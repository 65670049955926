import React, { Component } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import axios from 'axios'
import Loader from '../Loader/Loader';
import styles from './AllExercise.module.css';
import { getQuestions, getAnswers, getShowAnswers, getSimilarityScore, storeResults, getPreferences, updatePreferences } from '../../requests';
import UserContext from '../../utils/user-context';
import { startRecording, stopRecording } from '../../utils/record-audio';


var UUID;
var t;
var sec = 0, min = 0, order = 0;


class AllExercise extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);
        this.state = {
            showExercise: false,
            hideCategory: true,
            showResults: false,
            showTime: false,
            displayCheckAnswers: true,
            displayTryAgain: false,
            displayShowAnswers: false,
            displayLoader: true,
            displayLoader1: false,
            displayNextExer: false,
            displayDone: true,
            noquestions: true,
            quesMessage: false,
            questionsAndAnswers: "",
            question_id: "",
            UUID: "",
            exercise: "",
            errorMsg: false,
            myNumber: "",
            questions: [],
            // answers: [],
            userAnswers: [],
            recording: [],
            listening: [],
            audioLoading: [],
            playRecorded: [],
            playRecord: false,
            isBlocked: false,
            recordedBlobUrl: [],
            recordedBlob: [],
            audioInstances: [],
            playAudioInstances: [],
            similarityScores: [],
            accuracyMessages: [],
            highlightedTexts: [],
            speechProcessing: [],
            preferenceExist: false,
            languages: ["en-US", "en-GB", "en-IN", "en-AU"],
            languagesFull: { "en-US": "English - US", "en-GB": "English - UK", "en-IN": "English - India", "en-AU": "English - Australia" },
            voices: ["MALE", "FEMALE"],
            voicesFull: { "MALE": "Male", "FEMALE": "Female" },
            selectedLanguage: "en-GB",
            selectedVoice: "FEMALE",
            prefLoader: true,
            audPlaying: false,
            audInstance: "",
            testAudLoading: true,
            noPrefChange: true
        }
        this.timer = null;
        this.timer2 = null;
        this.lev_url = "/v1/gettingquestions/"
        // this.lev_url = "http://ac4a53352967411ea882a0e4ac2db6f1-1960014097.us-east-1.elb.amazonaws.com/v1/gettingquestions/";
        // Make it as an environment variable
        this.serverPath = process.env.LEVELWISE_API_URL || "/v1";
        this.callExercise = this.callExercise.bind(this);
        this.mainModal = this.mainModal.bind(this);
        this.showResults = this.showResults.bind(this);
        this.TryAgain = this.TryAgain.bind(this);
        this.showAnswers = this.showAnswers.bind(this);
        this.nextExercise = this.nextExercise.bind(this);
        this.quit = this.quit.bind(this);
        this.referNews = this.referNews.bind(this);
        this.add = this.add.bind(this);
        this.stopWatch = this.stopWatch.bind(this);
        this.restart = this.restart.bind(this);
        this.focusOnload = this.focusOnload.bind(this);
        this.focusQuest = this.focusQuest.bind(this);
        this.focusClick = this.focusClick.bind(this);
        this.playAudio = this.playAudio.bind(this);
        this.recordAudio = this.recordAudio.bind(this);
        this.playRecordedAudio = this.playRecordedAudio.bind(this);
        this.pauseRecordedAudio = this.pauseRecordedAudio.bind(this);
        this.pausePlayAudio = this.pausePlayAudio.bind(this);
        this.stopAudioAndRecording = this.stopAudioAndRecording.bind(this);
        this.disableOrEnableRecordedAudioButton = this.disableOrEnableRecordedAudioButton.bind(this);
        this.getAccuracyMessage = this.getAccuracyMessage.bind(this);
        this.handleBlob = this.handleBlob.bind(this);
        this.getExercise = this.getExercise.bind(this);
        this.updatePreference = this.updatePreference.bind(this);
        this.playTestAudio = this.playTestAudio.bind(this);
        this.onPrefChange = this.onPrefChange.bind(this);
        this.getHighlightedText = this.getHighlightedText.bind(this);
    }

    componentDidMount() {
        this.setState({ UUID: UUID });
    }

    async onPrefChange(language, gender, reAccess) {
        this.setState({ audioDuration: 0 })
        if (reAccess === false) {
        }
        else {
            this.setState({ testAudLoading: true });
            var audPlayElem = document.getElementById("playTestAudioButton")
            audPlayElem.style.opacity = 0.5;
            audPlayElem.style.pointerEvents = "none";

            let data = { "level": "testAudio", "uuid": "testAudio", "audio_path": "content/audio_files/testAudio", "question_uid": "", "gender": gender, "language": language }
            await fetch(this.lev_url + "getaudio", {
                method: 'POST',
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((response) => {
                return response.blob();
            }).then(async (blob) => {
                let blobUrl = URL.createObjectURL(blob);
                var audObject = new Audio(blobUrl);
                this.setState({ audInstance: audObject, testAudLoading: false });
                audPlayElem.style.opacity = 1.0;
                audPlayElem.style.pointerEvents = "all";
            }).catch(err => {

            });
        }
        this.setState({ selectedLanguage: language, selectedVoice: gender });

    }

    async getAccuracyMessage(index, score, qType = "normal") {
        let accMessage = this.state.accuracyMessages;
        let message = "";
        if (score > 90) {
            message = "Your Accuracy is " + score + "%. Great Work";
        }
        else if (score > 80) {
            message = "Your Accuracy is " + score + "%. Good Job";
        }
        else if (score > 70) {
            message = "Your Accuracy is " + score + "%. Good";
        }
        else if (score < 70) {
            message = "Your Accuracy is " + score + "%. Try Again!";
        }
        accMessage[index] = message;
        this.setState({ accuracyMessages: accMessage });
    }

    async getHighlightedText(index, highlightedText) {

        let highlightTexts = this.state.highlightedTexts;
        highlightTexts[index] = highlightedText
        this.setState({
            highlightedTexts: highlightTexts
        });

    }

    async playRecordedAudio(index) {
        this.pausePlayAudio();
        this.disableOrEnableRecordedAudioButton(false, true);
        clearTimeout(this.timer);
        let playRecorded = this.state.playRecorded;
        let recordAudioEvent = document.getElementsByClassName("recordButton");
        let playingRecEle = document.getElementsByClassName("playingRecorded");
        let playAudioElement = document.getElementsByClassName("playAudioButton");
        if (this.state.playRecorded[index]) {
            this.state.audioInstances[index].pause();
            this.state.audioInstances[index].currentTime = 0;
            playRecorded[index] = false;
            this.setState({ playRecorded });
            if (playingRecEle[index]) {
                playingRecEle[index].style.backgroundColor = "#FFFFFF";
            }
        }
        else {

            // const recLength = this.state.audioInstances.length;
            const recLength = this.state.listening.length;
            let listening = this.state.listening;
            let recording = this.state.recording;
            for (let i = 0; i < recLength; i++) {
                playRecorded[i] = false;
                listening[i] = false;
                if (recordAudioEvent[i]) {
                    recording[i] = false
                    // recordAudioEvent[i].style.pointerEvents = "none";

                }
                if (playAudioElement[i]) {
                    playAudioElement[i].style.backgroundColor = "#FFFFFF";
                    listening[i] = false;
                }

                if (this.state.audioInstances[i]) {
                    this.state.audioInstances[i].pause();
                    this.state.audioInstances[i].currentTime = 0;
                    if (playingRecEle[i]) {
                        playingRecEle[i].style.backgroundColor = "#FFFFFF";
                    }
                }
            }
            this.state.audioInstances[index].play();
            let audioDuration = await this.state.audioInstances[index].duration;
            playRecorded[index] = true;

            this.timer = setTimeout(() => {
                this.state.audioInstances[index].pause();
                this.state.audioInstances[index].currentTime = 0;
                playRecorded[index] = false;
                this.setState({ playRecorded });
                if (playingRecEle[index]) {
                    playingRecEle[index].style.backgroundColor = "#FFFFFF";
                }
            }, audioDuration * 1000);

            this.setState({ playRecorded, listening, recording });
            if (playingRecEle[index]) {
                playingRecEle[index].style.backgroundColor = "#FFF2E8";
            }
        }
    }

    pausePlayAudio(index = null) {
        let playAudInst = this.state.playAudioInstances;
        let playAudioElement = document.getElementsByClassName("playAudioButton");

        let length = playAudInst.length;
        for (let i = 0; i < length; i++) {
            if (playAudInst[i]) {
                playAudInst[i].pause();
                playAudInst[i].currentTime = 0;
            }
            if (playAudioElement[i]) {
                playAudioElement[i].style.opacity = 1.0;
                playAudioElement[i].style.pointerEvents = "all";
                playAudioElement[i].style.backgroundColor = "#ffffff";
            }
        }
        this.setState({ playAudioInstances: playAudInst });
    }

    pauseRecordedAudio(index = null) {
        let audInst = this.state.audioInstances;
        let playRecorded = this.state.playRecorded;
        let audLength = audInst.length;
        for (let i = 0; i < audLength; i++) {
            playRecorded[i] = false;
            if (audInst[i]) {
                audInst[i].pause();
                audInst[i].currentTime = 0;
            }
        }
        this.setState({ audioInstances: audInst, playRecorded });
    }

    disableOrEnableRecordedAudioButton(index = false, enable = false, disableRecordAll = false) {
        if (index === false) {
            let recordingInst = this.state.recording;
            let length = recordingInst.length;
            for (let i = 0; i < length; i++) {
                let textToAudio = document.getElementById("text_to_audio_" + i);
                let playRecorded = document.getElementById("playing_recorded_" + i);
                if (disableRecordAll === true) {
                    if (textToAudio) {
                        textToAudio.style.opacity = 0.5;
                        textToAudio.style.pointerEvents = "none";
                        // playingRecorded.style.backgroundColor = "#ffffff";
                    }
                }
                else {
                    if (this.state.displayTryAgain === false) {
                        if (textToAudio) {
                            textToAudio.style.opacity = 1.0;
                            textToAudio.style.pointerEvents = "all";
                            // playingRecorded.style.backgroundColor = "#ffffff";
                        }
                    }
                    else {
                        if (textToAudio) {
                            textToAudio.style.opacity = 0.5;
                            textToAudio.style.pointerEvents = "none";
                            // playingRecorded.style.backgroundColor = "#ffffff";
                        }
                    }
                    if (playRecorded) {
                        playRecorded.style.opacity = 1.0;
                        playRecorded.style.pointerEvents = "all";
                        playRecorded.style.backgroundColor = "#ffffff";
                    }
                }
            }
        }
        else {
            let playingRecorded = document.getElementById("playing_recorded_" + index);
            if (enable === true) {
                if (playingRecorded) {
                    playingRecorded.style.opacity = 1.0;
                    playingRecorded.style.pointerEvents = "all";
                    playingRecorded.style.backgroundColor = "#ffffff";
                }
            }
            else {
                if (playingRecorded) {
                    playingRecorded.style.opacity = 0.5;
                    playingRecorded.style.pointerEvents = "none";
                    // playingRecorded.style.backgroundColor = "#ffffff";
                }
            }
        }

    }

    async playAudio(index, audio_path, uuid, quest_type) {
        clearTimeout(this.timer);
        let playAudioElement = document.getElementsByClassName("playAudioButton");
        let recordAudioEvent = document.getElementsByClassName("recordButton");
        let playingRecorded = document.getElementsByClassName("playingRecorded");
        let listening = this.state.listening;
        let recording = this.state.recording;
        for (let i = 0; i < listening.length; i++) {
            recording[i] = false
            if (i === index) {
                let status = listening[i];
                if (status === false && this.state.playAudioInstances[i]) {
                    this.state.playAudioInstances[i].play();
                    listening[i] = true;
                    let audioDuration = await this.state.playAudioInstances[i].duration;

                    this.timer = setTimeout(() => {
                        this.state.playAudioInstances[i].pause();
                        this.state.playAudioInstances[i].currentTime = 0;
                        listening[i] = false;
                        this.setState({ listening });
                        if (playAudioElement[index]) {
                            playAudioElement[index].style.backgroundColor = "#FFFFFF";
                        }
                        if (quest_type === "text_to_audio") {
                            if (this.state.displayTryAgain === false) {
                                let recordAudioEventId = document.getElementById("text_to_audio_" + index);
                                recordAudioEventId.style.opacity = 1.0;
                                recordAudioEventId.style.pointerEvents = "all";
                            }
                            this.disableOrEnableRecordedAudioButton(i, true);
                        }
                    }, audioDuration * 1000);
                    playAudioElement[i].style.backgroundColor = "#FFF2E8";
                    if (quest_type === "text_to_audio") {
                        let recordAudioEventId = document.getElementById("text_to_audio_" + index);
                        recordAudioEventId.style.opacity = 0.5;
                        recordAudioEventId.style.pointerEvents = "none";
                        // Disabling Recorded Audio Player Button
                        this.disableOrEnableRecordedAudioButton(i, false);
                    }
                }
                else if (this.state.playAudioInstances[i]) {
                    this.state.playAudioInstances[i].pause();
                    this.state.playAudioInstances[i].currentTime = 0;
                    listening[i] = false;
                    playAudioElement[i].style.backgroundColor = "#ffffff";
                    if (quest_type === "text_to_audio") {
                        if (this.state.displayTryAgain === false) {
                            let recordAudioEventId = document.getElementById("text_to_audio_" + index);
                            recordAudioEventId.style.opacity = 1.0;
                            recordAudioEventId.style.pointerEvents = "all";
                        }
                        this.disableOrEnableRecordedAudioButton(i, true);
                    }
                }
            }
            else {
                if (this.state.playAudioInstances[i]) {
                    this.state.playAudioInstances[i].pause();
                    this.state.playAudioInstances[i].currentTime = 0;
                }
                listening[i] = false;
                playAudioElement[i].style.backgroundColor = "#ffffff";
                playAudioElement[i].style.opacity = 1.0;
                playAudioElement[i].style.pointerEvents = "all";

                if (recordAudioEvent[i] && this.state.displayTryAgain === false) {
                    recordAudioEvent[i].style.opacity = 1.0;
                    recordAudioEvent[i].style.pointerEvents = "all";
                }
                if (playingRecorded[i]) {
                    playingRecorded[i].style.backgroundColor = "#ffffff";
                }
                this.disableOrEnableRecordedAudioButton(i, true);
                this.pauseRecordedAudio();
            }
        }
        this.setState({ listening, recording });
    }

    async handleBlob(blob, index, answer) {
        let spProcessing = this.state.speechProcessing;
        spProcessing[index] = true;
        let recordedBlob = this.state.recordedBlob;
        const blobURL = URL.createObjectURL(blob);
        let audioInstances = this.state.audioInstances;
        let recordedBlobUrl = this.state.recordedBlobUrl;
        let similarityScores = this.state.similarityScores;
        let highlightedText = this.state.highlightedTexts;
        recordedBlob[index] = blob;
        recordedBlobUrl[index] = blobURL;
        let record2 = this.state.recording;
        record2[index] = false;
        audioInstances[index] = new Audio(blobURL);

        this.setState({ recordedBlobUrl, recording: record2, recordedBlob, audioInstances, speechProcessing: spProcessing }, () => {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
                var dataUrl = reader.result;
                var base64 = dataUrl.split(',')[1]
                let data = JSON.stringify({ "answer": answer, "audio_data": base64 });
                var response = await getSimilarityScore(data);
                var similarity = response["similarity"]
                similarityScores[index] = similarity;
                highlightedText[index] = response["user_answer"]
                spProcessing[index] = false;
                this.setState({ speechProcessing: spProcessing, similarityScores });
            }

        });


    }

    async recordAudio(index, audio_path, uuid, quest_type, answer) {
        this.pauseRecordedAudio();
        this.pausePlayAudio();
        let playAudioElement = document.getElementsByClassName("playAudioButton");
        let recordAudioEvent = document.getElementsByClassName("recordButton");
        let playingRecorded = document.getElementsByClassName("playingRecorded");

        let recording = this.state.recording;
        let listening = this.state.listening;
        if (this.state.isBlocked) {
            //console.log('Permission Denied');
            alert("Please give Microphone access");
        } else {
            if (this.state.recording[index] === false) {
                for (let i = 0; i < recording.length; i++) {
                    if (playingRecorded[i]) {
                        playingRecorded[i].style.backgroundColor = "#ffffff"
                    }
                    if (i === index) {
                        this.disableOrEnableRecordedAudioButton(i, false);
                    } else {
                        this.disableOrEnableRecordedAudioButton(i, true);
                    }
                }

                startRecording();
                let record = this.state.recording;
                record[index] = true;
                this.setState({ recording: record });
            }
            else {

                let rec = stopRecording();
                let that = this;
                rec.exportWAV(blob => {
                    that.handleBlob(blob, index, answer);
                });

                this.disableOrEnableRecordedAudioButton(index, true);

                let audioInstances = this.state.audioInstances;
            }
            for (let i = 0; i < recording.length; i++) {
                listening[i] = false
                if (i === index) {
                    let status = recording[i];
                    if (status === false) {
                        playAudioElement[i].style.opacity = 0.5;
                        playAudioElement[i].style.pointerEvents = "none";
                        playAudioElement[i].style.backgroundColor = "#ffffff";
                    }
                    else {
                        playAudioElement[i].style.opacity = 1.0;
                        playAudioElement[i].style.pointerEvents = "all";
                        playAudioElement[i].style.backgroundColor = "#ffffff";
                    }
                }
                else {
                    recording[i] = false;
                    playAudioElement[i].style.opacity = 1.0;
                    playAudioElement[i].style.pointerEvents = "all";
                    playAudioElement[i].style.backgroundColor = "#ffffff";
                    if (recordAudioEvent[i]) {
                        recordAudioEvent[i].style.opacity = 1.0;
                        recordAudioEvent[i].style.pointerEvents = "all";
                    }
                }
            }
            // this.setState({ recording, listening });
            this.setState({ listening });
        }

    }

    async stopAudioAndRecording() {
        let playAudioElement = document.getElementsByClassName("playAudioButton");
        // let recordAudioEvent = document.getElementsByClassName("recordButton");
        let playingRecorded = document.getElementsByClassName("playingRecorded");
        let audioInstances = this.state.audioInstances;
        let playAudioInstances = this.state.playAudioInstances;
        let playRecorded = this.state.playRecorded;
        let listening = this.state.listening;
        let record = this.state.recording;
        let speechProcessing = this.state.speechProcessing;

        for (let i = 0; i < audioInstances.length; i++) {
            playRecorded[i] = false;
            record[i] = false;
            if (audioInstances[i]) {
                audioInstances[i].pause();
                audioInstances[i].currentTime = 0;
            }
            if (speechProcessing[i]) {
                speechProcessing[i] = false;
            }
            if (playingRecorded[i]) {
                playingRecorded[i].style.backgroundColor = "#ffffff";
            }
        }
        for (let j = 0; j < playAudioInstances.length; j++) {
            listening[j] = false;
            if (playAudioInstances[j]) {
                playAudioInstances[j].pause();
                playAudioInstances[j].currentTime = 0;
            }
            if (playAudioElement[j]) {
                playAudioElement[j].style.backgroundColor = "#ffffff";
            }
        }
        this.setState({ audioInstances, playAudioInstances, playRecorded, listening, record, speechProcessing });
    }

    async updatePreference() {
        // Need to enable Once preference page completes
        // this.callExercise("Communication");
        let selectedLanguage = "en-GB"
        let selectedVoice = "FEMALE"

        var langEle = document.getElementsByName('language');
        var voiceEle = document.getElementsByName('gender');
        for (let i = 0; i < langEle.length; i++) {
            if (langEle[i].checked) {
                selectedLanguage = langEle[i].value;
            }
        }
        for (let j = 0; j < voiceEle.length; j++) {
            if (voiceEle[j].checked) {
                selectedVoice = voiceEle[j].value;
            }
        }

        const updateStatus = await updatePreferences({
            "voice_type": this.state.selectedLanguage,
            "voice_gender": this.state.selectedVoice
        })
        if (updateStatus.code === 200) {
            // //console.log("Succcessfully Preferences have been updated");
            let user = JSON.parse(localStorage.getItem("user"))
            user.language = selectedLanguage;
            user.gender = selectedVoice;
            localStorage.setItem("user", JSON.stringify(user));
        }
        this.setState({ preferenceExist: true, prefLoader: false }, () => {
            this.getExercise("Communication", false, true);
        })
    }

    async playTestAudio() {
        clearTimeout(this.timer2);
        let playTestAudioElement = document.getElementById("playTestAudioButton");
        if (this.state.audPlaying) {
            this.state.audInstance.pause();
            this.state.audInstance.currentTime = 0;
            playTestAudioElement.style.backgroundColor = "#F7F7F7";
            this.setState({ audPlaying: false });
        }
        else {
            var langEle = document.getElementsByName('language');
            var voiceEle = document.getElementsByName('gender');
            let selectedLanguage = "en-GB";
            let selectedVoice = "FEMALE";
            for (let i = 0; i < langEle.length; i++) {
                if (langEle[i].checked) {
                    selectedLanguage = langEle[i].value;
                }
            }
            for (let j = 0; j < voiceEle.length; j++) {
                if (voiceEle[j].checked) {
                    selectedVoice = voiceEle[j].value;
                }
            }
            let noPrefChange = false;
            if (this.state.selectedLanguage === selectedLanguage && this.state.selectedVoice === selectedVoice) {
                noPrefChange = true;
            }

            var audioDuration = this.state.audioDuration;
            if (noPrefChange === false) {
                await this.onPrefChange(selectedLanguage, selectedVoice);
            }
            else {
                audioDuration = this.state.audInstance.duration;
            }
            if (audioDuration > 0) {
                this.setState({ audPlaying: true })
                playTestAudioElement.style.backgroundColor = "#FFF2E8";
                this.state.audInstance.play();
                this.timer2 = setTimeout(() => {
                    this.state.audInstance.pause();
                    this.state.audInstance.currentTime = 0;
                    playTestAudioElement.style.backgroundColor = "#FFFFFF";
                    this.setState({ audPlaying: false });
                }, audioDuration * 1000);
            }
            else {
                await this.state.audInstance.addEventListener('loadedmetadata', async (e) => {
                    let audioDuration = await e.target.duration;

                    this.setState({ audPlaying: true })
                    playTestAudioElement.style.backgroundColor = "#FFF2E8";
                    this.state.audInstance.play();
                    this.timer = setTimeout(() => {
                        this.state.audInstance.pause();
                        this.state.audInstance.currentTime = 0;
                        playTestAudioElement.style.backgroundColor = "#FFFFFF";
                        this.setState({ audPlaying: false });
                    }, audioDuration * 1000);
                    this.setState({ audioDuration })
                });
            }
        }
        this.setState({ noPrefChange: true });
    }



    async getExercise(exercise, nextExer = false, prefSetDone = false) {
        this.setState({ showExercise: true, displayShowAnswers: false, hideCategory: false, showResults: false, displayLoader: true, displayCheckAnswers: true, displayTryAgain: false, displayNextExer: false, exercise: exercise });
        if (nextExer === true || prefSetDone === true) {
            this.callExercise(exercise)
        }
        else {
            if (exercise === "Communication") {
                var id = document.getElementById("exerName");
                id.innerHTML = '<img  src="../../ic_arrow_back_18px.svg" alt="" /> Select Accent';
                id.onclick = this.mainModal;
                const userPreferences = await getPreferences();
                let preferenceExist = this.state.preferenceExist
                let preferences = userPreferences.preferences;
                if (preferences && preferences.voice_type && preferences.voice_gender) {
                    preferenceExist = true;
                    this.setState({ preferenceExist, prefLoader: false });
                    let user = JSON.parse(localStorage.getItem("user"));
                    user.language = preferences.voice_type;
                    user.gender = preferences.voice_gender;
                    //console.log("user", user)
                    localStorage.setItem("user", JSON.stringify(user));
                    this.callExercise(exercise);
                } else {
                    preferenceExist = false;

                    this.setState({ preferenceExist, prefLoader: false })
                    this.onPrefChange("en-GB", "FEMALE");
                    var accentLanguage = document.getElementById(this.state.selectedLanguage);
                    var accentVoice = document.getElementById(this.state.selectedVoice);
                    if (accentLanguage) {
                        accentLanguage.checked = true;
                    }
                    if (accentVoice) {
                        accentVoice.checked = true;
                    }
                    // this.callExercise(exercise);
                }
            }
            else {
                this.setState({ preferenceExist: true, prefLoader: false })
                this.callExercise(exercise);
            }
        }
    }

    async callExercise(exercise) {
        this.stopAudioAndRecording();
        // let audioApiUrl = this.lev_url + "getaudio";
        var UUID_ = this.state.UUID;
        var id = document.getElementById("exerName");
        if (exercise === "Communication") {
            let eName = "Listening & Speaking"
            id.innerHTML = '<img  src="../../ic_arrow_back_18px.svg" alt="" />' + eName;
        }
        else {
            id.innerHTML = '<img  src="../../ic_arrow_back_18px.svg" alt="" />' + exercise;
        }
        id.onclick = this.mainModal;
        order++;
        // this.setState({ showExercise: true, displayShowAnswers: false, hideCategory: false, showResults: false, displayLoader: true, displayCheckAnswers: true, displayTryAgain: false, displayNextExer: false, exercise: exercise });
        let level = this.props.level.replace("_*", "");
        // Temporary for testing
        // UUID_ = "krishnaReddy971"
        var questions = await getQuestions({
            "level": level,
            "uuid": UUID_,
            "type": exercise,
            "order": order,
        });
        let listening = [];
        let recording = [];
        let recordedBlobUrl = [];
        let playRecorded = [];
        let audioInstances = [];
        let recordedBlob = [];
        let audioParams = [];
        let playAudioInstances = [];
        let similarityScores = [];
        let highlightedTexts = [];
        let audioLoading = [];
        if (exercise === "Communication" && questions) {
            for (let i = 0; i < questions.length; i++) {
                listening[i] = false;
                recording[i] = false;
                playRecorded[i] = false;
                recordedBlobUrl[i] = "";
                audioInstances[i] = "";
                recordedBlob[i] = '';
                similarityScores[i] = 0;
                highlightedTexts[i] = ''
                audioLoading[i] = false;
            }
        }

        if (questions) {
            let questionAndAnswers = [];
            let question_id = [];
            if (questions.length > 0) {
                questions.map(async (item, key) => {
                    if (item) {
                        var question = item.question;
                        var text_ = item.question.replace(/\“/g, "<b>\"");
                        var ques = text_.replace(/\”/g, "\"</b>");
                        //var ques =ques1.replace(/_+/g, "<input type='text' name='itemConsumption' id='gapfiller4' />");
                        var options = item.options;
                        var ques_id = item.question_uid;
                        let content_uuid = ""
                        var ques_type = item.question_type;
                        let audio_path = ""
                        if (exercise === "Communication") {
                            let user = JSON.parse(localStorage.getItem("user"))
                            var language = user.language;
                            var gender = user.gender;
                            localStorage.setItem("user", JSON.stringify(user));
                            audio_path = item.audio_location;
                            content_uuid = item.content_uuid;
                            let data = { "level": level, "uuid": UUID_, "audio_path": "content/audio_files/krishnaReddy971", "question_uid": ques_id, "gender": gender, "language": language }
                            audioParams[key] = data;
                            // 
                            audioLoading[key] = true;
                            this.setState({ audioLoading });
                            fetch(this.lev_url + "getaudio", {
                                method: 'POST',
                                headers: {
                                    "content-type": "application/json"
                                },
                                body: JSON.stringify(data)
                            }).then((response) => {
                                return response.blob();
                            }).then((blob) => {
                                let blobUrl = URL.createObjectURL(blob);
                                playAudioInstances[key] = new Audio(blobUrl);
                                audioLoading[key] = false;
                                this.setState({ audioLoading });
                            }).catch(err => {
                                //console.log(err);
                            });

                        }
                        var sentence = item.sentence;
                        var len = questions.length;
                        var q_id = { "q_id": ques_id }
                        question_id.push(q_id);
                        var itempush = { "ques": { __html: ques }, "options": options, "ques_id": ques_id, "ques_type": ques_type, "question": question, "sentence": sentence, "audio_path": audio_path, "content_uuid": content_uuid, "queslen": len }
                        questionAndAnswers.push(itempush);
                    }
                    let questAnswer = questions.length > 0 ? questionAndAnswers : "No logs recorded";
                    
                    this.setState({ questionsAndAnswers: questAnswer, question_id: question_id, displayLoader: false, quesMessage: false, noquestions: true, similarityScores,  highlightedTexts, audioInstances });
                    // if(exercise==="Communication"){
                    //     this.setState({ questionsAndAnswers: questAnswer, question_id: question_id, quesMessage: false, noquestions: true });
                    // }
                    // else{
                    //     this.setState({ questionsAndAnswers: questAnswer, question_id: question_id, displayLoader: false, quesMessage: false, noquestions: true });
                    // }
                });
                this.setState({ showTime: true, listening, recording, playRecorded, recordedBlobUrl },
                    () => {
                        if (document.getElementById("sec") != null && document.getElementById("min") != null) {
                            sec = document.getElementById("sec").value;
                            min = document.getElementById("min").value;
                            this.stopWatch();
                        }
                        this.focusOnload(questions.length);
                    }
                )
            }
            else {
                this.setState({ displayLoader: false, noquestions: false, quesMessage: true });
            }

        }

        else {
            this.setState({ displayLoader: false, noquestions: false, errorMsg: true });
        }
        this.setState({ seconds: sec, minutes: min, playAudioInstances, similarityScores, highlightedTexts });

    }




    focusOnload(length) {
        for (var i = 1; i < length; i++) {
            if (document.getElementById("exeQuest" + i) != null) {
                //document.getElementById("exeQuest" + i).style.opacity = "0.2";
            }
        }
    }


    focusQuest(evt, index) {
        evt.stopPropagation();
        if (document.getElementById("exeQuest" + (index + 1)) != null) {
            setTimeout(() => {
                document.getElementById("exeQuest" + (index + 1)).scrollIntoView({ behavior: "smooth" });
            }, 500);
            //document.getElementById("exeQuest" + (index + 1)).style.opacity = "1";
        }

    }

    focusClick(index, length) {
        //document.getElementById("exeQuest" + index).style.opacity = "1";

        for (var i = 0; i < length; i++) {

            if (i !== index) {
                // document.getElementById("exeQuest" + i).style.opacity = "0.2";
            }
        }

    }


    referNews() {
        document.getElementById("exerciseModal").style.display = "none";
    }
    add() {
        sec++;
        if (sec > 59) {
            sec = 0;
            min++;
        } if (document.getElementById("sec") != null || document.getElementById("min") != null) {
            sec < 10 ? document.getElementById("sec").textContent = "0" + sec : document.getElementById("sec").textContent = sec;
            min > 10 ? document.getElementById("min").textContent = min : document.getElementById("min").textContent = "0" + min;
        }
        this.stopWatch();
    }
    stopWatch() {
        t = window.setTimeout(this.add, 1000);

    }

    async showAnswers() {
        window.clearTimeout(t);
        this.setState({ displayLoader1: true, displayDone: false })
        var UUID_ = this.state.UUID;
        let id = [];
        let id1 = [];
        let checkAnswer = [];
        let checkAnswer1 = [];
        this.state.question_id.map((index, key) => {
            id[key] = index.q_id;
        })
        for (var l = 0; l < id.length; l++) {
            document.getElementById("exeQuest" + l).style.opacity = "1";
        }
        id1 = id.map(x => "" + x + "");
        for (var p = 0; p < id.length; p++) {
            var radios = document.getElementsByName(p);
            for (var i = 0, length = radios.length; i < length; i++) {
                radios[i].disabled = true;
                if (radios[i].checked) {
                    checkAnswer[p] = radios[i].value;
                }
            }
            if (document.getElementById("gapfiller" + p) != null) {
                checkAnswer[p] = document.getElementById("gapfiller" + p).value
                document.getElementById("gapfiller" + p).disabled = true;
            }
            if (checkAnswer[p] === undefined) { checkAnswer[p] = "" }
        }
        checkAnswer1 = checkAnswer.map(x => "" + x + "");
        let level = this.props.level.replace("_*", "");
        const answers = await getShowAnswers({
            "level": level,
            "uuid": UUID_,
            "ids": id1,
        });
        var index1 = 0;
        var everyChild = document.querySelectorAll(".exeQuest");
        document.getElementById("questions").style.pointerEvents = "";

        for (var i = 0; i < everyChild.length; i++) {
            let originalAnswer = answers[i].answer;
            let userAnswer = checkAnswer1[i]
            if (document.getElementById(i).style.color === "rgb(242, 73, 73)") {
                // document.getElementById(i).style.color = "";
                // document.getElementById("quesIcon" + i).innerHTML = '';
                // document.getElementById("hint" + i).innerHTML = '';
                if (document.getElementById("gapfiller" + i) != null) {
                    document.getElementById("gapfiller" + i).value = originalAnswer
                    // document.getElementById("gapfiller" + i).disabled = false;
                    document.getElementById("gapfiller" + i).style.color = "rgb(0, 156, 222)";
                    document.getElementById("gapfiller" + i).style.background = "#F5FCFF";
                    document.getElementById("gapfiller" + i).style.border = "1px solid #009CDE"
                };
            }
            if (document.getElementById(i).style.color === "rgb(28, 162, 80)") {
                document.getElementById(i).parentNode.style.pointerEvents = "none";
                if (this.state.exercise === "Communication") {
                    document.getElementById(i).parentNode.style.pointerEvents = "all";
                    if (answers[i].answer && document.getElementById("gapfiller" + i)) {
                        document.getElementById("gapfiller" + i).value = answers[i].answer;
                    }
                }

            }
            var radios = document.getElementsByName(index1);
            for (var j = 0, length = radios.length; j < length; j++) {
                if (originalAnswer === radios[j].value && originalAnswer !== userAnswer) {
                    radios[j].parentNode.style.color = "rgb(0, 156, 222)";
                    document.getElementById("checkIcon" + i + j).innerHTML = "&#10004;"
                }
            }
            index1++;
        }
        this.setState({
            showResults: false, showTime: false, displayCheckAnswers: false, displayTryAgain: true, displayShowAnswers: true, displayLoader1: false
        }, () => {
            document.getElementById("ShowAns").disabled = true
            // this.stopWatch();
            if (document.getElementById("questions") != null) {
                document.getElementById("questions").scrollIntoView();
            }
        })
    }

    TryAgain() {
        var index1 = 0;
        var everyChild = document.querySelectorAll(".exeQuest");
        document.getElementById("questions").style.pointerEvents = "";

        for (var i = 0; i < everyChild.length; i++) {
            if (document.getElementById(i).style.color === "rgb(242, 73, 73)" || this.state.similarityScores[i] < 100) {
                document.getElementById(i).style.color = "";
                document.getElementById("quesIcon" + i).innerHTML = '';
                document.getElementById("hint" + i).innerHTML = '';
                if (document.getElementById("gapfiller" + i) != null) {
                    document.getElementById("gapfiller" + i).disabled = false;
                    if (this.state.exercise !== "Communication") {
                        document.getElementById("gapfiller" + i).value = "";
                    }
                    else {
                        document.getElementById("gapfiller" + i).value = this.state.userAnswers[i];
                    }
                    document.getElementById("gapfiller" + i).style.color = "";
                    document.getElementById("gapfiller" + i).style.background = "";
                    document.getElementById("gapfiller" + i).style.border = ""
                };
            }
            if (document.getElementById(i).style.color === "rgb(28, 162, 80)") {
                document.getElementById(i).parentNode.style.pointerEvents = "none";

            }
            var radios = document.getElementsByName(index1);
            for (var j = 0, length = radios.length; j < length; j++) {
                radios[j].disabled = false;
                if (radios[j].parentNode.style.color === "rgb(28, 162, 80)") {
                    radios[j].disabled = true;
                }
                document.getElementById("checkIcon" + i + j).innerHTML = ""
                if (radios[j].parentNode.style.color === "rgb(0, 156, 222)") {
                    radios[j].parentNode.style.color = "";
                }
                if (radios[j].checked && radios[j].parentNode.style.color === "rgb(242, 73, 73)") {
                    radios[j].parentNode.style.color = "";
                    radios[j].checked = false;

                }
            }
            index1++;
        }
        this.setState({
            showResults: false, showTime: true, displayCheckAnswers: true, displayDone: true, displayShowAnswers: false, displayTryAgain: false, displayLoader1: false
        }, () => {
            this.stopWatch();
            if (document.getElementById("questions") != null) {
                document.getElementById("questions").scrollIntoView();
            }
            this.disableOrEnableRecordedAudioButton(false, false, false);

        })
    }
    mainModal() {
        this.setState({ showExercise: false, hideCategory: true, quesMessage: false, errorMsg: false });
        document.getElementById("exerName").innerHTML = "Exercise";
        this.quit();

    }




    async showResults() {
        this.stopAudioAndRecording()
        // to pause the timer
        window.clearTimeout(t);
        this.disableOrEnableRecordedAudioButton(false, false, true);
        var UUID_ = this.state.UUID;
        let id = [];
        let id1 = [];
        let checkAnswer = [];
        let checkAnswer1 = [];
        let similarityScores = this.state.similarityScores;
        var index = 0;
        var markCount = 0;
        this.setState({
            showResults: true, showTime: false, displayDone: false, displayCheckAnswers: false, displayTryAgain: true, displayLoader1: true, displayShowAnswers: true
        }, () => {
            if (document.getElementById("scoreDiv") != null) {
                document.getElementById("scoreDiv").scrollIntoView();
                document.getElementById("questions").style.pointerEvents = "none";
            }
        });
        this.state.question_id.map((index, key) => {
            id[key] = index.q_id;
        })
        for (var l = 0; l < id.length; l++) {
            document.getElementById("exeQuest" + l).style.opacity = "1";
        }
        id1 = id.map(x => "" + x + "");
        //id2 = "\"[" +id1+ "]\"";

        for (var p = 0; p < id.length; p++) {
            var radios = document.getElementsByName(p);
            for (var i = 0, length = radios.length; i < length; i++) {
                radios[i].disabled = true;
                if (radios[i].checked) {
                    checkAnswer[p] = radios[i].value;
                }
            }
            if (document.getElementById("gapfiller" + p) != null) {
                checkAnswer[p] = document.getElementById("gapfiller" + p).value
            }
            if (checkAnswer[p] === undefined) { checkAnswer[p] = "" }
        }
        checkAnswer1 = checkAnswer.map(x => "" + x + "");
        let level = this.props.level.replace("_*", "");
        let variables = {
            "level": level,
            "uuid": UUID_,
            "ids": id1,
            "order": order,
            "userAnswers": checkAnswer1,
            "duration": min.toString() + ":" + sec.toString(),
            "exerciseType": this.state.exercise,
            "title": this.props.contentTitle,
            "category": this.props.contentCategory
        }
        const answers = await getAnswers(variables);
        let ansCount = answers.length;
        if (this.state.exercise === "Communication") {
            for (let i = 0; i < ansCount; i++) {
                if (document.getElementById("gapfiller" + i) !== null && document.getElementById("gapfiller" + i).name === "aud_to_text") {
                    let userAnswer = document.getElementById("gapfiller" + i).value.toLowerCase()
                    let data = { "answer": answers[i].answer.toLowerCase(), "user_answer": userAnswer }
                    var response = await getSimilarityScore(data);
                    var similarity = response["similarity"]
                    similarityScores[i] = similarity;
                }
            }
        }
        if (answers) {
            await answers.map(async (item, key) => {
                if (document.getElementById("gapfiller" + key) != null) {
                    let userAnswer = document.getElementById("gapfiller" + key).value.toLowerCase()
                    let similarity = similarityScores[key];
                    this.getAccuracyMessage(index, similarity);
                    if (userAnswer === item.answer.toLowerCase() || similarity >= 80) {
                        let mark = 1
                        if (similarity) {
                            mark = similarity / 100;
                        }
                        // //console.log("Answers are correct")
                        document.getElementById("gapfiller" + key).style.color = "#1CA250";
                        document.getElementById(key).style.color = "#1CA250";
                        document.getElementById("quesIcon" + key).innerHTML = '<img class="imgg" src="../../ic_check_circle_24px.svg" alt="" />';
                        document.getElementById("hint" + key).innerHTML = "";
                        markCount = parseFloat(markCount) + mark;

                    }
                    else {
                        if (similarity) {
                            let mark = 0
                            mark = similarity / 100;
                            markCount = parseFloat(markCount) + mark;

                        }
                        // //console.log("Answers are not correct");
                        document.getElementById("gapfiller" + key).style.color = "#F24949";
                        document.getElementById("quesIcon" + key).innerHTML = '<img  class="imgg"src="../../ic_cancel_18px.svg" alt="" />'
                        document.getElementById(key).style.color = "#F24949";
                        if (item.answer_explanation !== "") { document.getElementById("hint" + key).innerHTML = "<b>Explanation</b>: The syllables in the word are " + "\"" + item.answer_explanation + "\""; }
                    }
                }
                var radios = document.getElementsByName(index);
                for (var i = 0, length = radios.length; i < length; i++) {
                    if (radios[i].checked) {
                        if (radios[i].value == item.answer) {
                            radios[i].parentNode.style.color = "#1CA250";
                            document.getElementById(index).style.color = "#1CA250";
                            document.getElementById("quesIcon" + index).innerHTML = '<img class="imgg" src="../../ic_check_circle_24px.svg" alt="" />';
                            document.getElementById("hint" + index).innerHTML = "";
                            markCount = parseFloat(markCount) + 1;
                        } else {
                            radios[i].parentNode.style.color = "#F24949";
                            document.getElementById("quesIcon" + index).innerHTML = '<img  class="imgg"src="../../ic_cancel_18px.svg" alt="" />'
                            document.getElementById(index).style.color = "#F24949";
                            if (item.answer_explanation !== "") { document.getElementById("hint" + index).innerHTML = "<b>Explanation</b>: The syllables in the word are " + "\"" + item.answer_explanation + "\""; }
                        }
                        break;
                    } else {
                        document.getElementById("quesIcon" + index).innerHTML = '<img  class="imgg" src="../../ic_cancel_18px.svg" alt="" />'
                        document.getElementById(index).style.color = "#F24949";
                        if (item.answer_explanation !== "") { document.getElementById("hint" + index).innerHTML = "<b>Explanation</b>: The syllables in the word are " + "\"" + item.answer_explanation + "\""; }
                    }
                }
                var tex_to_aud = document.getElementById("text_to_audio_" + key);
                if (tex_to_aud) {
                    markCount = parseFloat(markCount) + similarityScores[key] / 100;
                    if (this.state.similarityScores[key] > 70) {
                        document.getElementById(key).style.color = "#1CA250";
                        document.getElementById("quesIcon" + index).innerHTML = '<img class="imgg" src="../../ic_check_circle_24px.svg" alt="" />';
                    }
                    else {
                        document.getElementById("quesIcon" + key).innerHTML = '<img  class="imgg"src="../../ic_cancel_18px.svg" alt="" />'
                        document.getElementById(key).style.color = "#F24949";
                    }
                }
                index++;
                if (this.state.exercise === "Communication") {
                    markCount = parseFloat(markCount).toFixed(1);
                }
                else {
                    markCount = parseInt(markCount);
                }
                document.getElementById("total").innerHTML = id.length;
                document.getElementById("mark").innerHTML = markCount;
                if (markCount == id.length) {
                    this.setState({ displayNextExer: true, displayTryAgain: false, displayShowAnswers: false })
                }
            });
        }
        const exerciseType = this.state.exercise;
        if (exerciseType === "Communication") {
            variables.score = parseFloat(markCount);
            try {
                const storingResults = await storeResults(variables);
            }
            catch (e) {
                //console.log("Error", e.message)
            }
            finally {
                let score = markCount;
            }
            
        }
        this.setState({ displayLoader1: false, similarityScores, userAnswers: checkAnswer1 })
    }



    nextExercise() {
        this.setState({ displayShowAnswers: false, displayDone: true }, () => {
            // this.callExercise(this.state.exercise);
            this.getExercise(this.state.exercise, true);
        })
    }

    restart() {
        order = 0;
        // this.callExercise(this.state.exercise);
        this.getExercise(this.state.exercise);

    }

    // Reset the timer
    quit() {
        window.clearTimeout(t);
        sec = 0;
        min = 0;
        order = 0;
        if (document.getElementById("sec") != null || document.getElementById("min") != null) {
            document.getElementById("min").textContent = "0";
            document.getElementById("sec").textContent = "0";
        }
    }




    render() {
        UUID = this.props.UUIDD;
        return (
            <div className="modal" id="exerciseModal" tabIndex="-1" role="dialog">
                <div className=" modal-dialog  exerciseModal" role="document">
                    <div className="modal-content">
                        <div style={{ position: "sticky", top: "0px" }}>
                            <div className="modal-header modalHead">
                                <div style={{ padding: "0px 2rem" }} id="exerName">Exercise </div>
                                <div className="quitBtn" onClick={() => { this.props.onCloseButton(); this.quit() }}> QUIT </div>

                            </div>

                        </div>
                        {this.state.hideCategory && <div className="exerContent">Test your language skills with some quick questions from the News article you just read.
                <div style={{ textAlign: "center", marginTop: "80px" }}>
                                <div>Pick a category to continue</div>
                                <div className="categoryList">
                                    <div className="exerCategory" onClick={() => this.getExercise("Vocabulary")}>VOCABULARY</div>
                                    <div className="exerCategory" onClick={() => this.getExercise("Grammar")}>GRAMMAR</div>
                                    <div className="exerCategory" onClick={() => this.getExercise("Communication")}>LISTENING & SPEAKING</div>
                                    {/* <div className="exerCategory grayOut" >PRONUNCIATION <div className="comingSoon">Coming soon!</div></div> */}
                                </div>
                                <div style={{ paddingLeft: "200px" }}>
                                    <div className="exerCategory grayOut" >COMPREHENSION <div className="comingSoon">Coming soon!</div></div>
                                    <div className="exerCategory" style={{ borderLeft: "4px solid #42BABD" }} onClick={() => this.getExercise("Random")}>RANDOM</div>
                                </div>
                            </div>
                        </div>
                        }

                        {this.state.noquestions && this.state.showExercise &&
                            <div>
                                {this.state.displayLoader ?
                                    <div>{this.state.preferenceExist ? <div style={{ height: "59vh", overflowY: "scroll" }}>  <Loader /></div>
                                        :
                                        <div className={`prefMainDiv`}>
                                            {
                                                this.state.prefLoader ? <Loader />
                                                    :
                                                    <div style={{ height: "59vh" }} className={`exerPreferenceDiv`} >
                                                        <div className={`${styles.prefHeaderDiv}`}>
                                                            <p>Please select your preferred accent.</p>
                                                            <p>You can change this later under <b>'My Preferences'</b></p>
                                                        </div>
                                                        <div className="accentLanguageVoiceDiv">
                                                            <div className={`accentLanguage`}>
                                                                <div className={`${styles.accentLangaugeImageDiv}`}><img className={`${styles.accentLangaugeImage}`} src="../../MaskGroup_19.svg" alt="" /></div>
                                                                <div className="selectAccentOptionsDiv">
                                                                    <p>Select accent</p>
                                                                    <div>
                                                                        {
                                                                            this.state.languages.map((lang, index) => {
                                                                                return <div key={index} className="languageOption"><input type="radio" name="language" id={lang} value={lang} /> <label className={`${styles.langLabel}`} htmlFor={lang}>{this.state.languagesFull[lang]}</label></div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`accentVoice`}>
                                                                <div className={`${styles.accentLangaugeImageDiv}`}><img className={`${styles.accentLangaugeImage}`} src="../../MaskGroup_20.svg" alt="" /></div>
                                                                <div className="selectVoiceOptionsDiv">
                                                                    <p>Select voice</p>
                                                                    <div>
                                                                        {
                                                                            this.state.voices.map((voice, index) => {
                                                                                return <div key={index} className="voiceOption"><input type="radio" name="gender" id={voice} value={voice} /><label className={`${styles.voiceLabel}`} htmlFor={voice}>{this.state.voicesFull[voice]}</label></div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div></div>
                                                        </div>
                                                        <div className="testVoiceInExercise">
                                                            <a className={`${styles.testAudioButtonListen}`} id="playTestAudioButton" onClick={this.playTestAudio}>
                                                                {/* <button className={`${styles.audioButton}`} onClick={this.playAudio}> */}
                                                                {/* <span><i style={{ fontSize: "20px" }} className={`fa fa-volume-up`}></i></span> */}
                                                                {
                                                                    this.state.testAudLoading ?
                                                                        <span className={`${styles.audSpan}`}><img className={`${styles.testAudioLoadingImg}`} alt="loading" src="../../loader_rolling.gif" /> Listen to test voice</span>
                                                                        :
                                                                        <span>
                                                                            {this.state.audPlaying ?
                                                                                <span className={`${styles.audSpan}`}><img className={`${styles.speakerSvg22}`} alt="playing" src="../../speaker.gif" /> Listen to test voice</span>
                                                                                :
                                                                                <span className={`${styles.audSpan}`}><img className={`${styles.speakerSvg}`} alt="listen" src="../../speaker.svg" /> Listen to test voice</span>
                                                                            }
                                                                        </span>
                                                                }
                                                                {/* {this.state.audPlaying ?
                                                                    <span className={`${styles.audSpan}`}><img className={`${styles.speakerSvg22}`} alt="playing" src="../../speaker.gif" /> Listen to test voice</span>
                                                                    :
                                                                    <span className={`${styles.audSpan}`}><img className={`${styles.speakerSvg}`} alt="listen" src="../../speaker.svg" /> Listen to test voice</span>
                                                                } */}
                                                            </a>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    }</div>
                                    :
                                    <div>
                                        <div style={{ height: "59vh", overflowY: "scroll" }}  >
                                            {this.state.showTime && <div className="timer">
                                                <ul>
                                                    <li><i className="fa fa-hourglass-half" aria-hidden="true"></i></li>
                                                    <li id="min">00</li>
                                                    <li style={{ width: "3px" }}>:</li>
                                                    <li id="sec">00</li>

                                                </ul>
                                            </div>}
                                            <div>

                                                <div>
                                                    {this.state.showResults && <div style={{margin:"0 7rem"}}>
                                                        { this.state.displayLoader1 ? <div className="mainLoader1"></div> : ""}
                                                        <div id="scoreDiv" className="resultDiv">
                                                            <div className="score"><span id="mark"></span>/<span id="total"></span></div>
                                                            <div className="text">questions answered correctly!</div>
                                                            <div className="time">Time taken: <span id="minutes">{min}</span> min <span id="seconds">{sec}</span> sec</div>
                                                        </div>
                                                    </div>
                                                    }

                                                    <div id="questions">
                                                        {this.state.questionsAndAnswers.map((quesAns, index) => {
                                                            return (

                                                                <div className="exeQuest" id={'exeQuest' + index} key={index} style={{ padding: "2rem 7rem" }} onClick={() => this.focusClick(index, quesAns.queslen)}>
                                                                    <form action="">
                                                                        <div >
                                                                            <div className="questionNumber" id={index}><span id={'quesIcon' + index} ></span>Question {index + 1}/{quesAns.queslen}</div>
                                                                            {quesAns.ques_type !== "gap_filler" ?
                                                                                <div>
                                                                                    <div>
                                                                                        <div className="question" style={{ paddingBottom: "0px" }}><span className={`${styles.quesSentence}`}>{quesAns.sentence}</span>

                                                                                            {quesAns.ques_type === "audio_to_text" ?
                                                                                                <div >
                                                                                                    <a className={`${styles.audioButtonListen} playAudioButton`} onClick={this.playAudio.bind(null, index, quesAns.audio_path, quesAns.content_uuid, quesAns.ques_type)}>
                                                                                                        {/* <button className={`${styles.audioButton}`} onClick={this.playAudio}> */}
                                                                                                        {/* <span><i style={{ fontSize: "20px" }} className={`fa fa-volume-up`}></i></span> */}

                                                                                                        {this.state.listening[index] ?
                                                                                                            <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.gif" /> Listen</span>
                                                                                                            :
                                                                                                            <span>{this.state.audioLoading[index] ?
                                                                                                                <span><img className={`${styles.speakerSvg}`} style={{ opacity: "0.5" }} alt="listen" src="../../speaker.svg" /> Listen</span>
                                                                                                                :
                                                                                                                <span><img className={`${styles.speakerSvg}`} alt="listen" src="../../speaker.svg" /> Listen</span>
                                                                                                            }</span>
                                                                                                        }
                                                                                                    </a>
                                                                                                    {this.state.audioLoading[index] ? <div style={{ display: "inline-block" }}><img className={`${styles.audioLoadingImg}`} alt="loading" src="../../loader_rolling.gif" /></div> : ""}
                                                                                                </div>
                                                                                                : ""
                                                                                            }
                                                                                            {quesAns.ques_type === "text_to_audio" ?
                                                                                                <div className={styles.sentAudioImage}>
                                                                                                    <a className={`${styles.audioButton} playAudioButton`} onClick={this.playAudio.bind(null, index, quesAns.audio_path, quesAns.content_uuid, quesAns.ques_type)}>
                                                                                                        {/* <button className={`${styles.audioButton}`} onClick={this.playAudio}> */}
                                                                                                        {/* <span><i style={{ fontSize: "20px" }} className={`fa fa-volume-up`}></i></span> */}
                                                                                                        {/* <span><img className={`${styles.speakerSvg}`} width="20px" alt="listen" src="../../speaker.svg" /></span> */}
                                                                                                        {this.state.listening[index] ?
                                                                                                            // <div style={{display:"inline"}}>
                                                                                                            <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.gif" /></span>
                                                                                                            // </div>
                                                                                                            // <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.gif" /></span>
                                                                                                            :
                                                                                                            <span>{this.state.audioLoading[index] ?
                                                                                                                <span><img className={`${styles.speakerSvg}`} style={{ opacity: "0.5" }} alt="listen" src="../../speaker.svg" /></span> 
                                                                                                                :
                                                                                                                <span><img className={`${styles.speakerSvg}`} alt="listen" src="../../speaker.svg" /></span>
                                                                                                            }</span>
                                                                                                        }
                                                                                                    </a>
                                                                                                    {this.state.audioLoading[index] ? <div style={{ display: "inline-block" }}><img className={`${styles.audioLoadingImg}`} alt="loading" src="../../loader_rolling.gif" /></div> : ""}
                                                                                                </div>
                                                                                                : ""
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="question" id={quesAns.ques_id} ><span className={`${styles.quesSpan}`} dangerouslySetInnerHTML={quesAns.ques} /></div>
                                                                                    {quesAns.ques_type === "audio_to_text" ?
                                                                                        <div style={{ paddingBottom: "10px" }}><input type="text" className={`${styles.comInput}`} name="aud_to_text" id={'gapfiller' + index} />
                                                                                            {/* {this.state.displayDone && <span className="doneBtn" onClick={(e) => { this.focusQuest(e, index) }}>Done</span>} */}
                                                                                            {this.state.displayTryAgain ?
                                                                                                <div className={styles.accuracyMessage}>
                                                                                                    <span> {this.state.accuracyMessages[index]}</span>
                                                                                                </div>
                                                                                                : ""}
                                                                                        </div> :
                                                                                        <div>
                                                                                           {
                                                                                                (this.state.highlightedTexts[index] && quesAns.ques_type === "text_to_audio" )?<div className="highlightTextBox"><span className="highlightText" dangerouslySetInnerHTML={{__html:this.state.highlightedTexts[index]}}/></div>:""
                                                                                            }
                                                                                            {(this.state.speechProcessing[index] && quesAns.ques_type === "text_to_audio") ? <div className={styles.processingMsgDiv}><img className={`${styles.processingImg}`} alt="loading" src="../../loader_rolling.gif" /><span>Processing your speech.</span></div> :
                                                                                                        this.state.similarityScores[index] || this.state.displayTryAgain && quesAns.ques_type ==="text_to_audio" ? <div className={styles.recordedAccuracy}><span style={{ color: "#0079C4", fontSize: "14px", fontWeight: "500" }}>Your Accuracy is {this.state.similarityScores[index]} %.</span></div> : ""}
                                                                                            
                                                                                            {quesAns.ques_type === "text_to_audio" ?
                                                                                                <div>
                                                                                                    <a className={`${styles.recordButton} recordButton`} id={"text_to_audio_" + index} onClick={this.recordAudio.bind(null, index, quesAns.audio_path, quesAns.content_uuid, quesAns.ques_type, quesAns.sentence)}>
                                                                                                        {this.state.recording[index] ?
                                                                                                            <span><img className={`${styles.recordingGif}`} alt="recording" src="../../recording.gif" />Recording</span>
                                                                                                            :
                                                                                                            <span><i style={{ fontSize: "14px", paddingRight: "3px" }} className={`fa fa-microphone`}></i>Record</span>
                                                                                                        }
                                                                                                    </a>
                                                                                                    {this.state.recordedBlobUrl[index] !== "" ?
                                                                                                        <div className={`${styles.playRecordedAudioDiv}`}>
                                                                                                            <a className={`${styles.playingRecordedButton} playingRecorded`} id={"playing_recorded_" + index} onClick={this.playRecordedAudio.bind(null, index)}>
                                                                                                                {this.state.playRecorded[index] ?
                                                                                                                    <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.gif" /></span>
                                                                                                                    :
                                                                                                                    <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.svg" /></span>
                                                                                                                }
                                                                                                                {/* <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.svg" /></span> */}
                                                                                                            </a>

                                                                                                        </div>
                                                                                                        // <span><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.gif" /></span>
                                                                                                        : ""}
                                                                                                    
                                                                                                </div> :
                                                                                                <div>
                                                                                                    {quesAns.options.length > 0 ? <div style={{ paddingLeft: "10px" }}>
                                                                                                        {quesAns.options.map((options, idx) => (
                                                                                                            <div className="radio" key={idx} onClick={(e) => { this.focusQuest(e, index) }}>
                                                                                                                <input style={{ width: "16px", height: "16px", verticalAlign: "middle" }} type="radio" name={index} value={options} id={options + index} /> <label htmlFor={options + index} style={{ paddingLeft: "5px", display: "contents" }}>{options}</label><span className="checkIcon" id={"checkIcon" + index.toString() + idx.toString()}></span><br />

                                                                                                            </div>
                                                                                                        ))
                                                                                                        }
                                                                                                    </div>
                                                                                                        : <div style={{ paddingBottom: "10px" }}><input type="number" name="itemConsumption" id={'gapfiller' + index} />
                                                                                                            {this.state.displayDone && <span className="doneBtn" onClick={(e) => { this.focusQuest(e, index) }}>Done</span>}
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div> :
                                                                                <div><div className="question" style={{ paddingBottom: "0px" }}>{quesAns.sentence}</div>
                                                                                    <div className="question" id={quesAns.ques_id} ><span dangerouslySetInnerHTML={{ __html: quesAns.question.replace(/\_+/g, '<input type="text" name="itemConsumption" id="gapfiller' + index + '" />') }} /></div>
                                                                                    {/* {quesAns.ques_type === "text_to_audio" ?
                                                                                        <div>
                                                                                            <button className={`${styles.recordButton}`} onClick={this.recordAudio.bind(null, quesAns.audio_path, quesAns.content_uuid, quesAns.ques_type)}>
                                                                                                <span><i style={{ fontSize: "20px" }} className={`fa fa-microphone`}></i></span>
                                                                                            </button>
                                                                                        </div> :
                                                                                        ""
                                                                                    } */}
                                                                                </div>
                                                                            }
                                                                            <div className="hint" id={'hint' + index}></div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.preferenceExist ?
                                <div>{
                                    this.state.showExercise && this.state.noquestions && <div className="modal-footer" style={{ justifyContent: "space-between", paddingRight: "29px" }}> <div style={{ padding: "1rem" }}>
                                        {this.state.displayCheckAnswers && <button className="moreQuestions tooltipsSkill" onClick={this.referNews}>Refer News <i className="fa fa-info-circle" aria-hidden="true" style={{color:"#42BABD"}}></i><span className="tooltiptextsSkill">Check the news again if you want. Don't worry, your progress will be saved.</span></button>}
                                        {this.state.displayShowAnswers &&
                                            <button className="ShowAns" id="ShowAns" onClick={this.showAnswers}>Show Correct Answers<i style={{ paddingLeft: "5px",color:"#E0291A",fontWeight:"600",fontSize:"15px"}} className='fa fa-angle-right'> </i></button >
                                        }
                                    </div> <div style={{ padding: "3px 0px" }}>
                                            {this.state.displayCheckAnswers &&
                                                <button className="CheckAns" onClick={this.showResults}>Check Answers</button>}
                                            {this.state.displayTryAgain &&
                                                <button  className="CheckAns moreQues" onClick={this.nextExercise} >Next Exercise</button>}
                                            {this.state.displayTryAgain &&
                                                <button className="CheckAns" onClick={this.TryAgain}>Try Again</button >
                                            }
                                            {this.state.displayNextExer &&
                                                <div style={{ padding: "1rem 0" }}> <button className="CheckAns" onClick={this.nextExercise}>Next Exercise</button > </div>
                                            }
                                        </div>
                                    </div>

                                }</div>
                                :
                                <div>{
                                    this.state.showExercise && this.state.noquestions && <div className="modal-footer" style={{ justifyContent: "space-between", paddingRight: "29px" }}> <div style={{ padding: "1rem" }}>

                                    </div> <div style={{ padding: "3px 0px" }}>
                                            <div style={{ padding: "1rem 0" }}> <button className="prefContinue" onClick={this.updatePreference}>Continue</button > </div>
                                        </div>
                                    </div>

                                }</div>
                        }
                        {/* {
                            this.state.showExercise && this.state.noquestions && <div className="modal-footer" style={{ justifyContent: "space-between", paddingRight: "29px" }}> <div style={{ padding: "1rem" }}>
                                {this.state.displayCheckAnswers && <button className="moreQuestions tooltipsSkill" onClick={this.referNews}>Refer News <i className="fa fa-info-circle" aria-hidden="true"></i><span className="tooltiptextsSkill">Check the news again if you want. Don't worry, your progress will be saved.</span></button>}
                                {this.state.displayShowAnswers &&
                                    <button className="ShowAns" id="ShowAns" onClick={this.showAnswers}>Show Correct Answers</button >
                                }
                            </div> <div style={{ padding: "3px 0px" }}>
                                    {this.state.displayCheckAnswers &&
                                        <button className="CheckAns" onClick={this.showResults}>Check Answers</button>}
                                    {this.state.displayTryAgain &&
                                        <button style={{ padding: "1rem 2rem" }} className="moreQuestions" onClick={this.nextExercise} >Next Exercise</button>}
                                    {this.state.displayTryAgain &&
                                        <button className="CheckAns" onClick={this.TryAgain}>Try Again</button >
                                    }
                                    {this.state.displayNextExer &&
                                        <div style={{ padding: "1rem 0" }}> <button className="CheckAns" onClick={this.nextExercise}>Next Exercise</button > </div>
                                    }
                                </div>
                            </div>

                        } */}

                        {
                            this.state.errorMsg && <div style={{ padding: "4rem 2rem", height: "71vh", textAlign: "center", fontWeight: "500" }}>
                                <div><img className="" src="../../questions_notloaded.svg" alt="" /></div>
                                <div className="completedMessage">Sorry! We are unable to load questions at the moment. <div style={{ paddingTop: "10px" }}>Please try again later.</div></div>
                            </div>
                        }

                        {
                            this.state.quesMessage && <div style={{ padding: "4rem 2rem", height: "71vh", textAlign: "center" }}>
                                <div><img className="" src="../../noques.svg" alt="" /></div>
                                <div className="completedMessage">You have completed all available exercises in this category. <div style={{ paddingTop: "10px" }}>Try a different category.</div></div>
                                <div > <button className="CheckAns" onClick={this.mainModal}>Pick Category</button > </div>
                                <div style={{ paddingTop: "40px" }}>or <button className="moreQuestions" onClick={this.restart}>Click here to restart the exercises</button></div>
                            </div>
                        }



                    </div>
                </div>
            </div>
        );
    }


}

export default AllExercise;
