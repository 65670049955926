import React, { Component } from 'react';
import { MDBDataTable,/*MDBBtn,MDBContainer,*/MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import styles from './PinnedContentsTable.module.css';
import Loader from "../Loader/Loader";


class PinnedContentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pinned: "",
            
        }
        this.optionsList= this.optionsList.bind(this);
    }

   

    //     componentWillReceiveProps(){
    //     if(this.props.pinnedContentsDrafts1 != null){
    //      this.props.loadTableFunc1();}
    //  }

    optionsList (index){
      if( document.getElementById("list" + index).style.display === "inline-flex"){
        document.getElementById("list" + index).style.display = "none"
      }else{
        document.getElementById("list" + index).style.display = "inline-flex" 
      }
    }

    renderTableData(e) {
        const rows = this.props.pinnedContentsDrafts1.map((content, index) => {
            const { UUID, Category, Title, PublishedDate, Level,IsRecommendedToChina } = content;
            return ({
                // "index": this.props.startIndex + index + 1,
                "content_id": <b onClick={()=>this.props.previewButton(UUID,"","")} className={`${styles.relUUID}`}>{UUID}</b>,
                "eligibleforchina": IsRecommendedToChina === "true" ? <i class="fa fa-check-circle" aria-hidden="true"></i>: <i class="fa fa-times-circle"></i>,
                "level": Level === 1? "Lower Intermediate": Level === 2? "Higher Intermediate":"Advanced",
                "category": Category.charAt(0).toUpperCase() + Category.slice(1),
                "title": Title,
                "published_on": PublishedDate,
                "options": this.props.previewType1 ==="Drafts"?<div>
                    <button className={`${styles.optionsButton}`} onClick={this.optionsList.bind(null,index)}><i className="fa fa-ellipsis-v"></i></button>
                   <div id={"list" + index} className={`${styles.pinContentComb} ${styles.editContentButton} btn`} >
                       <span onClick={() => this.props.editButton(UUID,"","")}>Edit</span>
                       <span onClick={() => this.props.showpinPopUp(UUID)}>Delete</span>
                   </div>
                </div>:<div>
                    <button className={`${styles.optionsButton}`} onClick={this.optionsList.bind(null,index)}><i className="fa fa-ellipsis-v"></i></button>
                   <div id={"list1" + index} className={`${styles.pinContentComb} ${styles.editContentButton} btn`} >
                       <span onClick={() => this.props.showpinPopUp(UUID)}>Delete</span>
                   </div>
                </div>
            })
        })

        return rows

    }

    render() {

        const data = {
            columns: [
                // {

                //     label: '#',
                //     field: 'index',
                //     sort: 'asc',
                //     width: '60'
                // },
                {

                    label: 'CONTENT ID',
                    field: 'content_id',
                    sort: 'asc',
                    width: '200'
                },
                {

                    label: 'ELIGIBLE FOR CHINA',
                    field: 'eligibleforchina',
                    sort: 'asc',
                    width: '200'
            
                },
                {

                    label: 'LEVEL',
                    field: 'level',
                    sort: 'asc',
                    width: '200'
                },
                {

                    label: 'CATEGORY',
                    field: 'category',
                    sort: 'asc',
                    width: '130'
                },
                {

                    label: 'TITLE',
                    field: 'title',
                    sort: 'asc',
                    width: '200'
                },
                {

                    label:  this.props.previewType1 ==="Drafts" ?'LAST MODIFIED':'PUBLISHED ON',
                    field: 'published_on',
                    sort: 'asc',
                    width: '130'
                },
                {

                    label: '',
                    field: 'options',
                    sort: 'asc',
                    width: '130'
                }
            ]
        }

        const rows = this.renderTableData();
        data["rows"] = rows;
        return (
            <div> <div className="pinnedContentsTableDiv">
                <MDBTable className={styles.TableClass} fixed responsiveSm small hover>
                    <MDBTableHead className={styles.TableColumn} columns={data.columns} />
                    <MDBTableBody rows={data.rows} />
                </MDBTable>
                {/* <MDBDataTable
                    striped
                    bordered
                    small
                    data={data}
                    /> */}
                {/* <MDBDataTable
                    scrollX
                    scrollY
                    maxHeight="300px"
                    bordered
                    data={data}
                    /> */}
            </div>

            </div>
        )
    }
}

export default PinnedContentTable;
