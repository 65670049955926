import React, { useContext } from "react"
import ConfigContext from "../../utils/config-context"
import { Link } from "react-router-dom";
import styles from './Header.module.css';// Import css modules stylesheet as styles

export const NavLinks = function(props) {

    let config = useContext(ConfigContext);
    return (
        <div className="globalSettings">
            <ul className="globalMenu liststyle">
              {props.editViewState && <li className={styles.rightNav}><a onClick={props.pinnedContents}>Pinned Contents</a></li>}
             {props.teacherFlag && <li className={styles.rightNav}  onClick={props.editorView} ><a id="view">Editor View</a></li>}
             {props.teacherFlag && <li><span className={styles.pipeIcon}>&#124;</span></li>}
              <li className="mySolution solutions">
                <a className="mySolutions">
                  My Solutions <i className="fa fa-angle-down solutionsIcon"></i>
                </a>
                <div
                  className="dropdown-menu solutions-menu"
                  id="dropdown-menu"
                >
                  <ul className="globalMenu liststyle product-list">
                    <li>
                      <a href={config.edgeUrl}>
                        One{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href={config.reachUrl}
                        target="'_blank'"
                      >
                        Reach
                            </a>
                    </li>
                    <li>
                      <a
                        href={config.dashboardUrl}
                        target="'_blank'"
                      >
                        Dashboard
                            </a>
                    </li>
                    <li>
                      <a href="" className="active">
                        News Feed{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="reorderIcon">
                <a><i className="profileSelect fa fa-reorder "></i></a>
                <ul
                  className="settingMenu_dropdown  arrow_box"
                  id="profileSelect"
                >
                  <li>
                    <a
                      tabIndex="-1"
                      href={config.homeUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      My Profile
                          </a>{" "}
                  </li>
                  <Link to="/preferences">
                  <li>
                    <span className="preferenceSpan">
                      My Preferences
                      </span>{" "}
                  </li>
                  </Link>
                  
                  <li className="last">
                    <a href="/logout">Log Out</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
    );
}
