import React, { Component } from 'react';
import { loadContents } from './healthfunctions'

class Healthcheck extends Component {

state = {
    graphql_content: []
};

async componentDidMount() {

const classifiedContentQuery = JSON.stringify({
query: `{
  classifiedContent(params: {
    level: "levelone"
    category: "business"

  }, limitRows: {
    limit: 13
    offset: 0
  } ) {
    Title
    Media_Url

  }
}`,
})

const headers = {
            "content-type": "application/json",
            "Accept": "application/json",
            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiIyMzk5MzIzIiwiZ2VJZCI6IjIwMjM3NDQifQ.nRttiT8Mm5OsbsvxAv2SoJwTEfkJfxof1e8MdgCrPUo"
            }

const request = {
    method: "POST",
    headers: headers,
    body: classifiedContentQuery
};

    const graphql_result = await  fetch('/graphql', request);
    const json = await graphql_result.json();
    this.setState({ graphql_content: json.data.classifiedContent[0].Title })
}

   render() {
           return(
           <div>
           <li>Working Fine</li>
            </div>
           )
       }

  }


export default Healthcheck;