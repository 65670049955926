import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer/Footer';
import {Header} from './Header/Header';
import ConfigContext, {config} from '../utils/config-context'

class AccessDenied extends Component {
     render() {
      
    return (
      <div>
      <ConfigContext.Provider value={config}>
        <Header />
      </ConfigContext.Provider>
      <div className="errorPage">
        
        <div className="errorMessageDiv" >
          <div className="errMsgtitle"> You don't have permission to view this <br/>page!</div>
          <div className="errorMsg">Here's a link back to the <Link to="/">home page</Link>.<br/> For more help, please contact your administrator.</div>
        </div>
      
      </div>
      <Footer />
    </div>
        )
      }
    
    
    }
    
    export default AccessDenied;