import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styles from './NewsFeed.module.css';
import dateFormat from "dateformat";
// var dateFormat = require('dateformat');




class NewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            views: 41,
            isVideoCategory: false,
            // Need to remove and make contentViews dynamic
            contentViews: 40,
            summary: "No Summary Available",
            selectedUUID: "",


        }
        this.renderTopContent = this.renderTopContent.bind(this);
        this.renderContents = this.renderContents.bind(this);
        this.summaryPopUp = this.summaryPopUp.bind(this);
        this.closeSummaryButton = this.closeSummaryButton.bind(this);
        this.renderPopularContent = this.renderPopularContent.bind(this);

    }


    renderTopContent(e) {

        if (this.props.contents[0]) {
            var index = 0
            const content = this.props.contents[index];
            const { UUID, Source, Title, Readtime, ImageUrl, Media_Url, PublishedDate } = content;
            var date_ = new Date(PublishedDate);
            var publishedDate = dateFormat(date_, "dd mmmm,yyyy");
            if (this.props.selectedCategory === "topstories") {
                return (
                    <div className="row" style={{ padding: "0 0 15px 15px" }}>
                        <div className="col-sm-12 col-xs-12 imageContainer"><Link to={"/" + this.props.loadUrl + "/" + this.props.selectedCategory + "/" + UUID} key={UUID} id="imageContainer" index-id="0" onClick={this.props.openModal.bind(null, UUID)} >


                            <div>
                                {
                                   Media_Url != null && Media_Url.startsWith("http") ?
                                        <div className="videoCategoryImage" >
                                            <img width="700px" height="360px" src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="Content Img" />
                                            <div className="playButtonInsideImage image1" id="b"><span className="fa fa-play"></span></div>
                                        </div>
                                        :
                                        <div>
                                            <img width="700px" height="360px" src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="" />
                                        </div>
                                }
                            </div>
                            <div className="text-block">
                                <div>{publishedDate}</div>
                                <div style={{ fontSize: "20px", color: "#FFFFFF", fontWeight: "600" }}>{Title}</div>
                                {Source === "None" ?
                                    <div>{Readtime} </div> :
                                    <div>{Source} | {Readtime} </div>
                                }

                            </div>
                        </Link>
                        </div>

                    </div>
                )
            }
        }
    }




    summaryPopUp(index) {
        document.getElementById("tooltip" + index).style.display = "block";
        let indexTemp = index ;

        if (indexTemp % 3 == 0) {
            document.getElementById("tooltip" + index).style.marginLeft = "-530px";
        }

    }


    closeSummaryButton(index) {
        document.getElementById("tooltip" + index).style.display = "none";
    }

    renderPopularContent(e) {
        const newsFeeds = this.props.contents.map((content, index) => {
            const { UUID, Source, Title, Readtime, Media_Url, ImageUrl, PublishedDate } = content;
            var date_ = new Date(PublishedDate);
            var publishedDate = dateFormat(date_, "dd mmmm,yyyy");
            if (index <= 3) {
                return (
                    <div className="col-sm-3 col-xs-6 thumbnaill "> <Link to={"/" + this.props.loadUrl +"/" + this.props.selectedCategory + "/" + UUID} id="thumbnailImg1" key={UUID} onClick={this.props.openModal.bind(null, UUID)}>
                        <div className="thumbnailImage" style={{ paddingBottom: "10px" }}>
                            {
                                Media_Url != null && Media_Url.startsWith("http") ?
                                    <div className="videoCategoryImage" id="a">
                                        <img width="157px" height="110px" style={{ objectFit: "cover" }} src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="Content Img" />
                                        <div className="playButtonInsideImage" id="b"><span className="fa fa-play"></span></div>
                                    </div>
                                    :
                                    <div>
                                        <img width="157px" height="110px" style={{ objectFit: "cover" }} src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="" />
                                    </div>
                            }
                            <div className="thumbnailDetails">{publishedDate}</div>
                            <div style={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>{Title}</div>
                            {Source === "None" ?
                                <div className="thumbnailDetails">{Readtime} </div> :
                                <div className="thumbnailDetails">{Source} | {Readtime} </div>
                            }
                        </div>
                    </Link>
                    </div>

                )

            }
        })
        return newsFeeds;
    }

    renderContents(e) {
        var indexStartPosition;

        if (this.props.selectedCategory === "topstories") {
            indexStartPosition = 1;

        }
        else {
            indexStartPosition = 4;

        }
        const newsFeeds = this.props.contents.map((content, index) => {
            const { UUID, Source, Title, Readtime, Media_Url, ImageUrl, PublishedDate, Summary } = content;
            var date_ = new Date(PublishedDate);
            var publishedDate = dateFormat(date_, "dd mmmm,yyyy");
            if (index >= indexStartPosition && index < this.props.limit) {
                return (
                    <div className="col-sm-4 col-xs-6 thumbnail" key={index}><Link to={"/" + this.props.loadUrl + "/" + this.props.selectedCategory + "/" + UUID} id="thumbnailImg1" key={UUID} onClick={this.props.openModal.bind(null, UUID)}>
                        <div className="thumbnailImage" >
                            {
                                Media_Url != null && Media_Url.startsWith("http") ?
                                    <div className="videoCategoryImage" id="a">
                                        <img width="220px" height="150px" style={{ objectFit: "cover" }} src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="Content Img" />
                                        <div className="playButtonInsideImage" id="b"><span className="fa fa-play"></span></div>
                                    </div>
                                    :
                                    <div>
                                        <img width="220px" height="150px" style={{ objectFit: "cover" }} src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="" />
                                      {/* {this.props.editViewState &&  <div className={`${styles.playButtonInsideImage}`} id="b"><span>{this.state.views} views</span></div>} */}
                                    </div>
                            }
                            <div className="thumbnailDetails">{publishedDate}</div>
                            <div style={{ fontSize: "14px", fontWeight: "600", color: "#000000" }}>{Title}</div>
                            {Source === "None" ?
                                <div className="thumbnailDetails">{Readtime} </div> :
                                <div className="thumbnailDetails"><span>{Source}</span> | <span>{Readtime}</span></div>
                            }
                        </div>
                    </Link>
                        {this.props.editViewState && <div className={styles.summaryDiv}><button className="summaryButton " onClick={this.summaryPopUp.bind(null, index)}>Summary  <span className={styles.gtIcon}><i className='fa fa-chevron-right'></i></span></button></div>}
                        <div className="tooltiptextsSkill1" id={"tooltip" + index}><div className="summaryHeader"><b>Summary</b><img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right" }} onClick={this.closeSummaryButton.bind(null, index)} src="../../ic_close_48px.svg" alt="" /></div><div style={{ padding: "15px" }}>{Summary}</div><div className="summaryFooter" onClick={this.props.openModal.bind(null, UUID)}><b>Read full article</b><i className='fa fa-chevron-right' style={{paddingLeft:"5px"}}></i></div></div>

                    </div>

                )
            }
        })
        return newsFeeds;
    }

    loadMore() {
        if (this.props.loadingMoreRecords === false) {
            if ((this.props.contents).length === this.props.limit) {
                return (
                    <div>
                        {this.props.contents[0] && <div className="loadMore" style={{ textAlign: "center" }}><button onClick={this.props.loadMore}>Load more</button></div>}
                    </div>
                )
            }

        }
        else {
            return (
                <div style={{ textAlign: "center", paddingBottom: "50px" }}>
                    <img width="60px" height="60px" src="../../loader-log.gif" alt="" />
                </div>
            )
        }
    }



    render() {
        return (

            <div className="container-fluid">
                <div>
                    <div>
                        <div style={{paddingLeft:"12px",margin:"15px 0"}}><img id="reorder" onClick={this.props.closeTab} src="../../ic_menu_18px.svg" alt="" />
                        <span style={{ fontSize: "18px" }}>
                            <b>{this.props.selectedCategory === "topstories" ? "Top Stories" : this.props.selectedCategory.charAt(0).toUpperCase() + this.props.selectedCategory.slice(1)} </b>
                        </span></div>
                        {this.props.learnerViewState && <div style={{ padding: "25px 13px" }}>


                            <span>{this.renderTopContent()}</span>
                            {/* <ul style={{ listStyleType: "none", display: "flex", textAlign: "center", float: "right" }}>
                                <li className={styles.rightListDropDown}>
                                    Posted:
                                    <select className={styles.dropDown}>
                                        <option>Last 10 Days</option>
                                        <option>This Month</option>
                                        <option>Last Month</option>
                                    </select>
                                </li>
                                <li className={styles.rightListDropDown}>
                                    Sort:
                                    <select className={styles.dropDown}>
                                        <option>Recent First</option>
                                        <option>Oldest First</option>
                                        <option>Most Viewed</option>
                                    </select>
                                </li>
                            </ul> */}

                            {this.props.selectedCategory !== "topstories"  && <div>
                                <div className="popular" ><div className="textTitle">Most Popular </div><div className="divider"></div></div>
                                <div className="row" >
                                    {this.renderPopularContent()}
                                    <hr align="left" style={{ backgroundColor: "#444444", margin: "15px" }} width="100%"></hr>
                                </div>

                            </div>
                            }
                        </div>
                        }

                        <div className="row" style={{ padding: "0 0 25px 15px" }}>
                            {this.renderContents()}
                        </div>
                    </div>
                    {this.loadMore()}

                </div>



            </div>
        );
    }
}

export default NewsFeed;
