import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../Loader/Loader';
var contentText;
var UUID;


class Exercise extends Component {

  constructor(props) {
    super(props);
    this.state = {
      questions: "",
      contentText: "",
      UUID:"",
      questionsAndAnswers: "",
      answeredQuestions: [],
      displayTryAgain: false,
      displayCheckAnswers: true,
      displayMoreQuestions: true,
      displayLoader: true
    }
    // Make it as an environment variable
    this.serverPath = process.env.LEVELWISE_API_URL || "/v1";
    this.handleExercise = this.handleExercise.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.tryAgain = this.tryAgain.bind(this);

  }

  handleExercise() {
    this.setState({ displayMoreQuestions: true, displayCheckAnswers: true, displayTryAgain: false, displayLoader: true })
    var content_ = this.state.contentText;
    var UUID_ = this.state.UUID;
    if (content_) {
      // eslint-disable-next-line no-useless-escape
      var exerciseContent_ = content_.replace(/"/g, '\"');
      var exerciseContent = exerciseContent_.replace(/\n/g, '');
      axios.post(this.serverPath + "/questiongeneration/gapselection", {
        "content": exerciseContent,
        "pos": "ALL"
      }).then((res) => {//on success
        let questionAndAnswers = []
        res.data["data"].forEach(function (value, key) {
          if (value) {
            var htmlText = "<span className=\"text-justify h3\">" + value.question + "</span>";
            var question = htmlText.replace(/_+/g, "<input type='text' class='exerciseInput'/>");
            var answer = value.answer;
            var questionAnswer = { "question": { __html: question }, "answer": answer }
            questionAndAnswers.push(questionAnswer);
          }
        });
        let questAnswer = res.data["data"].length > 0 ? questionAndAnswers : "No logs recorded";
            this.setState({ questionsAndAnswers: questAnswer, displayLoader: false });
      }).catch((error) => {
        //on error
        alert("There is an error in API call.");
      });
    }
    else {
    }
    // this.setState({ displayLoader: true })
  }

  checkAnswers() {
    var questionInput = document.querySelectorAll(".exerciseInput");
    let correctAnswered = 0;
    let questionsLength = questionInput.length;
    let answers = [];
    for (let i = 0; i < questionsLength; i++) {
      var userInput = questionInput[i].value;
      answers.push(userInput);
      if (userInput.toLowerCase() === this.state.questionsAndAnswers[i]["answer"].toLowerCase()) {
        correctAnswered += 1;
        questionInput[i].style.borderColor = "#4CAF50";
      }
      else {
        questionInput[i].style.borderColor = "#FF1D1D";
      }
      questionInput[i].style.backgroundColor = "#efefef";
      questionInput[i].disabled = true;
    }
    this.setState({ answeredQuestions: answers });
    if (correctAnswered === questionsLength) {
      this.setState({ displayCheckAnswers: false, displayTryAgain: false });
      document.getElementById("exerciseMessage").style.color = "#1CA250";
      document.getElementById("exerciseMessage").innerHTML = "<p> Great job! You answered all the questions correctly.</p>"
    }
    else {
      this.setState({ displayCheckAnswers: false, displayTryAgain: true });
      document.getElementById("exerciseMessage").style.color = "#0079C4";
      document.getElementById("exerciseMessage").innerHTML = "<p> You answered " + correctAnswered + " out of " + questionsLength + " questions correctly. Try again!</p>"
    }
  }

  tryAgain() {
    var questionInput = document.querySelectorAll(".exerciseInput");
    document.getElementById("exerciseMessage").style.display = "none";
    let questionsLength = questionInput.length
    for (let i = 0; i < questionsLength; i++) {
      let previousAttemptedAnswer = this.state.answeredQuestions[i];
      if (previousAttemptedAnswer.toLowerCase() === this.state.questionsAndAnswers[i]["answer"].toLowerCase()) {
        questionInput[i].value = this.state.questionsAndAnswers[i]["answer"];
        questionInput[i].style.borderColor = "#4CAF50";
      }
      else {
        questionInput[i].disabled = false;
        questionInput[i].value = "";
        questionInput[i].style.borderColor = "#A9A9A9";
        questionInput[i].style.backgroundColor = "#ffffff";
      }
    }
    this.setState({ displayCheckAnswers: true, displayTryAgain: false });
  }

  componentDidMount() {
    this.setState({ contentText: contentText, UUID:UUID }, this.handleExercise);
  }


  render() {
    contentText = this.props.content;
    UUID = this.props.UUIDD;

    return (
      <div className="modal" id="exerciseModal" tabIndex="-1" role="dialog">
        <div className=" modal-dialog  exerciseModal" role="document">
          <div className="modal-content">
            <div className="modal-header modalHead">
              <div style={{ padding: "0px 3rem" }}>Exercise <span></span></div>
              <img className="closeBtn" style={{ marginBottom: "-5px", fontSize: "23px" }} onClick={this.props.onCloseButton} src="../../ic_close_48px.svg" alt="" />
                          </div>
            {/* Here starts the questions or error */}
            {this.state.displayLoader ? <div style={{ height: "71vh", overflowY: "scroll" }}><Loader /></div> :

              <div>
                {this.state.questionsAndAnswers !== "" &&
                  <div>
                    {this.state.questionsAndAnswers === "No logs recorded" ?
                      <div>{this.state.questionsAndAnswers}</div> :
                      <div style={{ padding: "0px 3rem" }}>
                        <div className="questionsDiv">
                          <div className="exerciseAlertMessage"></div>
                          <div id="exerciseMessage" style={{ textAlign: "center", color: "#0079C4" }}></div>
                          <div className="exerQuote" >Please enter your answers in the blanks given:</div>

                          {this.state.questionsAndAnswers.map((quesAns, index) => {
                            return (
                              <div key={index}>
                                <div className="questionDiv">
                                  <span className="questionIndex">{index + 1}.</span> <span className="questionText" dangerouslySetInnerHTML={quesAns.question} />
                                </div>
                                <span>{quesAns.answer}</span>
                              </div>
                            )
                          })}
                        </div>
                        <div className="modal-footer" style={{ justifyContent: "center" }}>
                          <button className="moreQuestions" onClick={this.handleExercise}>More Questions</button>
                          {this.state.displayCheckAnswers && <a href="#exerciseMessage" className="CheckAns" onClick={this.checkAnswers}>Check Answers</a>}
                          {this.state.displayTryAgain && <a href="#exerciseMessage" className="CheckAns" onClick={this.tryAgain}>Try Again</a >}
                        </div>

                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

}

export default Exercise;