import React, { Component } from 'react';
import { Header } from '../Header/Header';
import Footer from "../Footer/Footer";
import Loader from '../Loader/Loader';
import ConfigContext, { config } from '../../utils/config-context';
import styles from './Preferences.module.css';
import { getPreferences, updatePreferences } from '../../requests';


class Preferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: config,
            selectedLanguage: "",
            selectedVoice: "",
            languages: ["en-US", "en-GB", "en-IN", "en-AU"],
            languagesFull: { "en-US": "English - US", "en-GB": "English - UK", "en-IN": "English - IN", "en-AU": "English - AU" },
            voices: ["MALE", "FEMALE"],
            voicesFull: { "MALE": "Male", "FEMALE": "Female" },
            audPlaying: false,
            audLoading: true,
            prefLoader: true,
            audInstance: "",
            userPreferences: [],
            updateMessage: "",
            updateCode: 200,
            playFromStart: true,
            audioDuration: 0,
            noPrefChange: true
        }
        this.timer = null;
        this.lev_url = "/v1/gettingquestions/"
        // this.lev_url = "http://ac4a53352967411ea882a0e4ac2db6f1-1960014097.us-east-1.elb.amazonaws.com/v1/gettingquestions/";
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getPreferences = this.getPreferences.bind(this);
        this.setSelectedLanguage = this.setSelectedLanguage.bind(this);
        this.setSelectedVoice = this.setSelectedVoice.bind(this);
        this.playTestAudio = this.playTestAudio.bind(this);
        this.cancelingUpdate = this.cancelingUpdate.bind(this);
        this.updatingPreferences = this.updatingPreferences.bind(this);
        this.onPrefChange = this.onPrefChange.bind(this);
        this.stopAudio = this.stopAudio.bind(this);
    }
    async getPreferences() {
        let userPreferences = this.state.userPreferences;
        let selectedLanguage = "en-GB";
        let selectedVoice = "FEMALE";
        try {
            const preferencesObj = await getPreferences();
            const preferences = preferencesObj.preferences;
            if (preferences.voice_type) {
                selectedLanguage = preferences.voice_type;
            }
            if (preferences.voice_gender) {
                selectedVoice = preferences.voice_gender;
            }
        }
        finally {
            userPreferences[0] = selectedLanguage;
            userPreferences[1] = selectedVoice;
            this.setState({ selectedLanguage, selectedVoice, userPreferences, prefLoader: false });
            await this.onPrefChange(selectedLanguage, selectedVoice);
        }
    }

    async stopAudio() {
        var playTestAudioElement = document.getElementById("playTestAudioButton")
        this.state.audInstance.pause();
        this.state.audInstance.currentTime = 0;
        playTestAudioElement.style.backgroundColor = "#FFFFFF";
        this.setState({ audPlaying: false });
    }

    async onPrefChange(language, gender, reAccess) {
        this.setState({ audioDuration: 0 })
        if (reAccess === false) {
            console.log("Audio already loaded");
        }
        else {
            this.setState({ audLoading: true });
            var audPlayElem = document.getElementById("playTestAudioButton")
            audPlayElem.style.opacity = 0.5;
            audPlayElem.style.pointerEvents = "none";

            let data = { "level": "testAudio", "uuid": "testAudio", "audio_path": "content/audio_files/krishnaReddy971", "question_uid": "", "gender": gender, "language": language }
            await fetch(this.lev_url + "getaudio", {
                method: 'POST',
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((response) => {
                return response.blob();
            }).then(async (blob) => {
                let blobUrl = URL.createObjectURL(blob);
                var audObject = new Audio(blobUrl);
                this.setState({ audInstance: audObject, audLoading: false });
                audPlayElem.style.opacity = 1.0;
                audPlayElem.style.pointerEvents = "all";
            }).catch(err => {
                console.log(err);
            });
        }

    }

    async setSelectedLanguage(e) {
        this.stopAudio();
        var updateLink = document.getElementById("updateLink");
        var updateMessageEl = document.getElementById("updateMessage");
        updateLink.classList.add("updatePreferencesLink");
        updateMessageEl.style.display = "none";
        let selLang = e.target.id;
        if (this.state.selectedLanguage === selLang) {
            this.setState({ selectedLanguage: selLang, playFromStart: false });
        }
        else {
            this.setState({ selectedLanguage: selLang, playFromStart: false, noPrefChange: false });
        }
    }

    async setSelectedVoice(e) {
        this.stopAudio();
        var updateLink = document.getElementById("updateLink");
        var updateMessageEl = document.getElementById("updateMessage");
        updateLink.classList.add("updatePreferencesLink");
        updateMessageEl.style.display = "none";
        var selVoice = e.target.id
        if (this.state.selectedVoice === selVoice) {
            this.setState({ selectedVoice: e.target.id, playFromStart: false });

        }
        else {
            this.setState({ selectedVoice: e.target.id, playFromStart: false, noPrefChange: false });
        }
    }

    async playTestAudio() {
        clearTimeout(this.timer);
        let playTestAudioElement = document.getElementById("playTestAudioButton");
        if (this.state.audPlaying) {
            this.state.audInstance.pause();
            this.state.audInstance.currentTime = 0;
            playTestAudioElement.style.backgroundColor = "#FFFFFF";
            this.setState({ audPlaying: false });
        }
        else {
            var audioDuration = this.state.audioDuration;
            if (this.state.playFromStart === false && this.state.noPrefChange === false) {
                await this.onPrefChange(this.state.selectedLanguage, this.state.selectedVoice);

            }
            else {
                audioDuration = this.state.audInstance.duration;
            }
            if (audioDuration > 0) {
                this.setState({ audPlaying: true })
                playTestAudioElement.style.backgroundColor = "#FFF2E8";
                this.state.audInstance.play();
                this.timer = setTimeout(() => {
                    this.state.audInstance.pause();
                    this.state.audInstance.currentTime = 0;
                    playTestAudioElement.style.backgroundColor = "#FFFFFF";
                    this.setState({ audPlaying: false });
                }, audioDuration * 1000);
            }
            else {
                await this.state.audInstance.addEventListener('loadedmetadata', async (e) => {
                    let audioDuration = await e.target.duration;

                    this.setState({ audPlaying: true })
                    playTestAudioElement.style.backgroundColor = "#FFF2E8";
                    this.state.audInstance.play();
                    this.timer = setTimeout(() => {
                        this.state.audInstance.pause();
                        this.state.audInstance.currentTime = 0;
                        playTestAudioElement.style.backgroundColor = "#FFFFFF";
                        this.setState({ audPlaying: false });
                    }, audioDuration * 1000);
                    this.setState({ audioDuration })
                });
            }
        }
        this.setState({ noPrefChange: true });
    }

    async cancelingUpdate() {
        this.stopAudio()
        let userPreferences = this.state.userPreferences;
        this.setState({ selectedLanguage: userPreferences[0], selectedVoice: userPreferences[1], noPrefChange: false });
    }

    async updatingPreferences() {
        var updateMessageEl = document.getElementById("updateMessage");
        var updateLink = document.getElementById("updateLink");
        let userPreferences = this.state.userPreferences;
        const updateStatus = await updatePreferences({
            "voice_type": this.state.selectedLanguage,
            "voice_gender": this.state.selectedVoice
        })

        var updateMessage = "";
        if (updateStatus.code === 200) {
            userPreferences[0] = this.state.selectedLanguage;
            userPreferences[1] = this.state.selectedVoice;
            let user = JSON.parse(localStorage.getItem("user"))
            user.language = this.state.selectedLanguage;
            user.gender = this.state.selectedVoice;
            localStorage.setItem("user", JSON.stringify(user));
            updateMessage = "Your preferences have been updated successfully.";
            updateLink.classList.remove("updatePreferencesLink");
        }
        else {
            updateMessage = "Something went wrong. Try again after some time!";
            updateLink.classList.remove("updatePreferencesLink");
        }
        updateMessageEl.style.display = "block";
        setTimeout(() => {
            updateMessageEl.style.display = "none";
            updateMessage = "";
        }, 3000)
        this.setState({ userPreferences, updateCode: updateStatus.code, updateMessage });

    }

    async componentDidMount() {
        await this.getPreferences();
        let dropdown = document.querySelector(".dropdown-menu");
        let setDrpdwn = document.querySelector(".settingMenu_dropdown");
        let mySol = document.querySelector(".mySolutions");
        let arrowIcon = document.querySelector(".solutionsIcon");
        let profileSel = document.querySelector(".profileSelect");
        let accentLanguageDiv = document.querySelector(".accentLanguageDiv");
        let accentLanguages = document.querySelector(".accentLanguages");
        let spanLangElement = document.querySelector(".spanLangElement");

        let languagedropdown = document.querySelector(".languagedropdown");
        let accentVoiceDiv = document.querySelector(".accentVoiceDiv");
        let spanVoiceElement = document.querySelector(".spanVoiceElement");
        let accentVoices = document.querySelector(".accentVoices");

        let voiceDropDown = document.querySelector(".voiceDropDown");

        window.onclick = e => {
            if ((e.target === accentLanguageDiv || e.target === spanLangElement || e.target === accentLanguages) &&
                (languagedropdown.style.display === "none" || languagedropdown.style.display === "")) {
                languagedropdown.style.display = "block";
            }
            else {
                languagedropdown.style.display = "none";
            }
            if ((e.target === accentVoiceDiv || e.target === accentVoices || e.target === spanVoiceElement) &&
                (voiceDropDown.style.display === "none" || voiceDropDown.style.display === "")) {
                voiceDropDown.style.display = "block";
            }
            else {
                voiceDropDown.style.display = "none";
            }
            (e.target === mySol || e.target === arrowIcon) &&
                (dropdown.style.display === "" || dropdown.style.display === "none")
                ? (dropdown.style.display = "block")
                : (dropdown.style.display = "none");
            e.target === profileSel &&
                (setDrpdwn.style.display === "" || setDrpdwn.style.display === "none")
                ? (setDrpdwn.style.display = "block")
                : (setDrpdwn.style.display = "none");

        };
    }

    render() {

        return (
            <div className="abcc">
                <div>
                    {/* header starts here */}
                    <ConfigContext.Provider value={this.state.config}>
                        <Header from="preference" />
                    </ConfigContext.Provider>
                    {/* header ends here */}
                    {this.state.prefLoader ? <Loader /> :
                        <div>
                            <div className="row-fluid container clearfix">
                                <div className={`${styles.preferenceMainDiv}`}>
                                    <div className={`${styles.prefHeader}`}>
                                        <b style={{ fontSize: "18px", fontWeight: "600" }}>My Preferences</b>
                                    </div>
                                    <div className={`${styles.prefContainer}`}>
                                        <div className={`${styles.updateMessageDiv}`} id="updateMessage">
                                            {this.state.updateCode === 200 ?
                                                <p className={`${styles.successUpdateMessage}`}><i className={`${styles.infoIcon} fa fa-check-circle`}></i>{this.state.updateMessage}</p>
                                                :
                                                <p className={`${styles.failureUpdateMessage}`}><i className={`${styles.infoIcon} fa fa-exclamation-triangle`}></i>{this.state.updateMessage}</p>
                                            }
                                        </div>
                                        <div className={`${styles.accentDiv}`}>
                                            <p className={`${styles.accentH}`}>Accent Preference</p>
                                            <div>
                                                <p>Please select your preferred accent.</p>
                                                <div className={`${styles.accentLanguagesDiv}`}>
                                                    <p className={`${styles.dropDownLabel}`}>Accent</p>
                                                    <div className="accentLanguageDiv">
                                                        <a className={`accentLanguages`}>{this.state.languagesFull[this.state.selectedLanguage]} <span id="spanLangElement" className={`fa fa-angle-down accentLangIcon ${styles.accentLanguagesIcon}`}></span></a>
                                                    </div>
                                                    <div className={`languagedropdown`}>
                                                        {this.state.languages.map((lang) => {
                                                            return <span id={lang} key={lang} className={`langOption`} onClick={this.setSelectedLanguage}>{this.state.languagesFull[lang]}</span>
                                                        })}
                                                    </div>
                                                </div>
                                                <div className={`${styles.accentVoiceDiv}`}>
                                                    <p className={`${styles.dropDownLabel}`}>Voice</p>
                                                    <div className="accentVoiceDiv">
                                                        <a className={`accentVoices`}>{this.state.voicesFull[this.state.selectedVoice]} <span id="spanVoiceElement" className={`fa fa-angle-down accentLangIcon ${styles.accentLanguagesIcon}`}></span></a>
                                                    </div>
                                                    <div className={`voiceDropDown`}>
                                                        {this.state.voices.map((voice) => {
                                                            return <span id={voice} key={voice} className={`voiceOption`} onClick={this.setSelectedVoice}>{this.state.voicesFull[voice]}</span>
                                                        })}
                                                    </div>
                                                </div>

                                                <div className="testVoice">
                                                    <a className={`${styles.audioButtonListen}`} id="playTestAudioButton" onClick={this.playTestAudio}>
                                                        {this.state.audLoading ?
                                                            <span className={`${styles.audSpan}`}><img className={`${styles.audioLoadingImg}`} alt="loading" src="../../loader_rolling.gif" /> Listen to test voice</span>
                                                            : <span>
                                                                {this.state.audPlaying ?
                                                                    <span className={`${styles.audSpan}`}><img className={`${styles.speakerSvg2}`} alt="playing" src="../../speaker.gif" /> Listen to test voice</span>
                                                                    :
                                                                    <span className={`${styles.audSpan}`}><img className={`${styles.speakerSvg}`} alt="listen" src="../../speaker.svg" /> Listen to test voice</span>
                                                                }
                                                            </span>}
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.updateDiv}`}>
                                        <div className={`${styles.updateSubDiv}`}>
                                            <ul className={`${styles.updateUl}`}>
                                                <li className={`${styles.cancelLink}`} onClick={this.cancelingUpdate}><span>Cancel</span></li>
                                                <li className={`${styles.updateLink}`} id="updateLink" onClick={this.updatingPreferences}><span>Update</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* footer starts here */}

                            <Footer />
                            {/* footer ends here */}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Preferences
