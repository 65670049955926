import React, { Component } from 'react';
import styles from './PinnedContents.module.css';
import { EditText, EditTextarea } from 'react-edit-text';
import PinnedContentTable from "./PinnedContentsTable";
import Loader from "../Loader/Loader";
import { versionContents, saveContent, pinContents, exerciseContentPublish, deleteExercises, deleteContent } from '../../requests';
var pinnedContents = require("../../data/pinnedContents.json");



class PinnedContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pinnedContents: [],
            filterLevels: [],
            filterCategories: [],
            publishedLevels: [],
            runHandleDidMount: false,
            loading: false,
            runPaginate: true,
            loadingPagination: false,
            showFilters: false,
            activeButton: "drafts",
            totalRecords: null,
            totalPages: null,
            pageSize: 20,
            currentPage: 1,
            startIndex: 0,
            endIndex: null,
            offset: 0,
            pages: [],
            allPages: [],
            activePage: 1,
            activeButton1: "Content",
            showContent: true,
            showExer: false,
            showListenExer: false,
            showVocabExer: true,
            pinnedContentsDrafts0: "",
            versionContent: [],
            UUID: "",
            displayLoader: false,
            exerQues: [],
            tempUUID: "",
            exerVid: [],
            exerciseVersion: "",
            exerVersioningLoop: [],
            previewPage: false,
            showPopUp:false,
            showDeletePopUp:false,
            deleteUUID:"",
            showPublish:false,
            contentVersiion:"",
            exerciiseVersion:"",
            versionUUID:"",
            showDeleteVersion:false


        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillMount = this.componentWillMount.bind(this);
        this.publishedContents = this.publishedContents.bind(this);
        this.draftedContents = this.draftedContents.bind(this);
        this.activePinnedButton = this.activePinnedButton.bind(this);
        this.toggleShowFilters = this.toggleShowFilters.bind(this);
        this.filters = this.filters.bind(this);
        this.levelsFilter = this.levelsFilter.bind(this);
        this.removeFilters = this.removeFilters.bind(this);
        this.paginate = this.paginate.bind(this);
        this.handlePageNumber = this.handlePageNumber.bind(this);
        this.handleDidMount = this.handleDidMount.bind(this);
        this.handlePageSize = this.handlePageSize.bind(this);
        this.previewButton = this.previewButton.bind(this);
        this.viewContents = this.viewContents.bind(this);
        this.viewExercise = this.viewExercise.bind(this);
        this.activeButton = this.activeButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.previewType = this.previewType.bind(this);
        this.publishBtn = this.publishBtn.bind(this);
        //this.publishBtn1=this.publishBtn1.bind(this);
        this.optionsList = this.optionsList.bind(this);
        this.previewExercise = this.previewExercise.bind(this);
        this.editButton = this.editButton.bind(this);
        this.deleteContent = this.deleteContent.bind(this);
        this.deleteVersions = this.deleteVersions.bind(this);
        this.unpublishContent = this.unpublishContent.bind(this);
        this.showpinPopUp=this.showpinPopUp.bind(this);
        this.discardpinPopUp=this.discardpinPopUp.bind(this);
        this.deletepinPopUp=this.deletepinPopUp.bind(this);
        this.showDeletePopUp=this.showDeletePopUp.bind(this);
        this.discardDeletePopUp=this.discardDeletePopUp.bind(this);
        this.deletePopUp=this.deletePopUp.bind(this);
        this.publishBtn1 = this.publishBtn1.bind(this);
        this.publishClose = this.publishClose.bind(this);
        this.publishYes = this.publishYes.bind(this);
        this.versionBtn = this.versionBtn.bind(this);
        this.versionClose = this.versionClose.bind(this);
        this.versionYes = this.versionYes.bind(this);

    }


    async handleDidMount() {
        let totalRecords = this.state.totalRecords;
        let startIndex = this.state.startIndex;
        let endIndex = this.state.endIndex;
        let currentPage = this.state.currentPage;
        let pageSize = this.state.pageSize;
        startIndex = (currentPage - 1) * pageSize;
        endIndex = currentPage * pageSize;
        if (totalRecords < endIndex) {
            endIndex = totalRecords;
        }
        let contents = this.state.allContents.slice(startIndex, endIndex);
        this.setState({ startIndex, endIndex }, this.state.runPaginate ? this.paginate : null);

    }



    async activeButton() {
        var activeButton1 = this.state.activeButton1;
        var pinned = document.getElementsByClassName("previewButton");
        for (let i = 0; i < pinned.length; i++) {
            if (pinned[i].id === activeButton1) {
                pinned[i].style.color = "#E0291A";
                pinned[i].style.borderBottom = "3px solid";
                pinned[i].style.fontWeight = "600";
            }
            else {
                pinned[i].style.color = "#939495";
                pinned[i].style.borderBottom = "none";
            }
        }
    }

    handlePageSize(e) {
        let pageSize = e.target.value;
        let totalPages = Math.ceil(this.state.totalRecords / pageSize)
        this.setState({ runHandleDidMount: false, runPaginate: true, pageSize: parseInt(pageSize), totalPages: totalPages, currentPage: 1, activePage: 1, loading: true }, async () => {
            await this.handleDidMount();
        });
    }

    async draftedContents() {
        this.setState({ pinnedContents: pinnedContents["drafts"], activeButton: "drafts" }, this.activePinnedButton, this.props.pinnedContentQuery("drafts"));
    }


    async previewType() {
        if ((this.props.previewType) === "Drafts") {
            this.props.listPage();
            this.draftedContents();
        } else {
            this.props.listPage();
            this.publishedContents();
        }

    }

    async publishedContents() {
        this.setState({ pinnedContents: pinnedContents["published"], activeButton: "published" }, this.activePinnedButton, this.props.pinnedContentQuery("publish"));
    }

    async toggleShowFilters() {
        let filterShow = this.state.showFilters;
        this.setState({ showFilters: !filterShow });
    }

    async removeFilters(arr, itemToRemove) {
        var array = arr;
        var filtered = array.filter(function (value, index, arr) { return value === itemToRemove; })
        return filtered;
    }

    async showpinPopUp(UUID) {
        this.setState({deleteUUID:UUID});
        window.scrollTo(0, 0);
        document.getElementById("pinContentss").className += "deleteDiv";
        this.setState({ showPopUp: true });
       }

       async discardpinPopUp(){
        document.getElementById("pinContentss").classList.remove("deleteDiv");
        this.setState({ showPopUp: false });
    }

  

       async deletepinPopUp(){
        this.deleteContent(this.state.deleteUUID,"","");
        document.getElementById("pinContentss").classList.remove("deleteDiv");
        this.setState({ showPopUp: false });
       }

       

       async showDeletePopUp(UUID) {
        this.setState({deleteUUID:UUID});
        window.scrollTo(0, 0);
        document.getElementById("editContentss").className += "deleteDiv";
        this.setState({ showDeletePopUp: true });
       }

       async discardDeletePopUp(){
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showDeletePopUp: false });
    }

  

       async deletePopUp(){
        this.deleteContent(this.state.deleteUUID,"","");
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showDeletePopUp: false });
       }


    async publishBtn1(versionContent,exerciseVersion) {
        this.setState({contentVersiion:versionContent,exerciiseVersion:exerciseVersion})
        window.scrollTo(0, 0);
        document.getElementById("editContentss").className += " deleteDiv";
        this.setState({ showPublish: true });
    }

    async publishClose() {
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showPublish: false });
    }

    async publishYes() {
        this.publishBtn(this.state.contentVersiion,this.state.exerciiseVersion);
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showPublish: false });
    }


    async versionBtn(uuid,versionContent,exerciseVersion) {
        this.setState({versionUUID:uuid,contentVersiion:versionContent,exerciiseVersion:exerciseVersion})
        window.scrollTo(0, 0);
        document.getElementById("editContentss").className += " deleteDiv";
        this.setState({ showDeleteVersion: true });
    }

    async versionClose() {
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showDeleteVersion: false });
    }

    async versionYes() {
        this.deleteVersions(this.state.versionUUID,this.state.contentVersiion,this.state.exerciiseVersion);
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showDeleteVersion: false });
    }


    async levelsFilter() {
        // var allLevCheck = document.getElementById("allLev")
        // //////console.log("Levels Filter", this.state.filterLevels)
        // let levFilter = document.getElementsByClassName("levCheckBox");
        // for(let i=0; i<levFilter.length; i++){
        //     if(allLevCheck.checked===true){
        //         levFilter[i].checked = true;
        //         let levels = new Array(this.state.filterLevels);
        //         levels.push(levFilter[i].id);
        //         let uniqLev = levels => [...new Set(levels)];
        //         //////console.log("uniqLev", uniqLev);
        //         this.setState({filterLevels:uniqLev});
        //     }
        //     if(levFilter[i].checked===true){
        //         let levels = new Array(this.state.filterLevels);
        //         levels.push(levFilter[i].id);
        //         let uniqLev = levels => [...new Set(levels)];
        //         //////console.log("uniqLev", uniqLev);
        //         this.setState({filterLevels:uniqLev});
        //     }
        //     else if(levFilter[i].checked===false){
        //         let levels = new Array(this.state.filterLevels);
        //         let filteredLevels = await this.removeFilters(levels, levFilter[i].id);
        //         this.setState({filterLevels: filteredLevels});
        //     }
        // }
        ////////console.log("++++++Levels Filter", this.state.filterLevels);
        //////console.log(this.props.true);
    }


    async deleteContent(uuid, contentUUID, exerciseUUID) {
        this.props.previewLoader(true);
        let content = this.props.previewType === "Drafts" ? "drafts" : "publish";

        const deleteExercise = await deleteExercises({
            uuid: uuid,
            content: "drafts",
            versionId: exerciseUUID
        });
        const deleteContents = await deleteContent({
            uuid: uuid,
            content: "drafts",
            versionId: contentUUID
        });
        this.props.previewLoader(false);
        if (deleteExercise.code === "200" && deleteContents.code === "200") {

            if (content === "drafts") { this.setState({ pinnedContents: pinnedContents["drafts"], activeButton: "drafts" }, this.activePinnedButton, this.props.pinnedContentQuery("drafts")); }
            else if (content === "publish") {
                this.setState({ pinnedContents: pinnedContents["published"], activeButton: "published" }, this.activePinnedButton, this.props.pinnedContentQuery("publish"));
            }

        }
    }

    async unpublishContent(uuid, contentUUID, exerciseUUID) {
        this.props.previewLoader(true);
        const deleteExercise = await deleteExercises({
            uuid: uuid,
            content: "publish",
            versionId: ""
        });
        const deleteContents = await deleteContent({
            uuid: uuid,
            content: "publish",
            versionId: ""
        });
        this.props.previewLoader(false);
        if (deleteExercise.code === "200" && deleteContents.code === "200") {


            this.setState({ pinnedContents: pinnedContents["drafts"], activeButton: "drafts" }, this.activePinnedButton, this.props.pinnedContentQuery("drafts"));


        }
    }

    async deleteVersions(uuid, contentUUID, exerciseUUID) {
        this.props.previewLoader(true);
        let content = this.props.previewType === "Drafts" ? "drafts" : "publish";
        const deleteExercise = await deleteExercises({
            uuid: uuid,
            content: "drafts",
            versionId: exerciseUUID
        });
        const deleteContents = await deleteContent({
            uuid: uuid,
            content: "drafts",
            versionId: contentUUID
        });


        const versionContent = await versionContents({
            type: "drafts",
            uuid: uuid,
            version: true,
            versionId: ""

        });
        if (versionContent !== null) {
            const getQuest = await exerciseContentPublish({
                uuid: uuid,
                category: "vocabulary",
                versionId: ""

            });

            this.setState({ versionContent: versionContent, UUID: uuid, exerQues: getQuest["exercisePublish"], exerVid: getQuest });
            this.props.previewLoader(false);
            this.props.previewPage();

        }
        else {
            this.props.previewLoader(false);
            if (deleteExercise.code === "200" && deleteContents.code === "200") {
                if (content === "drafts") { this.setState({ pinnedContents: pinnedContents["drafts"], activeButton: "drafts" }, this.activePinnedButton, this.props.pinnedContentQuery("drafts")); }
                else if (content === "publish") {
                    this.setState({ pinnedContents: pinnedContents["published"], activeButton: "published" }, this.activePinnedButton, this.props.pinnedContentQuery("publish"));

                }
            }
        }

    }

    async publishBtn(version, exVersion) {
        this.props.enableLoader();
        let versionContent = this.props.versionContent1.length === 0 ? this.state.versionContent : this.props.versionContent1;
        let uuid = versionContent[0]["UUID"];
        let level1 = (versionContent[0]["Level"] === 1 ? "levelone" : (versionContent[0]["Level"] === 2 ? "leveltwo" : "levelthree"));


        const pinnedContent = await pinContents({
            "level": level1,
            "date": "",
            "uuid": uuid,
            "content": "publish",
            "versionid": version,
            "exerciseVersionId": exVersion

        });
         if (pinnedContent.code === "200") {

            this.props.navigatePreview(uuid, "Published");
        }




    }


    async editButton(UUID, contentVersion, exerVersion) {

        this.props.enableLoader();
        this.props.navigateEdit(UUID, contentVersion, exerVersion);
    }





    filters() {
        const filtersDiv =
            <div className={`${styles.filtersDiv}`}>
                <div className={`${styles.levCatOptions} row container1`}>
                    <div className={`${styles.levelsFilter} col-lg-6 col-md-6 col-sm-6 col-xs-6`}>
                        <p>Level</p>
                        <div className={`${styles.levelsCheckboxes}`}>
                            <div><label><input className={`${styles.filterCheck} levCheckBox`} id="allLev" type="checkbox" onClick={this.levelsFilter} />All</label></div>
                            <div><label><input className={`${styles.filterCheck} levCheckBox`} id="beginnerLev" type="checkbox" onClick={this.levelsFilter} />Beginner</label></div>
                            <div><label><input className={`${styles.filterCheck} levCheckBox`} id="lowerInterLev" type="checkbox" onClick={this.levelsFilter} />Lower Intermediate</label></div>
                            <div><label><input className={`${styles.filterCheck} levCheckBox`} id="higherInterLev" type="checkbox" onClick={this.levelsFilter} />Higher Intermediate</label></div>
                            <div><label><input className={`${styles.filterCheck} levCheckBox`} id="advancedLev" type="checkbox" onClick={this.levelsFilter} />Advanced</label></div>
                        </div>
                    </div>
                    <div className={`${styles.categoriesFilter} col-lg-6 col-md-6 col-sm-6 col-xs-6`}>
                        <p>Category</p>
                        <div className={`${styles.categoriesCheckboxes}`}>
                            <div><label><input className={`${styles.filterCheck} catCheckBox`} id="allCat" type="checkbox" onClick={this.categoryFilter} />All</label></div>
                            <div><label><input className={`${styles.filterCheck} catCheckBox`} id="business" type="checkbox" onClick={this.categoryFilter} />Business</label></div>
                            <div><label><input className={`${styles.filterCheck} catCheckBox`} id="technology" type="checkbox" onClick={this.categoryFilter} />Technology</label></div>
                            <div><label><input className={`${styles.filterCheck} catCheckBox`} id="science" type="checkbox" onClick={this.categoryFilter} />Science</label></div>
                            <div><label><input className={`${styles.filterCheck} catCheckBox`} id="sports" type="checkbox" onClick={this.categoryFilter} />Sports</label></div>
                            <div><label><input className={`${styles.filterCheck} catCheckBox`} id="health" type="checkbox" onClick={this.categoryFilter} />Health</label></div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.resetApplyDiv}`}>
                    <button className={`${styles.resetButton}`}>Reset</button>
                    <button className={`${styles.applyButton}`}>Apply</button>
                </div>
            </div>
        return filtersDiv;
    }

    async activePinnedButton() {
        var activeButton = this.state.activeButton;
        var pinned = document.getElementsByClassName("pinnedButton");
        for (let i = 0; i < pinned.length; i++) {
            if (pinned[i].id === activeButton) {
                pinned[i].style.color = "#E0291A";
                pinned[i].style.borderBottom = "3px solid";
                pinned[i].style.fontWeight = "600";
            }
            else {
                pinned[i].style.color = "#939495";
                pinned[i].style.borderBottom = "none";
            }
        }
    }

    handlePageNumber(e) {
        var pageNumber = e.target.value;
        pageNumber = parseInt(pageNumber);
        this.paginate(pageNumber);
    }


    async viewExercise(UUID1, category, exerVersion) {
        this.setState({ activeButton1: "Exercise", tempUUID: UUID1, showExer: true, showContent: false, exerciseVersion: exerVersion }, this.activeButton)
        this.previewExercise(UUID1, category, exerVersion);
    }
    async viewContents() {
        this.setState({ activeButton1: "Content", showContent: true, showExer: false }, this.activeButton)
    }


    async paginate(pageNumber = "setToCurrent") {
        if (pageNumber < 1) {
            return false
        }
        if (pageNumber >= 1) {
            this.setState({ runHandleDidMount: true, runPaginate: false });
        }
        else {
            pageNumber = 1;
            this.setState({ runHandleDidMount: false, runPaginate: true })
        }
        this.setState({ loading: true });
        var startPage, endPage;
        var allPages = [];
        for (var page = 1; page <= this.state.totalPages; page++) {
            allPages.push(page);
        }
        if (this.state.totalPages <= 6) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = this.state.totalPages;
        }
        else {
            // more than 10 total pages so calculate start and end pages
            if (pageNumber <= 3) {
                startPage = 1;
                endPage = 6;
            } else if (pageNumber + 2 >= this.state.totalPages) {
                startPage = this.state.totalPages - 5;
                endPage = this.state.totalPages;
            } else {
                startPage = pageNumber - 3;
                endPage = pageNumber + 2;
            }
        }

        // creating Pages depends on totalPages
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        this.setState({ activePage: pageNumber, currentPage: pageNumber, pages: pages, allPages: allPages }, () => {
            let offSet = (pageNumber - 1) * this.state.pageSize;
            var endIndex = Math.min(offSet + this.state.pageSize - 1, this.state.totalRecords - 1);
            this.setState({ loading: false, offset: offSet, endIndex: endIndex }, async () => {
                if (this.state.runHandleDidMount) {
                    await this.handleDidMount();
                }
            })


        });
    }

    async componentWillMount() {
        let totalRecords = pinnedContents["drafts"].length;
        let totalPages = parseInt(totalRecords / this.state.pageSize) + 1;
        this.setState({ pinnedContents: pinnedContents["drafts"], allContents: pinnedContents["drafts"], totalRecords, totalPages }, this.paginate);
    }
    async componentDidMount() {
        this.activePinnedButton();
        this.handleDidMount();



    }




    handleChange(e) {

        this.setState({ gender: e.target.value });
        if (e.target.value === ("vocabulary") || e.target.value === ("grammer")) {
            this.setState({ showListenExer: false, showVocabExer: true })
            this.previewExercise(this.state.tempUUID, e.target.value, this.state.exerciseVersion);
        } else {
            this.setState({ showListenExer: true, showVocabExer: false })
        }
    }

    optionsList(index) {
        if (document.getElementById("list" + index).style.display === "inline-flex") {
            document.getElementById("list" + index).style.display = "none"
        } else {
            document.getElementById("list" + index).style.display = "inline-flex"
        }
    }

    async previewButton(id, version, exVersion) {
        this.props.previewLoader(true);
        let UUID = id;
        const versionContent = await versionContents({
            type: "drafts",
            uuid: UUID,
            version: true,
            versionId: version

        });

        const getQuest = await exerciseContentPublish({
            uuid: UUID,
            category: "vocabulary",
            versionId: exVersion

        });

        this.setState({ versionContent: versionContent, UUID: UUID, exerQues: getQuest["exercisePublish"], exerVid: getQuest }, () => {

            this.props.previewLoader(false);
            this.props.previewContents();
            this.props.previewPage();

        });

        this.activeButton();



    }


    async previewExercise(uuid, category, exerVersion) {
        this.setState({ displayLoader: true });
        const getQuest = await exerciseContentPublish({
            uuid: uuid,
            category: category,
            versionId: exerVersion

        });
        this.setState({ exerQues: getQuest["exercisePublish"], exerVid: getQuest },
            () => this.setState({ displayLoader: false }));

    }

    render() {
        let currentPage = this.state.currentPage;
        let totalPages = this.state.totalPages;
        let pages = this.state.pages;
        let allPages = this.state.allPages;
        let pinnedContentsDrafts = this.props.pinnedContentsDrafts;
        let versionContent1 = this.state.versionContent.length === 0 ? this.props.versionContent1 : this.state.versionContent;
        let exerVersion = this.state.exerVid.length === 0 ? this.props.exerVersion["versionId"] : this.state.exerVid["versionId"];
        let exerVersioningLoop = this.state.exerVid.length === 0 ? this.props.exerVersion["versions"] : this.state.exerVid["versions"];
        let UUID1 = versionContent1.length === 0 ? 0 : versionContent1[0]["UUID"];
        let tags = versionContent1.length === 0 ? 0 : versionContent1[0]["Tags"].split(',');

        return (
            <div className={styles.PinnedContentsParent} style={{ minHeight: "80vh", margin:"0 89px"}}>
                {this.props.showPinnedPage && <div> <div id="pinContentss"> <p className={styles.pinnedContentsLabel}>Pinned Contents</p>
                    <div style={{ padding: "10px 0 25px 0" }}>
                        <button id="drafts" onClick={this.draftedContents} className={`${styles.pinnedContents} pinnedButton`}>Drafts</button>
                        <button id="published" onClick={this.publishedContents} className={`${styles.pinnedContents} pinnedButton`}>Published</button>
                    </div>
                    {this.props.loadTable ? <div style={{ minHeight: "70vh" }}><div className="mainLoader" style={{ width: "90%" }}></div></div> : <div>
                        <div>
                            {/* <button onClick={this.toggleShowFilters} className={`${styles.filterButton}`}><i className={`${styles.filterIcon} fa fa-filter`}></i>Filter</button> */}

                            {this.state.showFilters ? <div> {this.filters()} </div> : ""}

                        </div>
                        <div>
                            <PinnedContentTable
                                startIndex={this.state.startIndex}
                                pinnedContents={this.state.pinnedContents}
                                previewButton={this.previewButton}
                                pinnedContentsDrafts1={pinnedContentsDrafts}
                                editButton={this.editButton}
                                deleteContent={this.deleteContent}
                                previewType1={this.props.previewType}
                                showpinPopUp={this.showpinPopUp}
                            />
                        </div>


                    </div>
                    }
                </div>
                { this.state.showPopUp &&<div class={styles.discardChanges}>
                    <div class={styles.discardHeader}><b>Delete Content</b> <img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right",cursor:"pointer"}} src="../../ic_close_48px.svg" alt="" onClick={this.discardpinPopUp} /></div>
                    <div class={styles.discardBody}>The content will be removed from Pinned contents and the saved versions will be no longer available. Do you want to continue?</div>
                    <div class={styles.discardFooter}><div className={styles.rightbtn} style={{padding: "15px 3px 0 0"}}><button className={styles.discardbtn1} onClick={this.discardpinPopUp} style={{paddingRight:"15px"}}>Cancel</button><button className={styles.discardbtn} onClick={this.deletepinPopUp}>Yes, delete</button></div></div>
                </div>}</div>}
                {this.props.showPreviewPage && <div className="editContentss" >
                    <div id="editContentss" style={{paddingTop:"30px"}}>
                    <div >
                        <p className={styles.editContentsLabel} onClick={this.previewType}>
                            <i className='fa fa-angle-left' style={{fontWeight:"600",color:"#E0291A"}}></i> {this.props.previewType}
                        </p>
                        <div className={styles.editID}>Previewing: {versionContent1[0]["PreviewVersion"]} </div>
                        <div class={styles.toggleContentBtn}>
                            <button id="Content" onClick={this.viewContents} className={`${styles.editContents} previewButton`}>Content</button>
                            <button id="Exercise" onClick={() => this.viewExercise(UUID1, "vocabulary", exerVersion)} className={`${styles.editContents} previewButton`} style={{ marginLeft: "15px" }}>Exercise</button>
                        </div>
                        {this.state.showContent && <div> <div style={{ float: "left", width: "69%",paddingRight:"50px" }}>
                            <div className={styles.previewTitle}>{versionContent1[0]["Title"]}</div>
                            <div style={{ fontSize: "12px", color: "#686868", paddingBottom: "15px", display: "flex" }}>{versionContent1[0]["Level"] === 1 ? "LowerIntermediate" : (versionContent1[0]["Level"] === 2 ? "Higher Intermediate" : "Advanced")} | {versionContent1[0]["Category"]} <p>{versionContent1[0]["IsRecommendedToChina"] === "true" ? <p><span>| </span><i class="fa fa-check-circle" aria-hidden="true"></i><span> Content eligible for China</span></p> : ""}</p> </div>
                            <div className={styles.previewContent} dangerouslySetInnerHTML={{ __html: versionContent1[0]["Content"] }}></div>
                            <div className={styles.previewContent} ><span style={{ color: "#000000" }}>Summary :<br /><br /></span><span dangerouslySetInnerHTML={{ __html: versionContent1[0]["Summary"] }} /></div>
                            <div className={styles.previewContent} style={{ marginBottom: "30px" }}><span style={{ color: "#000000" }}>Tags :<br /><br /></span><span dangerouslySetInnerHTML={{ __html: versionContent1[0]["Tags"] }} /></div>
                        </div>
                        </div>}
                        {this.state.showExer && <div><div style={{ float: "left", width: "69%" }}>
                            <div className="aEdit" style={{ paddingBottom: "20px" }}><p style={{ width: "55px" }}>Category</p>  <select value={this.state.gender} onChange={this.handleChange}>
                                <option name="vocabulary" value="vocabulary" > Vocabulary</option>
                                <option name="grammar" value="grammer" >Grammar</option>
                                {/* <option name="listen" value="communication" >Listening and Speaking</option> */}
                            </select></div>

                            {this.state.displayLoader ? <div style={{ minHeight: "70vh" }}><div className="mainLoader" style={{ width: "90%" }}></div></div> : <div>  {this.state.showVocabExer && <div>   {this.state.exerQues.map((index, key) => <div style={{ paddingBottom: "30px" }}>
                                <div className={styles.editDiv}> <div className={styles.editQues} style={{ color: "#888888" }}>Question {key + 1}/{this.state.exerQues.length}</div>
                                </div>
                                <div className="editQuestion" style={{ fontSize: "15px", fontWeight: "500", padding: "0px" }} >{index.sentence} </div>

                                {index.question_type === "mcq" ? <div><div className="editQuestion" style={{ color: "#444444", fontSize: "15px", fontWeight: 500, padding: "0px" }}>{index.question}

                                </div>

                                    {index.options.length !== 0 ? <div style={{ paddingBottom: "20px" }}>
                                        <div style={{ overflow: "hidden" }}>
                                            <form>
                                                {index.options.map((index1, key) => <div>{index1 === index.answer ? <div class="optionsEdit" style={{ pointerEvents: "none" }}><input type="radio" id={index.question_uid + key} name="gender" value="male" className="inputOption" defaultChecked />
                                                    <EditText
                                                        style={{ color: "#009CDE" }}
                                                        name="textbox1"
                                                        id={"options" + index1}
                                                        className={"options" + index.question_uid}
                                                        defaultValue={index1}


                                                    /></div> : <div class="optionsEdit" style={{ pointerEvents: "none" }}><input type="radio" id={index.question_uid + key} name="gender" value="male" className="inputOption" />
                                                        <EditText
                                                            name="textbox1"
                                                            id={"options" + index1}
                                                            className={"options" + index.question_uid}
                                                            defaultValue={index1}

                                                        /></div>}</div>)}
                                            </form>
                                        </div></div> :
                                        <div className={styles.optionNum}  >{index.answer}</div>
                                    }

                                </div> : ""}


                                {index.question_type === "gap_filler" ? <div  > <div onClick={() => this.responsejson(index.question_uid, index.question_type)} style={{ fontSize: "14px", lineHeight: "2", pointerEvents: "none" }} id={"question" + index.question_uid} dangerouslySetInnerHTML={{ __html: index.question.replace(/\_+/g, '<input type="text" className="gapfiler" name="itemConsumption" placeholder=' + index.answer + ' id="gapfiller' + index.question_uid + '"  />') }} /></div> : ""}


                                {index.answer_explanation.length !== 0 ? <div className="bEdit" style={{ padding: "0 15px 15px 0px" }}>  <div
                                    style={{ fontSize: "12px", color: "#0079C4", fontWeight: "500" }}
                                    id={"ans_exp" + index.question_uid}
                                    class="editTitle"
                                    name="textbox1"
                                ><b>Explanation</b>: The syllables in the word are {index.answer_explanation.join("-")}</div>
                                </div> : ""}
                            </div>



                            )}
                            </div>}</div>}
                            {this.state.showListenExer && <div>Listening</div>}
                        </div>
                        </div>}

                    </div>


                    <div style={{ width: "31%", float: "left", display: "flex", flexDirection: "column" }}>
                        <div className={styles.versionDetails}><div className={styles.versionDetailsText}>version History</div><div>
                            {versionContent1[0]["Versions"].map((index, key) => <div className={styles.versionNumDetails} style={{ marginTop: "20px" }}><div className={styles.versionNumber} >{index.Version}</div>
                                <div style={{ fontSize: "12px", color: "#3333333", fontWeight: "500", margin: "0px 20px" }} ><p>Uploaded by Admin</p><p> {index.LastModified}</p></div>
                                <div> <div className={styles.ellipses} onClick={this.optionsList.bind(null, key)}><i class="fa fa-ellipsis-v" aria-hidden="true"></i></div>
                                    {this.props.previewType === "Drafts" ? <div id={"list" + key} className={`${styles.pinContentComb} ${styles.editContentButton} btn`} >
                                        <span onClick={() => this.previewButton(UUID1, index.VersionId, exerVersioningLoop[key]["VersionId"])}>Preview</span>
                                        <span onClick={() => this.editButton(UUID1, index.VersionId, exerVersioningLoop[key]["VersionId"])}>Edit</span>
                                        <span onClick={() => this.versionBtn(UUID1, index.VersionId, exerVersioningLoop[key]["VersionId"])}>Delete</span>
                                        <span onClick={() => this.publishBtn(index.VersionId, exerVersioningLoop[key]["VersionId"])} >Publish</span>
                                    </div> : <div id={"list" + key} className={`${styles.pinContentComb} ${styles.editContentButton} btn`} >
                                            <span onClick={() => this.previewButton(UUID1, index.VersionId, exerVersioningLoop[key]["VersionId"])}>Preview</span>
                                            <span onClick={() => this.versionBtn(UUID1, index.VersionId, exerVersioningLoop[key]["VersionId"])}>Delete</span>

                                        </div>}</div>
                            </div>)}
                        </div>
                        </div>

                        <div className={styles.delContent}  onClick={() =>this.showDeletePopUp(UUID1)}><i class="fa fa-trash"   ></i><span>Delete Content</span></div>
                       
                    </div>


                    <div className={styles.contentFooter}>

                        {this.props.previewType === "Drafts" ? <div className={styles.rightbtn}><button className={styles.leftbtn1} style={{ width: "86px" }} onClick={() => this.publishBtn1(versionContent1[0]["VersionId"], exerVersion)}>Publish</button><button className={styles.savebtn} style={{ width: "60px" }} onClick={() => this.editButton(UUID1, versionContent1[0]["VersionId"], exerVersion)}>Edit</button></div> : this.props.previewType === "Published" ? <div className={styles.rightbtn}><button className={styles.savebtn} style={{ width: "86px" }} onClick={() => this.unpublishContent(UUID1, versionContent1[0]["VersionId"], exerVersion)}>Unpublish</button></div> : ""}
                    </div>

                    </div>

                    { this.state.showDeletePopUp &&<div class={styles.discardChanges}>
                    <div class={styles.discardHeader}><b>Delete Content</b> <img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right",cursor:"pointer"}} src="../../ic_close_48px.svg" alt="" onClick={this.discardDeletePopUp} /></div>
                    <div class={styles.discardBody}>The content will be removed from Pinned contents and the saved versions will be no longer available. Do you want to continue?</div>
                    <div class={styles.discardFooter}><div className={styles.rightbtn} style={{padding: "15px 3px 0 0"}}><button className={styles.discardbtn1} onClick={this.discardDeletePopUp} style={{paddingRight:"15px"}}>Cancel</button><button className={styles.discardbtn} onClick={this.deletePopUp}>Yes, delete</button></div></div>
                </div>}

                {this.state.showPublish && <div class={styles.discardChanges}>
                    <div class={styles.discardHeader}><b>Publish</b> <img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right",cursor:"pointer"}} src="../../ic_close_48px.svg" alt="" onClick={this.publishClose} /></div>
                    <div class={styles.discardBody}>Are you sure you want to publish the selected version?</div>
                    <div class={styles.discardFooter}><div className={styles.rightbtn} style={{padding: "15px 3px 0 0"}}><button className={styles.discardbtn1} onClick={this.publishClose} style={{paddingRight:"15px"}}>Cancel</button><button className={styles.discardbtn}  onClick={this.publishYes}>Yes, publish</button></div></div>
                </div>}     
               
                
                {this.state.showDeleteVersion && <div class={styles.discardChanges}>
                    <div class={styles.discardHeader}><b>Delete Version</b> <img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right",cursor:"pointer"}} src="../../ic_close_48px.svg" alt="" onClick={this.versionClose} /></div>
                    <div class={styles.discardBody}>Are you sure you want to delete the selected version?</div>
                    <div class={styles.discardFooter}><div className={styles.rightbtn} style={{padding: "15px 3px 0 0"}}><button className={styles.discardbtn1} onClick={this.versionClose} style={{paddingRight:"15px"}}>Cancel</button><button className={styles.discardbtn}  onClick={this.versionYes}>Yes, delete</button></div></div>
                </div>}  

                </div>}
                {
                    this.props.previewLoader1 && <div style={{ minHeight: "90vh" }}><div className="mainLoader" style={{ width: "90%" }}></div></div>
                }
            </div>
        )
    }
}

export default PinnedContents;
