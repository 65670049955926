import React, { Component } from 'react'

class Loader extends Component {

    render() {
        return (
            <div className="mainLoader">
            </div>
        )
    }
}

export default Loader;