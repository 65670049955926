import React, { Component } from 'react';

class Footer extends Component {




    render() {
        return (
            <footer className="footer" id="footer">
              <div className="content">
              <a href="https://www.learnship.com/en/"><img class="footLogo"src="../../Learnship_Logo.svg" alt="" /></a>
    
    <span class="example-spacer"></span>
    <ul>
      <li className="first">
        <a target="_blank" href="https://www.learnship.com/en/about-learnship/">About</a>
      </li>
      <li>
        <a target="_blank" href="https://www.learnship.com/en/legal">Legal</a>
      </li>
      <li>
        <a target="_blank" href="https://www.learnship.com/en/privacy-policy">Privacy Policy</a>
      </li>
      <li>
        <a target="_blank" href="https://www.learnship.com/en/terms-conditions">Terms and Conditions</a>
      </li>
    </ul>
    <span class="example-spacer"></span>
    <span style={{fontsize : "12px"}}>© 2010 - 2021</span>
  
              </div>
            
    

          </footer>
            
        )
    }


}

export default Footer;