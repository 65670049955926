import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Popover, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Exercise from '../Exercise/Exercise';
import AllExercise from '../Exercise/AllExercise';
import EditContents from '../EditContents/EditContents';
import { loadRelatedContents, getMeaning, newsAddSession, pinContents, versionContents } from '../../requests';
import Loader from "../Loader/Loader";
import styles from './Modal.module.css';
import ReactDOM from "react-dom";
import dateFormat from "dateformat";


// var dateFormat = require('dateformat');
var preContent;
var datePublished = "";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedNews: [],
      previousRelatedNews: [],
      wordMeaning: [],
      selectedIndexToView: 0,
      selectedUUID: "",
      inModel: false,
      meaning: <p>Click on a word to see its meaning here.</p>,
      contentText: "",
      tagsString: "",
      relatedSearchQuery: "",
      from: 1,
      limit: 4,
      exerciseDisplay: false,
      allExerciseDisplay: false,
      isVideoCategory: false,
      displayRelatedNews: false,
      displayLoader: false,
      navigationCategory: "",
      showEditContentButton: false,
      showEditedContent: false,
      pinnedContentBtn: "Pin Content",
      contentToEdit: [],
      versionContent: [],

    }
    // Make it as an environment variable
    this.serverPath = process.env.LEVELWISE_API_URL || "/v1";
    this.handleMeaning = this.handleMeaning.bind(this);
    this.getRelatedContents = this.getRelatedContents.bind(this);
    this.openModelFromRelatedNews = this.openModelFromRelatedNews.bind(this);
    this.relatedRecommendedNews = this.relatedRecommendedNews.bind(this);
    this.renderRelatedContents = this.renderRelatedContents.bind(this);
    this.getMeaning = this.getMeaning.bind(this);
    this.renderTags = this.renderTags.bind(this);
    this.takeExercise = this.takeExercise.bind(this);
    this.takeAllExercise = this.takeAllExercise.bind(this);
    this.onClose = this.onClose.bind(this);
    this.quit = this.quit.bind(this);
    this.renderMeaning = this.renderMeaning.bind(this);
    this.fetchModal = this.fetchModal.bind(this);
    this.newsSession = this.newsSession.bind(this);
    this.pinContent = this.pinContent.bind(this);
    this.editContent = this.editContent.bind(this);
    this.editContentButtonDisplay = this.editContentButtonDisplay.bind(this);
    this.summaryPopUp = this.summaryPopUp.bind(this);
    this.closeSummaryButton = this.closeSummaryButton.bind(this);
    
  }


  async pinContent() {
    this.setState({ pinnedContentBtn: <p className="pinLoader"></p> })
    const pinnedContent = await pinContents({
      "level": this.props.level.replace("_*", ""),
      "date": datePublished,
      "uuid": this.props.selectedUUID,
      "content": "drafts",
      "versionId": "",
      "exerciseVersionId": ""
    });



    if (pinnedContent.code === "200") {
      var pinContent = document.querySelector("#pinContent");
      pinContent.style.color = "#42BABD";
      pinContent.style.fontWeight="600";
      pinContent.disabled = true;
      this.setState({ pinnedContentBtn: "Pinned" })
    };
  }

  async editContent() {


    if (document.getElementById("pinContent").innerHTML === "Pin Content") {
      await this.pinContent();
    }
    this.setState({ displayLoader: true });
    this.props.navigateEdit(this.props.selectedUUID, "", "");
    this.setState({ displayLoader: false });
    this.setState({ showEditContentButton: false });
  }

  summaryPopUp() {
    document.getElementById("tooltip").style.display = "block";
  }


  closeSummaryButton() {
    document.getElementById("tooltip").style.display = "none";
  }


  async relatedRecommendedNews() {
    const relatedContents = await loadRelatedContents({
      "level": this.props.level,
      "limit": this.state.limit,
      "from": this.state.from,
      "relatedSearchQuery": this.state.relatedSearchQuery,
    });
    var prevRelNews = this.state.relatedNews;
    this.setState({ displayRelatedNews: true, relatedNews: relatedContents, previousRelatedNews: prevRelNews }, () => {
      if (prevRelNews.length === 0) {
        this.setState({ previousRelatedNews: relatedContents });
      }
    });
  }

  openModelFromRelatedNews(UUID, content) {

    this.setState({ navigationCategory: content["Category"], inModel: true, selectedUUID: UUID, displayLoader: true, displayRelatedNews: false, from: 2, meaning: <p>Click on a word to see its meaning here.</p> }, () => {
      preContent = content
      this.newsSession(preContent["UUID"]);
      this.handleMeaning(preContent["Content"]);
      this.props.openModal(UUID);
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState({ displayLoader: false });
      }, 500)
      this.getRelatedContents(preContent);

    });

  }

  renderRelatedContents(e) {
    if (this.state.relatedNews) {
      const newsFeeds = this.state.relatedNews.map((content, index) => {
        const { UUID, Source, Title, Readtime, Media_Url, ImageUrl, PublishedDate, Category, Summary } = content;
        var date_ = new Date(PublishedDate);
        datePublished = date_;
        var publishedDate = dateFormat(date_, "dd mmmm,yyyy");

        return (
          <div className="col-sm-3 col-xs-6 thumbnail" id="thumbnailImg1" key={index}><Link to={"/" + this.props.loadUrl + "/" + Category + "/" + UUID} key={UUID} onClick={this.openModelFromRelatedNews.bind(null, UUID, content)}>
            <div className="thumbnailImage">
              {
                Media_Url != null && Media_Url.startsWith("http") ?
                  <div className="videoCategoryImage">
                    <img width="220px" height="150px" style={{ objectFit: "cover" }} src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="" />
                    <div className="playButtonInsideImage"><span className="fa fa-play"><span className="duration">1:10</span></span></div>
                  </div>
                  :
                  <div>
                    <img width="220px" height="150px" style={{ objectFit: "fill" }} src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt= "" />
                  </div>
              }
              <div className="thumbnailDetails relatedNewsColor">{publishedDate}</div>
              <div style={{ fontSize: "14px", fontWeight: "600", color: "#ffffff" }}>{Title}</div>
              {Source === "None" ?
                <div className="thumbnailDetails relatedNewsColor">{Readtime} </div> :
                <div className="thumbnailDetails relatedNewsColor">{Source} | {Readtime} </div>
              }
            </div>
          </Link>
          </div>
        )
      });
      return newsFeeds;
    }
  }


  async getMeaning(e) {
    let that = this;
    this.setState({
      meaning: <p>Loading...</p>
    });
    const meanings = await getMeaning({
      "word": e.target.innerHTML
    });

    if (meanings) {
      if (meanings.length > 0) {
        let data = meanings.map(function (item, index) {
          let def = item.definition.split(",_,").join("\n\n");
          let word = e.target.innerHTML + `(${item.partOfSpeech}): `;
          return <div key={index}>
            <p>
              <b>{word}</b><span className="def"></span><br /><span>{def}</span>
            </p>
            {
              item.synonyms ? <p><b>synonyms:</b> {item.synonyms.join(",")}</p> : ""
            }
            {
              item.antonyms ? <p><b>antonyms:</b> {item.antonyms.join(",")}</p> : ""
            }
            {
              item.examples ? <p><b>examples:</b> {item.examples.join(",")}</p> : ""
            }
          </div>
        });
        that.setState({
          meaning: data.splice(0, 2)
        });
      }
    }
    else {
      this.setState({
        meaning: <p>Currently, no meaning available for the selected word</p>
      });
    }

  }

  handleMeaning(textData) {
    var textDaata=textData.replace(/(<\/p>)/g,"\n").replace(/<[^>]*>/g,'');
    var text_ = textDaata.replace(/\n/g, " <br/><br/> ");
    var text = (text_.split(' '));
    for (var i = 0, len = text.length; i < len; i++) {
      text[i] = `<span class='word'  id=dict${[i]}  >${text[i]}</span>`;
    }
    let content = { __html: text.join(" ") };
    this.setState({ contentText: <div id="left"><div id="modalContent" className="newsContent"><p dangerouslySetInnerHTML={content} /></div></div> }, () => {
      setTimeout(() => { this.renderMeaning() }, 500);
    });

    return true;
  }

  renderMeaning() {
    const span = document.querySelectorAll('.word');
    span.forEach((element) => {
      element.addEventListener("click", this.getMeaning)
    });


  }

  renderTags(tagsString) {
    var tags = tagsString === "" ? [] : tagsString.split(",");
    const allTags = tags.map((tag, index) => {
      if (index < 5) {
        return (<button key={index} className="tagsButton tags" value={tag} onClick={() => this.props.searchContentsThroughButton(tag)}>{tag}</button>)
      }
    });
    return allTags
  }
  takeExercise() {
    this.setState({ exerciseDisplay: true });
  }
  takeAllExercise() {

    if (document.getElementById("exerciseModal") != null) {


      this.setState({ allExerciseDisplay: true },
        () => {
          document.getElementById("exerciseModal").style.display = "block";
        });

    }
    else {
      this.setState({ allExerciseDisplay: true });

    }
  }
  quit() {
    this.setState({ allExerciseDisplay: false });
  }
  onClose() {
    this.setState({ exerciseDisplay: false });
  }

  getRelatedContents(newContent) {
    var relatedSearchQuery;
    if (newContent["Tags"]) {
      relatedSearchQuery = newContent["Tags"]
    }
    else if (newContent["Keywords"]) {
      relatedSearchQuery = newContent["Keywords"]
    }
    else {
      relatedSearchQuery = newContent["Title"]
    }
    this.setState({ relatedSearchQuery: relatedSearchQuery, tagsString: newContent["Tags"] }, this.relatedRecommendedNews)
  }

  componentDidMount() {
    if (document.querySelector("#pinContent") != null) {
      var pinContent = document.querySelector("#pinContent");
      pinContent.style.color = "#424242";
      pinContent.disabled = false;
    }
    this.newsSession(preContent["UUID"]);
    this.fetchModal();
    window.onpopstate = (event) => {
      this.props.mainPage();
    }


    //let setDrpdwn = document.getElementById("editcontentbtn");
      let profileSel = document.getElementById("downarrowBtn");
      
    
      
      
    //   window.onclick = e => {       
    //   if(e.target != null && profileSel != null ){
    //     var name = ReactDOM.findDOMNode(this.refs.cpDev1).value;
    //       e.target === profileSel &&
    //       (setDrpdwn.style.display === "" || setDrpdwn.style.display === "none")
    //       ? (setDrpdwn.style.display = "block")
    //       : (setDrpdwn.style.display = "none");
    //    };
    // }
    

  }


  fetchModal() {
    var selectedIdx = this.props.selectedIndex;
    var tags = preContent["Tags"];
    this.setState({ displayRelatedNews: false, inModel: false, tagsString: tags, selectedIndexToView: selectedIdx }, () => {
      this.handleMeaning(preContent["Content"]);
      this.getRelatedContents(preContent);
    })
  }

  async newsSession(uuid) {
    const newsSession = await newsAddSession({
      newsId: uuid
    })
  }

  async editContentButtonDisplay() {
    let showStatus = !this.state.showEditContentButton
    this.setState({ showEditContentButton: showStatus });
  }

  render() {
    let navLink = "";
    if (this.state.navigationCategory === "") {
      var modelNavigationLink = this.props.modelNavigationLink;
      if (modelNavigationLink === "Search Results") {
        navLink = "search_results";
      }
      else {
        navLink = "main_page"
      }
    }
    else {
      modelNavigationLink = this.props.selectedCategory;
      navLink = "main_page";
    }
    let publishedDate;

    if (this.state.inModel === true) {
      if (this.state.previousRelatedNews) {
        var selectedUUID = this.state.selectedUUID;
        for (var i = 0; i < this.state.previousRelatedNews.length; i++) {
          if (selectedUUID === this.state.previousRelatedNews[i]["UUID"]) {
            preContent = this.state.previousRelatedNews[i];

          }
        }
        var Summary = preContent["Summary"];
        var date = new Date(preContent["PublishedDate"]);
        datePublished = preContent["ContentDate"];
        var uuid = preContent["UUID"];
        this.newsSession(uuid);
        publishedDate = dateFormat(date, "dd mmmm,yyyy");

      }
    }
    else {

      if (this.props.contents) {
        var selectedUUID = this.props.selectedUUID;
        for (var i = 0; i < this.props.contents.length; i++) {
          if (selectedUUID === this.props.contents[i]["UUID"]) {
            preContent = this.props.contents[i];
            

          }
        }


        if (preContent) {
          var Summary = preContent["Summary"];
          var date_ = preContent["PublishedDate"];
          var uuid = preContent["UUID"];
          publishedDate = dateFormat(date_, "dd mmmm,yyyy");
          datePublished = preContent["ContentDate"];
        }
        else {
          window.location.href = "/404notFound";
        }
      }
    }



    return (
      <div className="container" id="previewModal" role="document">
        {this.state.displayLoader ? <Loader /> :
          <div> {this.props.showNewsModal &&
            <div>
              <div className="previewModal">
                <div id="leftModal">
                  <div style={{ fontFamily: "Montserrat, sans-serif", padding: "15px 0", fontWeight: "500", cursor: "pointer", fontSize: "14px", width: "100%" }} onClick={navLink === "main_page" ? this.props.mainPage : this.props.searchPage}> <i style={{ paddingRight: "5px",color:"#E0291A",fontWeight:"600"}} className='fa fa-angle-left'></i>{modelNavigationLink.charAt(0).toUpperCase() + modelNavigationLink.slice(1)}</div>
                  <div className="modalTitle">{preContent["Title"]}</div>
                  {
                    preContent["Source"] === "None" ?
                      <div className="thumbnailDetails">{publishedDate} | {preContent["Readtime"]}</div> :
                      <div className="modalDate">{publishedDate} | {preContent["Readtime"]} | <a style={{ color: "#555555" }} rel="noopener noreferrer" href={preContent["Url"]} target="_blank" ><u>{preContent["Source"]}</u></a>  </div>
                  }


                  <div className="">
                    {this.props.editViewState && <div className={styles.summaryDiv}><button className="summaryButton1" onClick={this.summaryPopUp}>Read Summary</button></div>}
                    <div className="tooltiptextsSkill1" id="tooltip" style={{ top: "150px", marginLeft: "116px" }}><div className="summaryHeader" style={{ width: "538px" }}><b>Summary</b><img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right" }} onClick={this.closeSummaryButton} src="../../ic_close_48px.svg" alt="" /></div><div style={{ padding: "15px" }}>{Summary}</div></div>
                    {
                      preContent["Media_Url"] != null && preContent["Media_Url"].startsWith("http") ?
                        <div>
                          <video  style={{ borderRadius: "3px",width:"850px",height:"360px" }} controls>
                            <source src={preContent["Media_Url"] === "" ? "../../noImage.jpg" : preContent["Media_Url"]} type="video/mp4"></source>
                          </video>
                        </div>
                        :
                        <img style={{ borderRadius: "3px",width:"850px",height:"360px" }} src={preContent["ImageUrl"] === "" ? "../../noImage.jpg" : preContent["ImageUrl"]} alt= ""/>
                    }

                  </div>
                  <div className="modalContent" id="modalContent"> {this.state.contentText}</div>

                </div>
                <div>
                  <div id="rightModal">
                    <div className={`${styles.rightButtonsDiv}`}>
                      {this.props.editViewState && <div className={`${styles.pinContentCombDiv} btn-group`} role="group">
                       {
                         (preContent["Pinned"] === false) || (preContent["Pinned"] === null) ? 
                         <button onClick={this.pinContent} id="pinContent" className={`${styles.pinContentComb} btn`} style={{ width: "110px" }}   > {this.state.pinnedContentBtn }</button> :
                         <button onClick={this.pinContent} id="pinContent" className={`${styles.pinContentComb} btn`} style={{ width: "110px",pointerEvents:"none" }}   > <span className="pinnedButtonSpan">Pinned</span></button>
                       } 
                        <button className={`${styles.pinContentComb} btn `} id="downarrowBtn" onClick={this.editContentButtonDisplay}><i className='fa fa-angle-down' style={{fontWeight:"600"}}></i></button>
                      </div>}
                      <div>
                        {this.state.showEditContentButton ? <button className={`${styles.pinContentComb} ${styles.editContentButton} btn `} id="editcontentbtn" onClick={this.editContent} style={{ width: "150px", top: "28px",height:"42px",zIndex:"999",backgroundColor:"#F5F5F1" }} ref = "cpDev1">Edit content</button> : ""}
                      </div>
                      <div className={`${styles.exerciseButton}`}>
                        <button className="exerBtn1" onClick={this.takeAllExercise}>Exercises</button>
                      </div>
                    </div>
                    <div className="meaningDiv">
                      <div style={{ fontStyle: "12px", padding: "35px 0 20px 0",fontWeight:"500" }}><i className="fa fa-book"></i> &nbsp;Look up the dictionary</div>
                      <div className="dictMean">
                        {this.state.meaning}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="takeExcersise" >
                 <div style={{fontSize:"20px",color:"#333"}}><div >Hope you enjoyed the News.</div>
                  <div> How about some learning now?</div></div> 
                  <div style={{ padding: "15px 0", cursor: "pointer" }} onClick={this.takeAllExercise}> <button className="exerBtn" style={{padding:"6px 24px"}}>Exercises <i className="fa fa-arrow-right"></i></button></div></div>

                  <div className="RelatedNews">
                <span style={{ paddingRight: "10px" }}>Tagged:</span>
                {this.renderTags(this.state.tagsString)}
              </div>
              {/* </div> */}
              {/* } */}
              <div className={styles.relatedNewsDiv}  >
               
               
                {this.props.learnerViewState && <div style={{ overflow: "hidden", padding: "65px 0 40px", margin:"0 100px" }} >
                  {!this.state.displayRelatedNews ? <Loader /> :
                    <div>
                      <b style={{fontSize:"24px"}}>Related News </b>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        {this.renderRelatedContents()}
                      </div>
                    </div>
                  }
                </div>
                }
              </div>
              {this.state.exerciseDisplay &&
                <Exercise title="Content View" content={preContent["Content"]} UUIDD={preContent["UUID"]} onCloseButton={this.onClose} modalClass="mw-100" />
              }
              {this.state.allExerciseDisplay &&
                <AllExercise title="Content View" level={this.props.level} UUIDD={preContent["UUID"]} contentTitle={preContent["Title"]} contentCategory={preContent["Category"]} onCloseButton={this.quit} modalClass="mw-100" />
              }

            </div>
          }
          </div>
        }
        {
          <div>{ this.props.editLoader  && <div className="mainLoader" style={{width:"90%"}}> </div>
          }</div>
        }

   { this.props.showEditContentPage && <EditContents contentToEdit={preContent} navigatePreview1={this.props.navigatePreview} navigateContent1={this.props.navigateContent} closeEditPage1={this.props.closeEditPage} exerQues1={this.props.exerQues} enableLoader1={this.props.enableLoader} />
   }   
      </div>
    );
  }
}

export default Modal;
