import React, { Component, PropTypes } from 'react';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import RichTextEditor from 'react-rte';
import styles from './EditContents.module.css';
import { saveContent, pinContents, exerciseContentPublish, saveExercise, getQuestions } from '../../requests';
import Loader from "../Loader/Loader";
import { string } from 'prop-types';
import { bottom } from '@popperjs/core';
import { Editor, EditorState, RichUtils, ContentState, convertFromRaw, convertToRaw } from 'draft-js';


let titleEdit="";let tagsEdit = ""
let chinaEligible = "";
let jsonObj = [];
let deletejsonObj=[];
class EditContents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            edit: true,
            activeButton: "Content",
            showNews: true,
            showExer: false,
            value: RichTextEditor.createValueFromString(this.props.navigateContent1[0]["Content"], 'html'),
            valueSummary: RichTextEditor.createValueFromString(this.props.navigateContent1[0]["Summary"], 'html'),
            showListenExer: false,
            showVocabExer: true,
            level: "Lower Intermediate",
            category: this.props.navigateContent1[0]["Category"],
            title: this.props.navigateContent1[0]["Title"],
            showDiscard: false,
            showCategory: false,
            exerQues: [],
            displayLoader: false,
            tags: this.props.navigateContent1[0]["Tags"],
            isChinaEligible:this.props.navigateContent1[0]["IsRecommendedToChina"],
            showPublish:false
            
        };
        this.editContents = this.editContents.bind(this);
        this.editExercise = this.editExercise.bind(this);
        this.activeButton = this.activeButton.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChange1 = this.onChange1.bind(this);
        this.saveBtn = this.saveBtn.bind(this);
        this.previewBtn = this.previewBtn.bind(this);
        this.discardBtn = this.discardBtn.bind(this);
        this.discardClose = this.discardClose.bind(this);
        this.discardYes = this.discardYes.bind(this);
        this.showCategory = this.showCategory.bind(this);
        this.getExercise = this.getExercise.bind(this);
        this.responsejson=this.responsejson.bind(this);
        this.saveTag=this.saveTag.bind(this);
        this.saveTitle=this.saveTitle.bind(this);
        this.deleteQues=this.deleteQues.bind(this);
        this.undodeleteQues=this.undodeleteQues.bind(this);
        this.chinaBtn=this.chinaBtn.bind(this);
        this.publishBtn = this.publishBtn.bind(this);
        this.publishClose = this.publishClose.bind(this);
        this.publishYes = this.publishYes.bind(this);
    }

    onChange = (value) => {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(
                value.toString('html')
            );
        }
    };

    onChange1 = (valueSummary) => {
        this.setState({ valueSummary });
        if (this.props.onChange) {
            this.props.onChange(
                valueSummary.toString('html')
            );
        }
    };

    
    async editExercise(e) {
        
        this.setState({ showNews: false, showExer: true, activeButton: "Exercises",showVocabExer:true }, this.activeButton);
        this.getExercise("vocabulary");
    }
    async editContents() {
        this.setState({ showNews: true, showExer: false, activeButton: "Content", showVocabExer:false,showListenExer:false }, this.activeButton)
    }

    chinaBtn(){
        chinaEligible = document.getElementById("checkboxChina").checked;
    }

    async getExercise(category) {
       
        this.setState({ displayLoader: true });
        const getQuest = await exerciseContentPublish({
            uuid: this.props.navigateContent1[0]["UUID"],
            category: category,
            versionId:""

        });
        this.setState({ exerQues: getQuest["exercisePublish"] },
            () => this.setState({ displayLoader: false }));




    }


    async deleteQues(id){
        document.getElementById("editParentDiv"+id).className += " deleteDiv";  
        document.getElementById("deleteQues"+id).style.display="block";
        let question_uid=id;
        let questionDelete = {}
        questionDelete["isDeleted"]= true;
        questionDelete ["question_uid"] = question_uid; 
        deletejsonObj.push(questionDelete);
        jsonObj = jsonObj.concat(deletejsonObj);  
     }


     async undodeleteQues(id){
        document.getElementById("editParentDiv"+id).classList.remove("deleteDiv");
        document.getElementById("deleteQues"+id).style.display="none";  
        
        const index = deletejsonObj.findIndex(x => x.question_uid === id);
        
        if (index !== undefined) deletejsonObj.splice(index, 1);
        
     }



    

    async responsejson(q_id,q_type){
      
       
        let optionsLen= document.getElementsByClassName("options"+q_id).length;
        let tempOptions=document.getElementsByClassName("options"+q_id);
        let options=[];
        let answer = ""
        if(q_type === "mcq"){
            for(var i=0;i<optionsLen;i++){
                options.push(tempOptions[i].innerHTML);
                if(document.getElementById(q_id+i).checked === true){
                    answer = tempOptions[i].innerHTML;
                }
            
    
                }
        }
        else{
            
            answer = document.getElementById("gapfiller"+q_id).placeholder;
        }
        

               var question_uid =q_id;
               var question_type=q_type;
               var sentence =(document.getElementById('sentence'+q_id) !== null) ?(document.getElementById('sentence'+q_id).value !== undefined) ? document.getElementById('sentence'+q_id).value:"" : "";
               var question = (document.getElementById('question'+q_id) !== null) ?(document.getElementById('question'+q_id).value !== undefined ) ? document.getElementById('question'+q_id).value:((document.getElementById('question'+q_id).innerHTML.replace( /(<([^>]+)>)/ig, '') !== undefined)?document.getElementById('question'+q_id).innerHTML.replace( /(<([^>]+)>)/ig, ''):"") :"";
               var answer_explanation = (document.getElementById('ans_exp'+q_id) !== null) ? (document.getElementById('ans_exp'+q_id).value!== undefined) ? document.getElementById('ans_exp'+q_id).value.replace(/-/g, ','):(document.getElementById('ans_exp'+q_id).innerHTML.replace( /(<([^>]+)>)/ig, '') !== undefined ?document.getElementById('ans_exp'+q_id).innerHTML.replace(/-/g, ','):"") :"" ;

             let item = {}
             item["id"]= 1;
             item ["question_uid"] = question_uid;
             item ["question_type"] = question_type;
             item ["sentence"] =sentence;
             item ["question"]=question;
             item ["answer"]=answer;
             item ["answer_explanation"]= answer_explanation.split(",");
             item ["options"]=options
             item ["audio_location"]=""
             jsonObj.push(item);          
        
    }

    showCategory() {

        this.setState({ showCategory: true })
    }


    handleChange(e) {
        let exercise = e.target.value;
        this.setState({ gender: e.target.value });
        if (e.target.value === ("vocabulary") || e.target.value === ("grammer")) {
            this.setState({ showListenExer: false, showVocabExer: true })
        } else {
            this.setState({ showListenExer: true, showVocabExer: false })
        }
        this.getExercise(exercise);
    }

    handleChangeLevel(e) {

        this.setState({ level: e.target.value });
    }

    handleChangeCategory(e) {


        this.setState({ category: e.target.value });
    }


    async activeButton() {
        var activeButton = this.state.activeButton;
        var pinned = document.getElementsByClassName("editButton");

        for (let i = 0; i < pinned.length; i++) {

            if (pinned[i].id === activeButton) {
                pinned[i].style.color = "#E0291A";
                pinned[i].style.borderBottom = "3px solid";
                pinned[i].style.fontWeight="600";
            }
            else {
                pinned[i].style.color = "#939495";
                pinned[i].style.borderBottom = "none";
            }
        }
    }


    async saveBtn() {
      chinaEligible = chinaEligible!=""?chinaEligible:this.state.isChinaEligible == "true";        
      jsonObj = jsonObj.concat(deletejsonObj);      

        function hash(o){
            return o.question_uid;
        }    
        
        var hashesFound = {};
        
        jsonObj.forEach(function(o){
            hashesFound[hash(o)] = o;
        })
        
        var results = Object.keys(hashesFound).map(function(k){
            return hashesFound[k];
        });      


       let title = titleEdit !== ""?titleEdit:this.state.title;
       let tags = tagsEdit !== ""?tagsEdit:this.state.tags;
       
       
        let level = (this.state.level === "Lower Intermediate" ? 1 : (this.state.level === "Higher Intermediate" ? 2 : 3))
        let uuid = this.props.navigateContent1[0]["UUID"]
       
      this.props.enableLoader1();
           const saveExercises1 = await saveExercise ({
            exercises:results,
            uuid:uuid
        });

       
        const saveContentQuery = await saveContent({
            level: level,
            uuid: this.props.navigateContent1[0]["UUID"],
            category: this.state.category,
            title: title,
            content: this.state.value.toString("html"),
            summary: this.state.valueSummary.toString("html"),
            tags: tags,
            isRecommendedToChina:chinaEligible
        });
       
        if ( saveContentQuery && saveExercises1 ) {
            this.props.navigatePreview1(uuid, "Drafts");
       
    }
        
        
}


    async saveTitle(){
        
        titleEdit = document.getElementById("title")!== null?document.getElementById("title").value:"";
        
       
    }

    async saveTag(){
        tagsEdit = document.getElementById("tags")!== null?document.getElementById("tags").value:"";
    }

    async previewBtn() {
        this.props.enableLoader1();
        let level = (this.state.level === "Lower Intermediate" ? 1 : (this.state.level === "Higher Intermediate" ? 2 : 3))
        let uuid = this.props.navigateContent1[0]["UUID"];
        let level1 = (this.state.level === "Lower Intermediate" ? "levelone" : (this.state.level === "Higher Intermediate" ? "leveltwo" : "levelthree"));
        let versionId = this.props.navigateContent1[0]["VersionId"];
        let exVersion = this.props.exerQues1["versionId"]
        let title = titleEdit !== ""?titleEdit:this.state.title;
        let tags = tagsEdit !== ""?tagsEdit:this.state.tags;
        chinaEligible = chinaEligible!=""?chinaEligible:this.state.isChinaEligible == "true";   

        jsonObj = jsonObj.concat(deletejsonObj);   
        function hash(o){
            return o.question_uid;
        }    
        
        var hashesFound = {};
        
        jsonObj.forEach(function(o){
            hashesFound[hash(o)] = o;
        })
        
        var results = Object.keys(hashesFound).map(function(k){
            return hashesFound[k];
        });

         const saveContentQuery = await saveContent({
            level: level,
            uuid: this.props.navigateContent1[0]["UUID"],
            category: this.state.category,
            title: title,
            content: this.state.value.toString("html"),
            summary: this.state.valueSummary.toString("html"),
            tags:tags,
            isRecommendedToChina:chinaEligible
        });


        const saveExercises = await saveExercise ({
            exercises:results,
            uuid:this.props.navigateContent1[0]["UUID"]
        });
       

       
       const pinnedContent = await pinContents({
                "level": level1,
                "date": "",
                "uuid": uuid,
                "content": "publish",
                "versionid": versionId,
                "exerciseVersionId":exVersion
            });


            if (pinnedContent.code === "200") {

                this.props.navigatePreview1(uuid, "Published",exVersion);
            }



        
    }



    async componentDidMount() {
        this.activeButton();

    }


    async discardBtn() {
        window.scrollTo(0, 0);
        document.getElementById("editContentss").className += " deleteDiv";
        this.setState({ showDiscard: true });
    }

    async discardClose() {
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showDiscard: false });
    }

    async discardYes() {

        this.props.closeEditPage1();
        document.getElementById("editContentss").classList.remove("deleteDiv");
    }

    async publishBtn() {
        window.scrollTo(0, 0);
        document.getElementById("editContentss").className += " deleteDiv";
        this.setState({ showPublish: true });
    }

    async publishClose() {
        document.getElementById("editContentss").classList.remove("deleteDiv");
        this.setState({ showPublish: false });
    }

    async publishYes() {
        this.previewBtn();
        document.getElementById("editContentss").classList.remove("deleteDiv");
    }


    render() {

        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

       let exerQuestions = this.state.exerQues !== 0? this.state.exerQues:this.props.exerQues1["exercisePublish"];
        return (
            
           <div> <div className="editContentss" id="editContentss">
                <p className={styles.editContentsLabel} onClick={() => this.props.closeEditPage1()}>
                    <i className='fa fa-angle-left'></i> Drafts
            </p>

                <div className={styles.editID}>Editing: <span style={{paddingLeft:"5px"}}>{this.props.navigateContent1[0]["PreviewVersion"]}</span></div>

                <div class={styles.toggleContentBtn}>
                    <button id="Content" onClick={this.editContents} className={`${styles.editContents} editButton`} >Content</button>
                    <button id="Exercises" onClick={this.editExercise} className={`${styles.editContents} editButton`} style={{ marginLeft: "15px" }}>Exercises</button>
                </div>
                {this.state.showNews && <div>
                    <div className="aEdit"> <div><p>Level</p> <select value={this.state.level} onChange={this.handleChangeLevel}>
                        <option name="lower"> Lower Intermediate</option>
                        <option name="higher">Higher Intermediate</option>
                        <option name="advanced">Advanced</option>
                    </select></div>
                        {/* <div><p style={{ width: "55px", left: "35px" }}>Category</p> <select value={this.state.category} onChange={this.handleChangeCategory} style={{ marginLeft: "25px" }}>
                            <option name="business"> Business</option>
                            <option name="technology">Technology</option>
                            <option name="science"> Science</option>
                            <option name="sports">Sports</option>
                            <option name="health"> Health</option>
                        </select>
                        </div> */}

                        <div>  <p style={{ width: "55px", left: "35px" }}>Category</p>
                            <input list="categories" name="myBrowser" value={this.state.category} onChange={this.handleChangeCategory} onClick={this.showCategory} style={{ marginLeft: "25px" }} />
                            <datalist id="categories">
                                <option value="Business" />
                                <option value="Technology" />
                                <option value="Science" />
                                <option value="Sports" />
                                <option value="Health" />

                            </datalist></div>

                        {/* <div>
                            <p style={{ width: "55px", left: "35px" }}>Category</p>
                            <input type="text" name="myText" value={this.state.category} onChange={this.handleChangeCategory} style={{ marginLeft: "25px" }}
       selectBoxOptions="Canada;Denmark;Finland;Germany;Mexico" /> 
                            </div> */}

                        <div>
                            <label class="switch">
                            {this.props.navigateContent1[0]["IsRecommendedToChina"] === "true" ? <input type="checkbox" id="checkboxChina"  defaultChecked onChange={this.chinaBtn}/>:<input type="checkbox" id="checkboxChina" onChange={this.chinaBtn}/>}
                                <span class="slider round"></span>
                            </label>
                            <span className="contentChina">Content eligible for China</span>
                        </div>
                    </div>

                    <div className="bEdit" > <p>Title</p> <EditText
                        id="title"
                        class="editTitle"
                        name="textbox1"
                        defaultValue={this.state.title}
                        onSave={() => this.saveTitle()}
                    />
                    </div>
                    <div class="dEdit"><p className={styles.contentp}>Content</p> <RichTextEditor
                        value={this.state.value}
                        onChange={this.onChange}
                        toolbarConfig={toolbarConfig}

                    />
                    </div >


                    <div class="dEdit"><p className={styles.contentp}>Summary</p> <RichTextEditor
                        value={this.state.valueSummary}
                        onChange={this.onChange1}
                        toolbarConfig={toolbarConfig}
                    />
                    </div>

                    <div className="bEdit"> <p>Tags</p> <div><EditText
                        id="tags"
                        class="editTitle"
                        name="textbox1"
                        defaultValue={this.state.tags}
                        onSave={() => this.saveTag()}
                    /></div></div>


                </div>}
                {this.state.showExer && <div> <div className="aEdit" > <div><p style={{ width: "55px" }}>Category</p> <select value={this.state.gender} onChange={this.handleChange}>
                    <option name="vocabulary" value="vocabulary" > Vocabulary</option>
                    <option name="grammar" value="grammer" >Grammar</option>
                    {/* <option name="listen" value="communication" >Listening and Speaking</option> */}
                </select></div> </div>
                    {this.state.displayLoader ? <Loader /> : <div>  {this.state.showVocabExer && <div>   {exerQuestions.map((index, key) => <div><div className={styles.editParentDiv} id={"editParentDiv"+index.question_uid}> 
                        <div className={styles.editDiv}> <div className={styles.editQues}>Question {key + 1}/{exerQuestions.length}</div><div style={{ float: "right" }}  onClick={() => this.deleteQues(index.question_uid)}><i class="fa fa-trash"></i></div>
                        </div>
                        <div className="editQuestion" >
                            <EditTextarea
                                id={"sentence"+index.question_uid}
                                defaultValue={index.sentence}
                                onSave={() => this.responsejson(index.question_uid,index.question_type)}
                                
                            />

                        </div>

                        {index.question_type === "mcq" ? <div><div className="editQuestion">
                            <EditTextarea
                                id={"question"+index.question_uid}
                                defaultValue={index.question}
                                onSave={() => this.responsejson(index.question_uid,index.question_type)}

                            />
                        </div>

                            {index.options.length !== 0 ? <div style={{ paddingBottom: "20px" }}> <div className={styles.options}><b>Options (select Answer)</b></div>
                                <div style={{ overflow: "hidden", padding: "0 15px" }}>
                                    <form>
                                        {index.options.map((index1, key) => <div>{index1 === index.answer ? <div class="optionsEdit"><input type="radio" id={index.question_uid+key} name="gender" value="male" className="inputOption" onClick={() => this.responsejson(index.question_uid,index.question_type)} defaultChecked />
                                            <EditText
                                                name="textbox1"
                                                id={"options"+index1}
                                                className={"options"+index.question_uid}
                                                defaultValue={index1}
                                                onSave={() => this.responsejson(index.question_uid,index.question_type)}

                                            /></div> : <div class="optionsEdit"><input type="radio" id={index.question_uid+key} name="gender" value="male" className="inputOption" onClick={() => this.responsejson(index.question_uid,index.question_type)} />
                                                <EditText
                                                    name="textbox1"
                                                    id={"options"+index1}
                                                    className={"options"+index.question_uid}
                                                    defaultValue={index1}
                                                    onSave={() => this.responsejson(index.question_uid,index.question_type)}
                                                /></div>}</div>)}
                                    </form>
                                </div></div> : <div><div className={styles.options}><b>Answer</b></div>
                                    <EditText className={styles.optionNum} defaultValue={index.answer} /></div>


                                    


                            
                            }

                        </div> : ""}


                        {index.question_type === "gap_filler" ? <div  className={styles.optionNum}> <div onClick={() => this.responsejson(index.question_uid,index.question_type)} style={{fontSize:"14px",lineHeight:"2",backgroundColor:"#F5F5F1"}} id={"question"+index.question_uid}  dangerouslySetInnerHTML={{ __html: index.question.replace(/\_+/g, '<input type="text" name="itemConsumption" placeholder=' + index.answer + ' id="gapfiller'+index.question_uid +'"  />') }} /></div> : ""}


                        {index.answer_explanation.length !== 0 ? <div className="bEdit" style={{ padding: "0 15px 15px 15px" }}> <p style={{ width: "97px" }}>Explanation/Hint</p> <EditText
                            style={{ width: "928px" }}
                            id={"ans_exp" + index.question_uid}
                            class="editTitle"
                            name="textbox1"
                            defaultValue={index.answer_explanation.join("-")}
                            onSave={() => this.responsejson(index.question_uid,index.question_type)}
                        />
                        </div> : ""}
                    </div>
                  <div id={"deleteQues"+index.question_uid} style={{bottom:"167px",position:"relative",left:"200px",display:"none"}}>
                        <div style={{fontWeight:"500"}}>This question is deleted and will not be available in higher versions.</div>
                        <div onClick={() => this.undodeleteQues(index.question_uid)} style={{padding:"10px 196px",color:"#F13728",fontSize:"14px",fontWeight:"500",cursor:"pointer"}}> <i class="fa fa-undo" aria-hidden="true"></i> Undo delete</div>
                        </div>
                        </div>



                    )}
                    </div>}</div>}

                </div>}

                {this.state.displayLoader ? <Loader /> : <div>   {
                    this.state.showListenExer && <div>{exerQuestions.map((index, key) => <div> <div className={styles.editParentDiv}> <div className={styles.editDiv}>
                        <div className={styles.editQues}>Question {key + 1}/{exerQuestions.length}</div><div style={{ float: "right" }}  onClick={() => this.responsejson()}><i class="fa fa-trash"></i></div>
                    </div>

                        <div style={{ overflow: "hidden" }}> <div className="bEdit" style={{ padding: "0 15px 15px 15px", float: "left" }}> {index.question_type === "text_to_audio" ? <p style={{ width: "170px" }}>Word or sentence to practice</p> : <p style={{ width: "170px" }}>Text for audio</p>} <EditText
                            style={{ width: "800px" }}
                            class="editTitle"
                            name="textbox1"
                            defaultValue={index.answer}
                        />
                        </div>
                            {index.question_type === "text_to_audio" ? <div ><span className={styles.exerciseSpeaker}><img className={`${styles.speakerSvg}`} alt="listen" src="../../speaker.svg" /></span></div> : ""}
                        </div>
                        {index.question_type === "audio_to_text" ? <span className={styles.exerciseSpeaker1}><img className={`${styles.speakerSvg1}`} alt="listen" src="../../speaker.svg" /> Listen</span> : ""}
                        <div className="editQuestion" style={{ padding: "15px" }}>
                            <EditTextarea
                                defaultValue={index.question}
                            />
                        </div>
                    </div>



                    </div>
                    )} </div>
                } </div>}


                <div className={styles.contentFooter}>
                    <div className={styles.leftbtn}><button className={styles.leftbtn1} style={{ float: "left", width: "152px" }} onClick={this.discardBtn}>Discard Changes</button></div>
                    <div className={styles.rightbtn}><button className={styles.leftbtn1} style={{ width: "86px" }} onClick={this.publishBtn} >Publish</button><button className={styles.savebtn} onClick={this.saveBtn}>Save as New Version</button></div>
                </div>

            </div>

                {this.state.showDiscard && <div class={styles.discardChanges}>
                    <div class={styles.discardHeader}><b>Confirmation</b> <img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right",cursor:"pointer"}} src="../../ic_close_48px.svg" alt="" onClick={this.discardClose} /></div>
                    <div class={styles.discardBody}>Your current edits will be discarded. Continue?</div>
                    <div class={styles.discardFooter}><div className={styles.rightbtn} style={{padding: "15px 3px 0 0"}}><button className={styles.discardbtn1} onClick={this.discardClose} style={{paddingRight:"15px"}}>Cancel</button><button className={styles.discardbtn}  onClick={this.discardYes}>Yes, discard</button></div></div>
                </div>}
                {this.state.showPublish && <div class={styles.discardChanges}>
                    <div class={styles.discardHeader}><b>Publish</b> <img style={{ marginBottom: "-5px", fontSize: "23px", width: "fit-content", float: "right",cursor:"pointer"}} src="../../ic_close_48px.svg" alt="" onClick={this.publishClose} /></div>
                    <div class={styles.discardBody}>Are you sure you want to publish the selected version?</div>
                    <div class={styles.discardFooter}><div className={styles.rightbtn} style={{padding: "15px 3px 0 0"}}><button className={styles.discardbtn1} onClick={this.publishClose} style={{paddingRight:"15px"}}>Cancel</button><button className={styles.discardbtn} onClick={this.publishYes}>Yes, publish</button></div></div>
                </div>}           </div>
        )
    }
}

export default EditContents;