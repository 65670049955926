import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Footer from '../Footer/Footer';
import { Header } from '../Header/Header';
import Loader from "../Loader/Loader";
import { validateTrialUserData, getUser } from '../../requests';
import ConfigContext, { config } from '../../utils/config-context'
import axios from 'axios';

class TrialSession extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shortcode: props.match.params.shortcode,
            showNavLinks: false
        };

    }


    componentDidMount() {
        let config = this.context;

        
        axios.get(
            "/authenticate/"+this.state.shortcode
            ).then(function(response){

            if (response.data) {

                
                let user = {
                    userid: "2399323",
                    authstatus: true, 
                    level: "levelone",
                    "token": response.data.token

                }
                
                
                localStorage.setItem("user", JSON.stringify(user));
                

                if(global.window) {
                    global.window.location.href = (document.referrer.match(/^(http|https):\/\/(\w?)+(newsfeed\.(.*))/g))?document.referrer:'/'
                }
                 return true;
            }

            localStorage.removeItem("user");
            global.window.location.href = "/logout";
            return false;

        })

       
        

    }
    render() {
        return (
        <div>
            <ConfigContext.Provider value={config}>
                <Header showNavLinks={this.state.showNavLinks}/>
            </ConfigContext.Provider>
            <div className="errorPage">
                
                <div className="loaderTrial"><Loader/></div>
                
            </div>
            
      <Footer />
    </div>
        )
      
    }
    
    
    }
    
export default TrialSession;