import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Route, Switch, Link,Redirect, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "./js/store/index"
import NewsFeed from './components/NewsFeed/NewsFeed';
import Error from './components/Error';
import AccessDenied from './components/AccessDenied';
import Login from './components/Login/Login';
import PageNotFound from './components/PageNotFound';
import TrialSession from './components/TrialSession/TrialSession';
import TrialActivation from './components/TrialSession/TrialActivation';
import ConfigContext from './utils/config-context'
import Preferences from './components/Preferences/Preferences'
import NotAllowed from './components/NotAllowed'
import china_timezones from './utils/timezone'
import Healthcheck from './components/Healthcheck/Healthcheck'



async function isLoggedIn() {
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"))
    if ("userid" in user && parseInt(user.userid) !== 0 && user.authstatus === true) {
      
      if (typeof window.jstz !== 'undefined') {
        
        let current_timezone = window.jstz.determine().name();
        
        let timezones = china_timezones.filter(function(timezone){
          return current_timezone.includes(timezone)
        })
        
        if (timezones.length > 0) {
          window.location.href = "/notallowed";
        }
      }

      if (user.country=="CN") {
        window.location.href = "/notallowed";
      }
      return true;
    }
  }

  window.location.href = "/login"
  return false;
}


const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => isLoggedIn() ? (

    <Component {...props} />
  ) : <Redirect to="/login" />
  } />
)

const routing = (
  <Provider store={store} >
    <Router>
      <div>
        <Switch>
          <Route path="/login" component={Login} />
          <Route exact path='/logout' component={() => { 
            let config = useContext(ConfigContext);
            localStorage.removeItem("user")
            window.location.href = config.loginUrl+'/logout?redirectUrl='+window.location.protocol+"//"+window.location.hostname; 
            return null;
            }}/>
          <ProtectedRoute exact path="/" component={() => {   
            window.location.href = '/learner/topstories';         
          }}/>
           <ProtectedRoute exact path="/learner" component={() => {   
            window.location.href = '/learner/topstories';         
          }}/>
          <Route exact path="/error" component={Error} />
          <Route exact path="/trial" component={TrialSession} />
          <Route exact path="/trialactivate/:shortcode" component={TrialActivation} />
          <Route exact path="/401accessdenied" component={AccessDenied} />
          <Route exact path="/404notFound" component={PageNotFound} />
          <Route exact path="/notallowed" component={NotAllowed} />
          <Route path="/preferences" component={Preferences}/>
          <ProtectedRoute path="/:view/:category" component={App} />
          <ProtectedRoute path="/:view/:category/:uuid" component={NewsFeed} />
          <Route path="/Healthcheck" component={Healthcheck}/>
        </Switch>

        
      
      </div>
      
    </Router>
  </Provider>
)


ReactDOM.render(
  routing,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
