import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer/Footer';
import {Header} from './Header/Header';
import ConfigContext, {config} from '../utils/config-context'
class Error extends Component {
  componentDidMount() {
    let dropdown = document.querySelector(".dropdown-menu");
    let setDrpdwn = document.querySelector(".settingMenu_dropdown");
    let mySol = document.querySelector(".mySolutions");
    let arrowIcon = document.querySelector(".solutionsIcon");
    let profileSel = document.querySelector(".profileSelect");
    window.onclick = e => {
      (e.target === mySol || e.target === arrowIcon) &&
        (dropdown.style.display === "" || dropdown.style.display === "none")
        ? (dropdown.style.display = "block")
        : (dropdown.style.display = "none");
      e.target === profileSel &&
        (setDrpdwn.style.display === "" || setDrpdwn.style.display === "none")
        ? (setDrpdwn.style.display = "block")
        : (setDrpdwn.style.display = "none");
    };
  }
  render() {

    return (
      <div>
        {/* header starts here */}
        <ConfigContext.Provider value={config}>
          { <Header from="App"/>
          }  
          </ConfigContext.Provider>
          {/* header ends here */}
        <div className="errorPage">
          
          <div className="errorMessageDiv">
            <div className="errMsgtitle"> <i className="fa fa-exclamation-triangle"></i> Oh no!</div>
            <div className="errorMsg">Sorry, this content is unavailable in your location. Please contact your<br/> administrator.</div>
          </div>
        
        </div>
        <Footer />
      </div>
    )
  }


}

export default Error;