import React, { Component } from 'react';
import Footer from '../Footer/Footer';
import { Header } from '../Header/Header';
import { getTrialUserData, getUser } from '../../requests';
import ConfigContext, { config } from '../../utils/config-context'
import { ThemeProvider } from '@mui/system';
import { TextField, Button, createTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import * as Yup from 'yup';
import { ErrorMessage, Formik, Form } from "formik";
import Loader from "../Loader/Loader";


const theme2 = createTheme({
    palette: {
        // primary: blue,
        primary: {
            main: "#42BABD"
        },
    }
});




const initialValues = {
    emailId: "",
    organisation: "",

}


const yupValidationSchema = Yup.object().shape({
    emailId: Yup.string()
        .email("Enter a valid email address")
        .required(),
})




class TrialSession extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLabel: false,
            trialUser: "",
            displayLoader:false,
            showForm: false,
            errorMsg: ""
        };
        this.submit = this.submit.bind(this);
        this.clickURL=this.clickURL.bind(this);

    }

    componentDidMount() {

        let dropdown = document.querySelector(".dropdown-menu");
        let setDrpdwn = document.querySelector(".settingMenu_dropdown");
        let mySol = document.querySelector(".mySolutions");
        let arrowIcon = document.querySelector(".solutionsIcon");
        let profileSel = document.querySelector(".profileSelect");
        window.onclick = e => {
            (e.target === mySol || e.target === arrowIcon) &&
            (dropdown.style.display === "" || dropdown.style.display === "none")
        ? (dropdown.style.display = "block")
        : (dropdown.style.display = "none");
      e.target === profileSel &&
        (setDrpdwn.style.display === "" || setDrpdwn.style.display === "none")
        ? (setDrpdwn.style.display = "block")
        : (setDrpdwn.style.display = "none");
    };
        let config = this.context;
        let that = this;
            getUser().then(function(user){
                if (!user){
                    return false;
                }
                
                if (parseInt(user.userid) !== 0 && user.trialaccess===false) {
                     
                    if(global.window) {
                       global.window.location.href = (document.referrer.match(/^(http|https):\/\/(\w?)+(newsfeed\.(.*))/g))?document.referrer:'/'
                     }
                    return true;
                }

                that.setState({
                    showForm: true
                  });
                return false;
            });
        
    }


    async submit(values) {
        this.setState({displayLoader:true, errorMsg: ""});
        for (var key in values) {
            var value = values[key];
        }
        const response = await getTrialUserData({
            "emailId": values["emailId"],
            "accountName": values["organisation"],
            "country": value.label
        }, false, false );

        if (response.errors) {
            
         this.setState({  errorMsg: response.errors[0].message, displayLoader:false});

        } else {
           
            let trialUserLink = response.trialUser.shortCode;      
            this.setState({ showLabel: true, trialUser: trialUserLink, displayLoader:false});
        }

       
    }


    clickURL(){
       
            var copyText = document.getElementById("sessionurl");
            copyText.select();
            copyText.setSelectionRange(0, 99999)
            document.execCommand("copy");
          
    }





    render() {
        return (this.state.showForm)?(
            <div>
                <ConfigContext.Provider value={config}>
                    <Header />
                </ConfigContext.Provider>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        this.submit(values)
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                    }}
                    validationSchema={yupValidationSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,
                        dirty,
                        /* and other goodies */
                    }) => (
                            <Form>
                                < div className="trialSession" >
                                    <div className="trialSessionDiv" style={{ left: "32%" }}> <div style={{ fontSize: "20px", fontWeight: "500" }}>Welcome ! <sup style={{ color: "#e67320", fontSize: "10px" }}>Trial Session</sup></div>
                                        <div className="emailId">
                                            <ThemeProvider theme={theme2}>
                                                <TextField
                                                    required
                                                    id="emailid"
                                                    type="email"
                                                    label="Email address"
                                                    variant="outlined"
                                                    name="emailId"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                // onChange={handleChange}
                                                // onBlur={handleBlur}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <ErrorMessage
                                            name="emailId"
                                            component="span"
                                            className="error-message"
                                        />
                                        <div className="organisation">
                                            <ThemeProvider theme={theme2}>
                                                <TextField
                                                    id="organisation"
                                                    type="text"
                                                    label="Organisation"
                                                    variant="outlined"
                                                    name="organisation"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </ThemeProvider>
                                        </div>
                                       


                                        <div className="countryDropdown">
                                            <ThemeProvider theme={theme2}>
                                                <Autocomplete
                                                    id="country"
                                                    name="country"
                                                    options={countries}
                                                    getOptionLabel={(option) => option.label}
                                                    style={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} label="Select country" variant="outlined" />}
                                                    onChange={setFieldValue}
                                                    onBlur={handleBlur}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <Button variant="contained" type="submit" className="saveBtn" id="submit" disabled={!(isValid && dirty)} >Submit</Button>
                                    </div>
                                  
                                    
                                  
                                  {this.state.displayLoader?
                                  <div className="loaderTrial"><Loader/></div>:<div>
                                
                                { 
                                  
                                  this.state.errorMsg == "" ? <div>  {this.state.showLabel && <div className="link">
                                        <span style={{fontSize:"13px",paddingBottom:"13px"}}>copy and share the below link to access NewsFeed:</span><br />
                                        
                                        <input className="trialURL" type="text" value={global.window.location.hostname + "/trialactivate/" + this.state.trialUser} id="sessionurl"/><i class="fa fa-copy" onClick={this.clickURL}></i>
                                    </div>
                                    
                                }</div> : <div className="link">{this.state.errorMsg}</div>}</div>
                                    
                                    }
                                    
                                </div>
                            </Form>
                        )}
                </Formik>
                <Footer />
            </div >
        ):(
            <div>
                <ConfigContext.Provider value={config}>
                    <Header showNavLinks={this.state.showNavLinks}/>
                </ConfigContext.Provider>
                <div className="errorPage">
                    
                    <div className="loaderTrial"><Loader/></div>
                    
                </div>
                
          <Footer />
        </div>
            )
    }


}

const countries = [
    { label: 'Andorra' },
    { label: 'United Arab Emirates' },
    { label: 'Afghanistan' },
    { label: 'Antigua and Barbuda' },
    { label: 'Anguilla' },
    { label: 'Albania' },
    { label: 'Armenia' },
    { label: 'Angola' },
    { label: 'Antarctica' },
    { label: 'Argentina' },
    { label: 'American Samoa' },
    { label: 'Austria' },
    { label: 'Australia' },
    { label: 'Aruba' },
    { label: 'Alland Islands' },
    { label: 'Azerbaijan' },
    { label: 'Bosnia and Herzegovina' },
    { label: 'Barbados' },
    { label: 'Bangladesh' },
    { label: 'Belgium' },
    { label: 'Burkina Faso' },
    { label: 'Bulgaria' },
    { label: 'Bahrain' },
    { label: 'Burundi' },
    { label: 'Benin' },
    { label: 'Saint Barthelemy' },
    { label: 'Bermuda' },
    { label: 'Brunei Darussalam' },
    { label: 'Bolivia' },
    { label: 'Brazil' },
    { label: 'Bahamas' },
    { label: 'Bhutan' },
    { label: 'Bouvet Island' },
    { label: 'Botswana' },
    { label: 'Belarus' },
    { label: 'Belize' },
    { label: 'Canada' },
    { label: 'Cocos (Keeling) Islands' },
    { label: 'Congo, Democratic Republic of the' },
    { label: 'Central African Republic' },
    { label: 'Congo, Republic of the' },
    { label: 'Switzerland' },
    { label: "Cote d'Ivoire" },
    { label: 'Cook Islands' },
    { label: 'Chile' },
    { label: 'Cameroon' },
    { label: 'China' },
    { label: 'Colombia' },
    { label: 'Costa Rica' },
    { label: 'Cuba' },
    { label: 'Cape Verde' },
    { label: 'Curacao' },
    { label: 'Christmas Island' },
    { label: 'Cyprus' },
    { label: 'Czech Republic' },
    { label: 'Germany' },
    { label: 'Djibouti' },
    { label: 'Denmark' },
    { label: 'Dominica' },
    { label: 'Dominican Republic' },
    { label: 'Algeria' },
    { label: 'Ecuador' },
    { label: 'Estonia' },
    { label: 'Egypt' },
    { label: 'Western Sahara' },
    { label: 'Eritrea' },
    { label: 'Spain' },
    { label: 'Ethiopia' },
    { label: 'Finland' },
    { label: 'Fiji' },
    { label: 'Falkland Islands (Malvinas)' },
    { label: 'Micronesia, Federated States of' },
    { label: 'Faroe Islands' },
    { label: 'France' },
    { label: 'Gabon' },
    { label: 'United Kingdom' },
    { label: 'Grenada' },
    { label: 'Georgia' },
    { label: 'French Guiana' },
    { label: 'Guernsey' },
    { label: 'Ghana' },
    { label: 'Gibraltar' },
    { label: 'Greenland' },
    { label: 'Gambia' },
    { label: 'Guinea' },
    { label: 'Guadeloupe' },
    { label: 'Equatorial Guinea' },
    { label: 'Greece' },
    { label: 'South Georgia and the South Sandwich Islands' },
    { label: 'Guatemala' },
    { label: 'Guam' },
    { label: 'Guinea-Bissau' },
    { label: 'Guyana' },
    { label: 'Hong Kong' },
    { label: 'Heard Island and McDonald Islands' },
    { label: 'Honduras' },
    { label: 'Croatia' },
    { label: 'Haiti' },
    { label: 'Hungary' },
    { label: 'Indonesia' },
    { label: 'Ireland' },
    { label: 'Israel' },
    { label: 'Isle of Man' },
    { label: 'India' },
    { label: 'British Indian Ocean Territory' },
    { label: 'Iraq' },
    { label: 'Iran, Islamic Republic of' },
    { label: 'Iceland' },
    { label: 'Italy' },
    { label: 'Jersey' },
    { label: 'Jamaica' },
    { label: 'Jordan' },
    { label: 'Japan' },
    { label: 'Kenya' },
    { label: 'Kyrgyzstan' },
    { label: 'Cambodia' },
    { label: 'Kiribati' },
    { label: 'Comoros' },
    { label: 'Saint Kitts and Nevis' },
    { label: "Korea, Democratic People's Republic of" },
    { label: 'Korea, Republic of' },
    { label: 'Kuwait' },
    { label: 'Cayman Islands' },
    { label: 'Kazakhstan' },
    { label: "Lao People's Democratic Republic" },
    { label: 'Lebanon' },
    { label: 'Saint Lucia' },
    { label: 'Liechtenstein' },
    { label: 'Sri Lanka' },
    { label: 'Liberia' },
    { label: 'Lesotho' },
    { label: 'Lithuania' },
    { label: 'Luxembourg' },
    { label: 'Latvia' },
    { label: 'Libya' },
    { label: 'Morocco' },
    { label: 'Monaco' },
    { label: 'Moldova, Republic of' },
    { label: 'Montenegro' },
    { label: 'Saint Martin (French part)' },
    { label: 'Madagascar' },
    { label: 'Marshall Islands' },
    { label: 'Macedonia, the Former Yugoslav Republic of' },
    { label: 'Mali' },
    { label: 'Myanmar' },
    { label: 'Mongolia' },
    { label: 'Macao' },
    { label: 'Northern Mariana Islands' },
    { label: 'Martinique' },
    { label: 'Mauritania' },
    { label: 'Montserrat' },
    { label: 'Malta' },
    { label: 'Mauritius' },
    { label: 'Maldives' },
    { label: 'Malawi' },
    { label: 'Mexico' },
    { label: 'Malaysia' },
    { label: 'Mozambique' },
    { label: 'Namibia' },
    { label: 'New Caledonia' },
    { label: 'Niger' },
    { label: 'Norfolk Island' },
    { label: 'Nigeria' },
    { label: 'Nicaragua' },
    { label: 'Netherlands' },
    { label: 'Norway' },
    { label: 'Nepal' },
    { label: 'Nauru' },
    { label: 'Niue' },
    { label: 'New Zealand' },
    { label: 'Oman' },
    { label: 'Panama' },
    { label: 'Peru' },
    { label: 'French Polynesia' },
    { label: 'Papua New Guinea' },
    { label: 'Philippines' },
    { label: 'Pakistan' },
    { label: 'Poland' },
    { label: 'Saint Pierre and Miquelon' },
    { label: 'Pitcairn' },
    { label: 'Puerto Rico' },
    { label: 'Palestine, State of' },
    { label: 'Portugal' },
    { label: 'Palau' },
    { label: 'Paraguay' },
    { label: 'Qatar' },
    { label: 'Reunion' },
    { label: 'Romania' },
    { label: 'Serbia' },
    { label: 'Russian Federation' },
    { label: 'Rwanda' },
    { label: 'Saudi Arabia' },
    { label: 'Solomon Islands' },
    { label: 'Seychelles' },
    { label: 'Sudan' },
    { label: 'Sweden' },
    { label: 'Singapore' },
    { label: 'Saint Helena' },
    { label: 'Slovenia' },
    { label: 'Svalbard and Jan Mayen' },
    { label: 'Slovakia' },
    { label: 'Sierra Leone' },
    { label: 'San Marino' },
    { label: 'Senegal' },
    { label: 'Somalia' },
    { label: 'Suriname' },
    { label: 'South Sudan' },
    { label: 'Sao Tome and Principe' },
    { label: 'El Salvador' },
    { label: 'Sint Maarten (Dutch part)' },
    { label: 'Syrian Arab Republic' },
    { label: 'Swaziland' },
    { label: 'Turks and Caicos Islands' },
    { label: 'Chad' },
    { label: 'French Southern Territories' },
    { label: 'Togo' },
    { label: 'Thailand' },
    { label: 'Tajikistan' },
    { label: 'Tokelau' },
    { label: 'Timor-Leste' },
    { label: 'Turkmenistan' },
    { label: 'Tunisia' },
    { label: 'Tonga' },
    { label: 'Turkey' },
    { label: 'Trinidad and Tobago' },
    { label: 'Tuvalu' },
    { label: 'Taiwan, Province of China' },
    { label: 'United Republic of Tanzania' },
    { label: 'Ukraine' },
    { label: 'Uganda' },
    { label: 'United States' },
    { label: 'Uruguay' },
    { label: 'Uzbekistan' },
    { label: 'Holy See (Vatican City State)' },
    { label: 'Saint Vincent and the Grenadines' },
    { label: 'Venezuela' },
    { label: 'British Virgin Islands' },
    { label: 'US Virgin Islands' },
    { label: 'Vietnam' },
    { label: 'Vanuatu' },
    { label: 'Wallis and Futuna' },
    { label: 'Samoa' },
    { label: 'Kosovo' },
    { label: 'Yemen' },
    { label: 'Mayotte' },
    { label: 'South Africa' },
    { label: 'Zambia' },
    { label: 'Zimbabwe' }
];

export default TrialSession