
import React, { useContext } from "react"
import ConfigContext from "../../utils/config-context"
import { Link } from "react-router-dom";
import { NavLinks } from './NavLinks'

let isteacher="";
let isTrialUser = function() {
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"))
    isteacher = user.isteacher;
    if ("token" in user) {
      return true;
    }
  }

  return false;
};

export const Header = function (props) {

  let config = useContext(ConfigContext)

  let showNavLinks = true;

  showNavLinks = (typeof props.showNavLinks != 'undefined')?props.showNavLinks:showNavLinks;

  
  
    return (<div className="row-fluid edge_menu ">
  <div className="centering">
    <div className="container grid" id="fixed-menu-wrap">
      <div id="logos-wrap">
        {
          props.from==="App"?
          <div className="logos" onClick={props.onMainPage} >
          <a href="/" title="Home">
            <img src="../../learnshipLogo.svg" alt="" />
          </a>
        </div>:
        <div className="logos loo" onClick= {props.navigate}>
        <a  title="Home">
          <img src="../../learnshipLogo.svg" alt="" />
        </a>
      </div>
        }
      </div>
     {
     isTrialUser() == false 
     && showNavLinks==true 
     && <ConfigContext.Provider value={config}>
        <NavLinks pinnedContents={props.pinnedContents} editorView={props.editorView} editViewState={props.editViewState} teacherFlag={isteacher} /> 
     </ConfigContext.Provider> 
     }
     
     
    </div>
  </div>
</div>
)
   

  
}
