import React, { Component } from 'react'
import { getUser } from '../../requests'
import ConfigContext from "../../utils/config-context"
class Login extends Component {

    static contextType = ConfigContext
    
    componentDidMount() {
        let config = this.context;
            getUser().then(function(user){
                if (!user){
                    return false;
                }
                
                if (parseInt(user.userid) !== 0 && user.authstatus===true) {
                    
                    if (user.country=="CN") {
                        window.location.href = "/notallowed";
                    }
                    
                    localStorage.setItem("user", JSON.stringify(user));
                    if(global.window) {
                       global.window.location.href = (document.referrer.match(/^(http|https):\/\/(\w?)+(newsfeed\.(.*))/g))?document.referrer:'/'
                     }
                    return true;
                }
                
                localStorage.removeItem("user");
                global.window.location.href = config.loginUrl+'?redirectUrl='+global.window.location.href;
                return false;
            });
        

    }
    
    render() {
        return (
            <div className="loginLoader">
            </div>
        )
    }
}

export default Login;