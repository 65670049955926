const china_timezones = ['Zhejiang', 'Yunnan',

'Xinjiang',

'Tibet',

'Tianjin',

'Sichuan',

'Shanxi',

'Shanghai',

'Shandong',

'Shaanxi',

'Qinghai',

'Ningxia Hui Autonomous Region',

'Liaoning',

'Jilin',

'Jiangxi',

'Jiangsu',

'Inner Mongolia',

'Hunan',

'Hubei',

'Henan',

'Heilongjiang',

'Hebei',

'Hainan',

'Guizhou',

'Guangxi',

'Guangdong',

'Gansu',

'Fujian',

'Chongqing',

'Beijing',

'Anhui',
]


export default china_timezones;