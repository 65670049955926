import React  from 'react'


let enviroment_config = 
{
    "development":
    {
        "edgeUrl": "https://stageedge.globalenglish.com/One",
        "reachUrl": "https://globalenglish.com/solutions/globalenglish-reach/",
        "dashboardUrl": "https://testdashboard.globalenglish.com",
        "loginUrl": ((global.window.location.hostname.match(/(^|^[^:]+:\/\/|[^\.]+\.)learnship\.tech/g))?"https://login.learnship.tech/":"https://stagelogin.globalenglish.com"),
        "homeUrl": "https://stagehome.globalenglish.com/profile"
        
    },
    "stage":
    {
    "edgeUrl": "https://stageedge.globalenglish.com/One",
    "reachUrl": "https://globalenglish.com/solutions/globalenglish-reach/",
    "dashboardUrl": "https://stagedashboard.globalenglish.com",
    "loginUrl": ((global.window.location.hostname.match(/(^|^[^:]+:\/\/|[^\.]+\.)learnship\.tech/g))?"https://login.learnship.tech/":"https://stagelogin.globalenglish.com"),
    "homeUrl": "https://stagehome.globalenglish.com/profile"
    },
    "production": {
        "edgeUrl": "https://edge.globalenglish.com/One",
        "reachUrl": "https://globalenglish.com/solutions/globalenglish-reach/",
        "dashboardUrl": "https://dashboard.globalenglish.com",
        "loginUrl": ((global.window.location.hostname.match(/(^|^[^:]+:\/\/|[^\.]+\.)learnship\.com/g))?"https://login.learnship.com":"https://login.globalenglish.com"),
        "homeUrl": "https://home.globalenglish.com/profile"
    }

}

export const config = enviroment_config[process.env.BUILD_ENV || "stage"]

const ConfigContext = React.createContext(config);

export default ConfigContext