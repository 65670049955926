import React, { Component } from 'react';
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
// var dateFormat = require('dateformat');

class SearchPage extends Component {

    constructor(props) {
        super(props);
        this.renderContents = this.renderContents.bind(this);       
    }
   
    renderContents() {

        if (this.props.loadingProperty) {
            return (
                <div style={{ textAlign: "center", paddingTop: "100px" }}>
                    <img width="80px" height="80px" src="../../loader-log.gif" alt="" />&nbsp; &nbsp; Searching
           </div>
            )
        }
        else {

            if ((this.props.contents).length > 0 ) {              
                const newsFeeds = this.props.contents.map((content, index) => {
                    if(index < this.props.searchLimit){
                    const { UUID, Source, Title, Content, Readtime, ImageUrl, PublishedDate,Category } = content;
                    var date_ = new Date(PublishedDate);
                    var publishedDate = dateFormat(date_, "dd mmmm,yyyy");
                    return (
                        <div style={{ marginBottom: "25px", cursor: "pointer" }}> <Link to={"/" + Category + "/" + UUID} key={UUID}  onClick={this.props.openModal.bind(null, UUID)} >
                            <div className="searchImage">
                                <img width="120px" height="78px" src={ImageUrl === "" ? "../../noImage.jpg" : ImageUrl} alt="Content Img" />
                            </div>
                            <div className="" >
                                <div style={{ fontSize: "16px", fontWeight: "600", color: "#000000", textAlign: "justify" }}>{Title}</div>
                                {Source === "None" ?
                                    <div className="thumbnailDetails">{publishedDate} | {Readtime} </div> :
                                    <div className="thumbnailDetails">{publishedDate} | {Source} | {Readtime}</div>
                                }
                                <div className="searchContent">{Content.slice(0, 150)}....</div>
                            </div>
                            </Link>
                        </div>
                    )
                            }
                })
                return newsFeeds;
            }

        }
    }

    loadMore() {
        if (!this.props.loadingProperty) {
            if (!this.props.loadingMoreRecords) {
                if(this.props.contents.length > this.props.searchLimit)
                    return (
                        <div>
                            {this.props.contents[0] && <div className="loadMore"><button onClick={this.props.loadMore.bind(null, true)}>Load More</button></div>}
                        </div>
                    )
                

            }
            else {
                return (
                    <div style={{ textAlign: "center", paddingBottom: "50px" }}>
                        <img width="80px" height="80px" src="../../loader-log.gif" alt="" />
                    </div>
                )
            }
        }
    }

    componentDidMount(){
        window.onpopstate = (event) => {
            window.location.href="/";
          }         
    }

    render() {    
        return (
            <div className="container" id="searchPage" role="document">
                <div className="searchPage">


                    {!this.props.loadingProperty && ((this.props.contents).length <= 0) &&
                        <div className="resultNos">
                            No results found for '{this.props.searchQuery}'. Try modifying your search terms.
    
                        </div>
                    }

                    {!this.props.loadingProperty && ((this.props.contents).length > 0) &&
                        <div className="resultNos">
                            {(this.props.contents).length} results  for '{this.props.searchQuery}'.  
                        </div>
                    }


                    {this.renderContents()}
                </div>
                {this.loadMore()}
            </div>
        );
    }

}

export default SearchPage;