import { config } from './utils/config-context'
const endpointURL = '/graphql';
//const endpointURL = 'https://stagenewsfeed.globalenglish.com/graphql'




async function graphqlRequest(query, variables = {}, custom_header = false, handle_error = true) {

    let headers = {
        "content-type": "application/json",
        'Accept': 'application/json'
        //"Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiIyMzk5MzIzIiwiZ2VJZCI6IjIwMjM3NDQifQ.nRttiT8Mm5OsbsvxAv2SoJwTEfkJfxof1e8MdgCrPUo"

    }

    if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user"))

        if ("token" in user) {
            headers["Authorization"] = "Bearer " + user.token
        }
    }

    const request = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ query, variables })
    };
    const response = await fetch(endpointURL, request).catch((err) => {
        return err;
    });


    if (response.status && response.status == 401) {
        localStorage.removeItem("user");
        global.window.location.href = config.loginUrl + '?redirectUrl=' + global.window.location.href;
    }
    const responseBody = await response.json();

    if (responseBody.errors) {

        if (handle_error == false) {
            return responseBody;
        }

        var errorCode = [404, 500, 403];
        var count = 0;
        const message = responseBody.errors.map((error) => error.message).join("\n");
        localStorage.setItem("ErrorMessage", message);

        if (responseBody.errors[0].code === 401) {

            localStorage.removeItem("user");
            global.window.location.href = config.loginUrl + '?redirectUrl=' + global.window.location.href;
        } else {
            for (var i = 0; i < errorCode.length; i++) {
                if ((responseBody.errors[0].code === errorCode[i])) { count++ }
            } if (count > 0) {
                window.location.href = "/error";
            }
        }
    }
    return (responseBody.data) ? responseBody.data : {};
};

const categoriesQuery = `
    query CategoriesQuery{
        categories{
        category
        }
    }
    `
const contentsQuery = `
query ContentsQuery($level: String, $searchUuid: String,$category: String,$searchQuery: String, $searchTitle:String, $limit: Int, $from: Int){
    contents(level:{name:$level},filter:{category:$category,searchUuid:$searchUuid, searchQuery:$searchQuery, searchTitle:$searchTitle},limitRows:{limit:$limit,offset:$from}){
    UUID
    Url
    Source
    Author
    Title
    Content
    Summary
    Keywords
    Tags
    Readtime
    Media_Url
    ImageUrl
    PublishedDate
    Category
    }
}
    `

const levelWiseContentQuery = ` 
query LevelWiseContentQuery($level: String,$category: String,$uuid:String,$date:String$limit: Int, $from: Int){
        classifiedContent(params: {level: $level,category: $category,uuid: $uuid, date:$date}, limitRows:{limit: $limit,offset: $from }) {
            UUID
            Url
            Source
            Author
            Title
            Content
            Summary
            Keywords
            Tags
            Media_Url
            ImageUrl
            Readtime
            PublishedDate
            Category
            Pinned
            ContentDate
        }
      }
`


const relatedContentsQuery = `
    query RelatedContentsQuery($level: String,$relatedSearchQuery: String, $limit: Int, $from: Int){
    relatedContents(level:{name:$level},filter:{relatedSearchQuery:$relatedSearchQuery},limitRows:{limit:$limit,offset:$from}){
        UUID
        Url
        Source
        Author
        Title
        Content
        Summary
        Keywords
        Tags
        Media_Url
        ImageUrl
        Readtime
        PublishedDate
        Category
    }
    }
    `
const MeaningsQuery = `
query MeaningsQuery($word: String){
    meaning(word:{word: $word}){
      definition
      partOfSpeech
      synonyms
      antonyms
      examples
    }
  }`

const QuestionsQuery = `
  query QuestionsQuery($level: String, $uuid: String, $type: String, $order: Int){
    questions(filter:{level:$level, uuid:$uuid, type: $type, order:$order}){
        question_uid
        content_uuid
        audio_location
        question
        question_type
        sentence
        options
    }
  }`

const AnswersQuery = `
    query AnswersQuery($level: String, $uuid: String,$exerciseType:String, $title: String, $category: String, $ids: [String], $order: Int, $duration: String, $userAnswers: [String]){
        answers(filter:{level:$level,exerciseType:$exerciseType, title:$title, category:$category, uuid:$uuid, ids: $ids, order:$order, userAnswers: $userAnswers, duration: $duration}){
        question_id
        answer
        answer_explanation
        }
    }`

const storeResultsQuery = `
    query StoreResultsQuery($level: String,$exerciseType: String, $uuid: String, $title: String, $category: String, $ids: [String], $order: Int, $duration: String, $userAnswers: [String], $score: Float){
        storeExerciseResults(filter:{level:$level, title:$title, category:$category, uuid:$uuid,exerciseType: $exerciseType ids: $ids, order:$order, userAnswers: $userAnswers, duration: $duration, score: $score}){
        code
        message
        }
    }`

const showAnswersQuery = `
    query showAnswersQuery($level: String, $uuid: String, $ids: [String], $order: Int, $duration: String){
        showAnswers(filter:{level:$level, uuid:$uuid, ids: $ids, order:$order,  duration: $duration}){
        question_id
        answer
        answer_explanation
        }
    }`

const audioDownloadQuery = `
    mutation AudioDownload($input: AudioInput){
    audioDownload(audioParams: $input){
      data
    }
  }`

const similarityScoresQuery = `
    query SimilarityScores($answer: String!, $audio_data: String, $user_answer: String){
        similarityScore(simParams: {answer : $answer, audio_data: $audio_data, user_answer: $user_answer}){
        similarity,
        answer,
        user_answer
        }
    }`

const newsAddQuery = `query NewsAddQuery($newsId: String!){
    newsAdd(newsId:{newsId: $newsId}){
      code
      message
    }
}`

const getPreferenceQuery = `
    query GetPreferences{
        getPreferences{
            preferences {
            voice_type
            voice_gender
            } 
        }
    }`

const updatePreferencesQuery = `
    query updatePreferences($voice_type: String!, $voice_gender: String!){
        updatePreferences(prefParams:{ voice_type: $voice_type, voice_gender: $voice_gender}){
        code
        message
        }
    }`

const userQuery = `
    query userQuery{
        user{
        userid,
        authstatus,
        level,
        country,
        trialaccess,
        isteacher
        }
    }
    `
const userLevelQuery = `
    query getUserLevel{
        userLevel{
        level
        }
    }
    `

const trialUserQuery = `  mutation TrialUserquery($emailId:String!, $accountName:String!, $country: String) {
trialUser( trailUserParams: {emailAddress: $emailId, accountName: $accountName, country: $country})
{
    shortCode
}
      }`

const validateTrialUserQuery = `
query validateTrialUserQuery($shortCode: String!) {  validateTrialUser(validateTrialUserParams: {shortCode: $shortCode}) {    token  }}`


const pinContentQuery = `mutation ($level:String,$date:String,$uuid:String!,$content:String!,$versionid:String,$exerciseVersionId:String)  { pinContent
 (pinContentParams: {
      level: $level,
      date: $date,
      uuid: $uuid,
      content: $content,
      versionId: $versionid,
      exerciseVersionId: $exerciseVersionId
    })
    {
      code
      message
    }
  }`

const classifiedContentPublishQuery = `query($type: String!) {
    classifiedContentPublish(classifiedContentPublishParams: {
      type: $type
    }, limitRows: {
      limit: 0
      offset: 10
    } ) {
      UUID,
      Title,
      Content,
      Summary,
      Category,
      PublishedDate,
      Level,
      VersionId,
      IsRecommendedToChina
    }
  }`


const saveContentQuery = `mutation($level:Int!,$uuid:String!,$category:String!,$title:String!,$content:String!,$summary:String!,$tags:String!,$isRecommendedToChina:Boolean) {
    saveContent(saveContentParams:{
      level: $level,
      uuid: $uuid,
      category: $category,
      title: $title,
      content:$content,
      summary:$summary,
      tags:$tags,
      isRecommendedToChina:$isRecommendedToChina
        }){
      code,
      message
    }
  }`




const versionContentQuery = `query($type:String!, $uuid: String, $version:Boolean, $versionId:String) {
    classifiedContentPublish(classifiedContentPublishParams: {
      type: $type,
      uuid: $uuid,
      version: $version,
      versionId: $versionId
     
    }, limitRows: {
      limit: 0
      offset: 10
    } ) {
      UUID,
      Title,
      Content,
      Summary,
      Category,
      PublishedDate,
      Tags,
      VersionId,
      PreviewVersion,
      Level,
      IsRecommendedToChina
      Versions{
        IsLatest,
        VersionId,
        Version,
        LastModified
      }
    }
  }`


const exerciseContentPublishQuery = `query($category:String!,$uuid:String!,$versionId:String){
    exerciseContentPublish(
      exerciseContentPublishParams: {
        uuid: $uuid,
        category: $category
        versionId: $versionId
      }
    ){
        exercisePublish{
          id,
          question_uid,
          question_type,
          sentence,
          question,
          answer,
          answer_explanation,
          options,
          audio_location
          
        }
        versions {
         VersionId        
        }
        previewVersion
        versionId
      }
    }`


const saveExerciseQuery = `mutation($exercises:[ExerciseFields],$uuid:String){
    saveExercises(
      saveExercisesParams:{
       
    exercises: $exercises,
    uuid: $uuid
  }
    ){
      code
      message
    }
  }`

  const deleteContentQuery = `mutation($uuid:String!,$content:String!,$versionId:String){
    deleteContent(deleteContentParams:{
      uuid: $uuid,
      content: $content,
      versionId: $versionId
    }) {
     code
      message
    }
  }`


  const deleteExercisesQuery = `mutation($uuid:String!,$content:String!,$versionId:String){
    deleteExercises(deleteExercisesParams:{
        uuid: $uuid,
        content: $content,
        versionId: $versionId
    }) {
     code
      message
    }
  }`


export async function loadCategories() {
    const { categories } = await graphqlRequest(categoriesQuery);
    return categories
}

export async function loadContents(variables) {
    const { contents } = await graphqlRequest(contentsQuery, variables);
    return contents;
}


export async function loadLevelWiseContents(variables) {
    const { classifiedContent } = await graphqlRequest(levelWiseContentQuery, variables);
    return classifiedContent;

}

export async function loadRelatedContents(variables) {
    const { relatedContents } = await graphqlRequest(relatedContentsQuery, variables);
    return relatedContents;
}

export async function getMeaning(variables) {
    const response = await graphqlRequest(MeaningsQuery, variables);
    return response["meaning"];
}

export async function getQuestions(variables) {
    const { questions } = await graphqlRequest(QuestionsQuery, variables);
    return questions;
}


export async function getAnswers(variables) {
    const { answers } = await graphqlRequest(AnswersQuery, variables);
    return answers;
}

export async function storeResults(variables) {
    const { storeExerciseResults } = await graphqlRequest(storeResultsQuery, variables);
    return storeExerciseResults;
}

export async function getShowAnswers(variables) {
    const { showAnswers } = await graphqlRequest(showAnswersQuery, variables);
    return showAnswers;
}

export async function getAudioData(variables) {
    const { audioDownload } = await graphqlRequest(audioDownloadQuery, variables);
    return audioDownload;
}

export async function getSimilarityScore(variables) {
    const { similarityScore } = await graphqlRequest(similarityScoresQuery, variables);
    return similarityScore;
}

export async function newsAddSession(variables) {
    const response = await graphqlRequest(newsAddQuery, variables);
    return response
}

export async function getPreferences(variables) {
    const { getPreferences } = await graphqlRequest(getPreferenceQuery, variables);
    return getPreferences;
}

export async function updatePreferences(variables) {
    const { updatePreferences } = await graphqlRequest(updatePreferencesQuery, variables);
    return updatePreferences;
}

export async function getUser() {
    const { user } = await graphqlRequest(userQuery);
    return user
}

export async function getUserLevel() {
    const { userLevel } = await graphqlRequest(userLevelQuery);
    return userLevel;
}


export async function getTrialUserData(variables, custom_header, handle_error) {
    let data = await graphqlRequest(trialUserQuery, variables, custom_header, handle_error);
    return data;
}

export async function validateTrialUserData(variables) {
    const { validateTrialUser } = await graphqlRequest(validateTrialUserQuery, variables, true);
    return validateTrialUser;
}

export async function pinContents(variables) {
    const { pinContent } = await graphqlRequest(pinContentQuery, variables);
    return pinContent;
}

export async function classifiedContentPublish(variables) {
    const { classifiedContentPublish } = await graphqlRequest(classifiedContentPublishQuery, variables);
    return classifiedContentPublish;
}

export async function saveContent(variables) {
    const { saveContent } = await graphqlRequest(saveContentQuery, variables);
    return saveContent;
}


export async function versionContents(variables) {
    const { classifiedContentPublish } = await graphqlRequest(versionContentQuery, variables);
    return classifiedContentPublish;
}


export async function exerciseContentPublish(variables) {
    const { exerciseContentPublish } = await graphqlRequest(exerciseContentPublishQuery, variables);
    return exerciseContentPublish;
}

export async function saveExercise(variables) {
    const { saveExercises } = await graphqlRequest(saveExerciseQuery, variables);
    return saveExercises;
}


export async function deleteContent(variables) {
    const { deleteContent } = await graphqlRequest(deleteContentQuery, variables);
    return deleteContent;
}

export async function deleteExercises(variables) {
    const { deleteExercises } = await graphqlRequest(deleteExercisesQuery, variables);
    return deleteExercises;
}
