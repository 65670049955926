import React, { Component } from "react";
import { Link } from "react-router-dom";
import NewsFeed from "./components/NewsFeed/NewsFeed";
import Modal from "./components/Modal/Modal";
import SearchPage from "./components/SearchPage/SearchPage";
import Loader from "./components/Loader/Loader";
import PinnedContents from './components/PinnedContents/PinnedContents';
import { Header } from './components/Header/Header';
import { Navbar, Nav, TabContainer } from 'react-bootstrap';

import LevelsHeader from './components/LevelsHeader/LevelsHeader';
import Footer from "./components/Footer/Footer";
import "./App.css";
import { loadCategories, loadContents, loadLevelWiseContents, classifiedContentPublish, versionContents, exerciseContentPublish } from "./requests";
import UserContext, { user } from './utils/user-context'
import ConfigContext, { config } from './utils/config-context';


class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      displayWelcomeContent: false,
      displayNewsFeed: true,
      displayPreviewModal: false,
      displaySearchPage: false,
      categories: [],
      contents: [],
      selectedIndexToView: 0,
      selectedUUID: "",
      // level has to change according to the user-level automatically
      level: user.level + "_*",
      selectedCategory: "topstories",
      searchUuid: "",
      limit: 13,
      searchLimit: 7,
      from: 0,
      searchQuery: "",
      searchTitle: "",
      activeCategory: "topstories",
      noContents: false,
      modelNavigationLink: "",
      loadingProperty: false,
      loadingMoreRecords: false,
      loadingPage: true,
      loadingCategories: true,
      showPinnedContents: false,
      showHeader: true,
      showContent: true,
      showFooter: true,
      redirect: false,
      user: user,
      config: config,
      editorView: false,
      callLevelWiseQuery: false,
      learnerView: true,
      pinnedContentsDrafts: [],
      loadTable: false,
      loadUrl: "learner",
      showPinnedPage: true,
      showPreviewPage: false,
      versionContent: [],
      showEditContentPage: false,
      navigateContent: [],
      showNewsModal: true,
      previewType: "",
      exerQues: [],
      previewVersion: "",
      editLoader: false,
      enableLoader: false,
      exerVersion: [],
      previewLoader: false



    };
    this.pinnedContents = this.pinnedContents.bind(this);
    this.mainPage = this.mainPage.bind(this);
    this.openModal = this.openModal.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.categoryWiseContents = this.categoryWiseContents.bind(this);
    this.searchContents = this.searchContents.bind(this);
    this.searchContentsThroughButton = this.searchContentsThroughButton.bind(
      this
    );
    this.searchPage = this.searchPage.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleDidMount = this.handleDidMount.bind(this);
    this.loadLevelwiseContent = this.loadLevelwiseContent.bind(this);
    this.backButtonURL = this.backButtonURL.bind(this);
    this.levelWise = this.levelWise.bind(this);
    this.ShowEditContents = this.ShowEditContents.bind(this);
    this.previewContents = this.previewContents.bind(this);
    this.editorView = this.editorView.bind(this);
    this.pinnedContentQuery = this.pinnedContentQuery.bind(this);
    this.navigate = this.navigate.bind(this);
    this.navigatePreview = this.navigatePreview.bind(this);
    this.previewPage = this.previewPage.bind(this);
    this.listPage = this.listPage.bind(this);
    this.navigateEdit = this.navigateEdit.bind(this);
    this.closeEditPage = this.closeEditPage.bind(this);
    this.enableLoader = this.enableLoader.bind(this);
    this.previewLoader = this.previewLoader.bind(this);
    this.closeTab = this.closeTab.bind(this);
  }


  async navigatePreview(UUID, type, exVersion) {

    const versionContent = await versionContents({
      type: "drafts",
      uuid: UUID,
      version: true,
      versionId: ""

    });


    const getQuest = await exerciseContentPublish({
      uuid: UUID,
      category: "vocabulary",
      versionId: exVersion

    });


    this.setState({ versionContent: versionContent, exerVersion: getQuest, showEditContentPage: false, previewType: type, previewVersion: exVersion }, () => {
      this.setState({ showPinnedContents: true, showPreviewPage: true, showPinnedPage: false, enableLoader: false });
      window.scrollTo(0, 0);
      this.setState({
        showContent: false,
        displayNewsFeed: false,
        displayWelcomeContent: false,
        displayPreviewModal: false,
        displaySearchPage: false,
        showHeader: false
      });
      this.previewContents();


    });

  }

  async previewPage() {
    this.setState({ showPinnedPage: false, showPreviewPage: true })
  }

  async listPage() {
    this.setState({ showPinnedPage: true, showPreviewPage: false })
  }

  async previewLoader(loaderStatus) {
    if (loaderStatus === true) { this.setState({ showPinnedPage: false, previewLoader: true, showPreviewPage: false }) }
    else { this.setState({ previewLoader: false }) }
  }

  enableLoader() {
    this.setState({ enableLoader: true, showEditContentPage: false, showPinnedPage: false, showPreviewPage: false })
  }


  async pinnedContents() {
    this.setState({
      showPinnedContents: true, displayNewsFeed: false,
      displayWelcomeContent: false,
      displayPreviewModal: false,
      displaySearchPage: false,
      showHeader: false, showContent: false,
      showEditContentPage: false,
      showPreviewPage: false,
      showPinnedPage: true
    });
    this.pinnedContentQuery("drafts");
    // window.location.href = "/pinned-contents";
  }


  async pinnedContentQuery(query) {

    let type1 = (query === "drafts") ? "Drafts" : "Published"
    this.setState({ loadTable: true, showPinnedPage: true });
    const classifiedContents = await classifiedContentPublish({
      type: query

    });
    this.setState({
      pinnedContentsDrafts: classifiedContents,
      previewType: type1

    });
    this.setState({ loadTable: false })
  }


  async previewContents() {
    this.setState({
      showFooter: false
    });
  }


  async navigate() {

    window.location.href = "/learner/topstories"
  }
  async editorView() {
    this.closeTab(document.getElementById("view").innerHTML);
    if (document.getElementById("view").innerHTML == "Editor View") {
      this.setState({ loadUrl: "editor" });
      document.getElementById("view").innerHTML = "Learner View";
      this.setState({
        editorView: true,
        callLevelWiseQuery: true,
        learnerView: false,
        activeCategory: "business",
        displayNewsFeed: true,
        displayPreviewModal: false,
        displaySearchPage: false,
        showPinnedContents: false,
        showHeader: true,
        showContent: true,

      }, () => {

        this.levelWise("levelone");
        var Url = this.props.location.pathname;
        var routeUrl = Url.split('/').slice(2)[0];
        var routeUrl1 = Url.split('/').slice(3)[0];
        var routeUrl2 = routeUrl1 ? routeUrl + "/" + routeUrl1 : routeUrl;
        window.history.pushState(null, '', window.location.toString().replace('/learner/' + routeUrl2, '/editor/business'));
      });


    } else {
      document.getElementById("view").innerHTML = "Editor View"
      this.setState({ loadUrl: "learner" });
      let Level = this.state.level + "_*"
      this.setState({
        editorView: false,
        level: Level, callLevelWiseQuery: false,
        learnerView: true, selectedCategory: "topstories",
        activeCategory: "topstories",
        displayNewsFeed: true,
        displayPreviewModal: false,
        displaySearchPage: false,
        showPinnedContents: false,
        showHeader: true, showContent: true

      }, () => {
        this.handleDidMount();
        var Url = this.props.location.pathname;
        var routeUrl = Url.split('/').slice(2)[0];
        var routeUrl1 = Url.split('/').slice(3)[0];
        var routeUrl2 = routeUrl1 ? routeUrl + "/" + routeUrl1 : routeUrl;
        window.history.pushState(null, '', window.location.toString().replace('/editor/' + routeUrl2, '/learner/topstories'));
      });


    }


  }




  async levelWise(level) {
    let contentLevel = level
    var levClass = document.getElementsByClassName("contentLevels");
    for (let i = 0; i < levClass.length; i++) {

      if (levClass[i].id === level) {
        levClass[i].style.color = "#E0291A";
        levClass[i].style.borderBottom = "3px solid #E0291A";
      }
      else {
        levClass[i].style.color = "#333333";
        levClass[i].style.borderBottom = "3px solid transparent"
      }
    }
    // Uncomment only when graphql integrated for teachers view
    // Temporary Solution for Level issue
    let user = JSON.parse(localStorage.getItem("user"));
    user.level = level
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({
      limit: 13,
      level: contentLevel,
      displayNewsFeed: true,
      displayPreviewModal: false,
      searchQuery: "",
      loadingProperty: true,
      loadingPage: true,
      loadingCategories: false
    }, this.loadLevelwiseContent);
  }


  async loadLevelwiseContent() {
    this.setState({ noContents: false });
    let category = this.state.activeCategory.replace(/\s/g, "").toLowerCase();
    let level = this.state.level.replace("_*", "")
    const classifiedContent = await loadLevelWiseContents({
      level: level,
      category: category,
      limit: this.state.limit,
      from: this.state.from,
      uuid: "",
      date: ""

    });

    this.setState({
      contents: classifiedContent,
      loadingProperty: false,
      loadingMoreRecords: false,
      loadingPage: false,
      loadingCategories: false
    })
    classifiedContent.length === 0
      ? this.setState({ noContents: true })
      : this.setState({ noContents: false });

    

    if (document.getElementById("span_9") != null && (document.getElementById("span_9").style.width === "100%")) {
      var x = document.getElementsByClassName("thumbnail").length;
      for (var i = 0; i < x; i++) {
        document.getElementsByClassName("thumbnail")[i].className +=
          " col-sm-3";
        document
          .getElementsByClassName("thumbnail")
        [i].classList.remove("col-sm-4");
      }
    }

  }



  async handleDidMount() {

    this.setState({ noContents: false });
    let category = this.state.activeCategory.replace(/\s/g, "").toLowerCase();
    if (category === "topstories") {
      category = "";
    }
    const contents = await loadContents({
      level: this.state.level,
      category: category,
      limit: this.state.limit,
      from: this.state.from,
      searchQuery: this.state.searchQuery,
      searchTitle: this.state.searchTitle,
      searchUuid:""

    });
    this.setState({
      contents: contents,
      loadingProperty: false,
      loadingMoreRecords: false,
      loadingPage: false,
      loadingCategories: false
    });
   
    contents.length === 0
      ? this.setState({ noContents: true })
      : this.setState({ noContents: false });
    if (document.getElementById("span_9") != null && (document.getElementById("span_9").style.width === "100%")) {
      var x = document.getElementsByClassName("thumbnail").length;
      for (var i = 0; i < x; i++) {
        document.getElementsByClassName("thumbnail")[i].className +=
          " col-sm-3";
        document
          .getElementsByClassName("thumbnail")
        [i].classList.remove("col-sm-4");
      }
    }
  }


  mainPage() {
    var categoryURL = window.location.pathname.substring(1);
    var categorySelected = (categoryURL === "") ? "topstories" : categoryURL.substring(0, categoryURL.lastIndexOf('/')) ? categoryURL.substring(0, categoryURL.lastIndexOf('/')) : window.location.pathname.substring(1);
    window.location.href = "/" + categorySelected;
    document.getElementById("search").style.textAlign = "left";
    document.getElementById("searchBox").style.width = "250px";
  }

  searchPage() {
    this.setState({
      displaySearchPage: true,
      displayWelcomeContent: false,
      displayNewsFeed: false,
      displayPreviewModal: false
    });
  }
  async openModal(UUID) {
   
    if (this.state.callLevelWiseQuery === true) {
     
      let category = this.state.activeCategory.replace(/\s/g, "").toLowerCase();
      let level = this.state.level.replace("_*", "");
      const classifiedContent = await loadLevelWiseContents({
        level: level,
        category: category,
        limit: this.state.limit,
        from: this.state.from,
        uuid: UUID,
        date: ""

      });
      this.setState({ contents: classifiedContent });
    }

   
    this.setState({
      selectedIndexToView: 0,
      selectedUUID: UUID,
      displayNewsFeed: false,
      displayWelcomeContent: false,
      displayPreviewModal: true,
      displaySearchPage: false,
    });
    window.scrollTo(0, 0);
    // document.getElementById("search").style.textAlign = "left";
    // document.getElementById("searchBox").style.width = "250px";


  }

  loadMore(searchPage = false) {
    if (searchPage === true) {
      let newLimit = this.state.searchLimit + 7;
      this.setState({ searchLimit: newLimit },()=>{
      });

    }
    else {
      this.setState({ loadingMoreRecords: true });
      const limit = this.state.limit;
      let newLimit = limit + 12;
      
      this.setState({ limit: newLimit }, () => {
       
        this.state.callLevelWiseQuery === true ? this.loadLevelwiseContent() : this.handleDidMount();
        if (document.getElementById("span_9") != null && (document.getElementById("span_9").style.width === "100%")) {
          var x = document.getElementsByClassName("thumbnail").length;
          for (var i = 0; i < x; i++) {
            document.getElementsByClassName("thumbnail")[i].className +=
              " col-sm-3";
            document
              .getElementsByClassName("thumbnail")
            [i].classList.remove("col-sm-4");
          }
        }
      });
    }
  }


  async categoryWiseContents(e) {
    let categorySelected;
    var count = 0;
    var categoryIndex;

    if (e && e.target && e.target.getAttribute("category-name")) {
      categorySelected = e.target.getAttribute("category-name");
    }
    else {
      var routeUrl = this.props.location.pathname;
      if (routeUrl.split('/').slice(1)[0] === "editor" || "learner") {
        var categoryURL = routeUrl.split('/').slice(2)[0];
        categorySelected = categoryURL === "topstories" ? "topstories" : categoryURL;
        for (var j = 0; j < this.state.categories.length; j++) {
          if ((this.state.categories[j].toLowerCase() === categorySelected.toLowerCase()) || (categoryURL === "topstories")) { count++; }

        }

        categoryIndex = routeUrl.split('/').slice(3)[0] ? routeUrl.split('/').slice(3)[0] : 0;
        // var isnum = /^\d+$/.test(categoryIndex);
        if ((count === 0)) {
          window.location.href = "/404notFound";
        }

        if ((this.props.location.pathname.split('/').slice(3)[0])) {

          var categorySelected1 = categorySelected;

          if ((this.props.location.pathname.split('/').slice(1)[0]) === "learner") {
            const contents = await loadContents({
              level: this.state.level,
              category: categorySelected1,
              limit: 13,
              from: this.state.from,
              searchQuery: this.state.searchQuery,
              searchTitle: this.state.searchTitle,
              searchUuid: categoryIndex
            });
            this.setState({
              contents: contents,
              loadingProperty: false,
              loadingMoreRecords: false,
              loadingPage: false,
              loadingCategories: false,
              displayNewsFeed: false
            }, () => {
              this.openModal(categoryIndex)
            })
          }

          else if ((this.props.location.pathname.split('/').slice(1)[0]) === "editor") {
            let level = this.state.level.replace("_*", "");
            let uuid = this.props.location.pathname.split('/').slice(3)[0];

            const classifiedContent = await loadLevelWiseContents({
              level: level,
              category: categorySelected1,
              limit: this.state.limit,
              from: this.state.from,
              uuid: uuid,
              date: ""

            });
            this.setState({
              contents: classifiedContent,
              loadingProperty: false,
              loadingMoreRecords: false,
              loadingPage: false,
              loadingCategories: false,
              displayNewsFeed: false
            }, () => {
              this.openModal(categoryIndex);
            })
          }



        }
        else {
          this.setState({ displayWelcomeContent: true })
        }

      }
      else {
        window.location.href = "/404notFound";
      }

    }
    if (window.location.pathname.split('/').slice(1)[0] === "editor") {
      this.setState({ loadUrl: "editor" });
      document.getElementById("view").innerHTML = "Learner View";
      this.setState({
        editorView: true,
        callLevelWiseQuery: true,
        learnerView: false,
        activeCategory: "business",
        displayNewsFeed: true,
        displayPreviewModal: false,
        displaySearchPage: false,
        showPinnedContents: false,
        showHeader: true, showContent: true
      })
    }

    this.setState(
      {
        limit: 13,
        searchQuery: "",
        selectedCategory: categorySelected,
        modelNavigationLink: categorySelected,
        activeCategory: categorySelected,
        loadingProperty: true,
        loadingCategories: false,
        loadingPage:true
      }, () => {
        this.props.location.pathname.split('/').slice(1)[0] === "editor" ? this.loadLevelwiseContent() : this.handleDidMount();
      }
    );

  }

  async backButtonURL() {

    document.getElementById("searchBox").value = "";
    let categorySelected;
    var count = 0;
    var categoryIndex;
    var categoryURL = window.location.pathname.substring(1);
    categorySelected = (categoryURL === "") ? "topstories" : categoryURL.substring(0, categoryURL.lastIndexOf('/')) ? categoryURL.substring(0, categoryURL.lastIndexOf('/')) : window.location.pathname.substring(1);
    for (var j = 0; j < this.state.categories.length; j++) {
      if ((this.state.categories[j].toLowerCase() === categorySelected.toLowerCase()) || (categorySelected.toLowerCase() === "topstories")) { count++; }

    }

    categoryIndex = categoryURL.split('/').slice(1)[0] ? categoryURL.split('/').slice(1)[0] : 0;
    // var isnum = /^\d+$/.test(categoryIndex);
    if ((count === 0)) {
      window.location.href = "/404notFound";
    }

    if ((categoryURL.split('/').slice(1)[0])) {
      var categorySelected1 = categorySelected === "topstories" ? "" : categorySelected;

      const contents = await loadContents({
        level: this.state.level,
        category: categorySelected1,
        limit: this.state.limit,
        from: this.state.from,
        searchQuery: this.state.searchQuery,
        searchTitle: this.state.searchTitle,
        searchUuid: categoryIndex

      });
      this.setState({
        contents: contents,
        loadingProperty: false,
        loadingMoreRecords: false,
        loadingPage: false,
        loadingCategories: false
      }, () => {
        this.openModal(categoryIndex)
      });
    }


    this.setState(
      {
        limit: this.state.limit,
        searchQuery: "",
        selectedCategory: categorySelected,
        modelNavigationLink: categorySelected,
        activeCategory: categorySelected,
        loadingProperty: true,
        loadingPage: true,
        loadingCategories: false,
        displayPreviewModal: false,
        displayNewsFeed: true,
      }, () => {
        this.state.callLevelWiseQuery === true ? this.loadLevelwiseContent() : this.handleDidMount();
      }
    );

  }

  searchContents(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      const query = event.target.value;
      if (query.length > 0) {
        this.setState(
          {
            limit:100,
            searchLimit: 7,
            displaySearchPage: true,
            displayWelcomeContent: false,
            displayNewsFeed: false,
            displayPreviewModal: false,
            searchQuery: query,
            modelNavigationLink: "Search Results",
            loadingProperty: true,
            loadingPage: true,
          }, () => { this.state.callLevelWiseQuery === true ? this.loadLevelwiseContent() : this.handleDidMount(); }


        );
      }
    }
  }
  searchContentsThroughButton(tags) {
    var query = "";
    if (tags === "") {
      query = document.getElementById("searchBox").value;
    }
    else {
      query = tags;
    }
    if (query.length > 0) {
      this.setState(
        {
          limit:100,
          searchLimit: 7,
          displaySearchPage: true,
          displayWelcomeContent: false,
          displayNewsFeed: false,
          displayPreviewModal: false,
          searchQuery: query,
          modelNavigationLink: "Search Results",
          loadingProperty: true,
          loadingPage: true
        }, () => { this.state.callLevelWiseQuery === true ? this.loadLevelwiseContent() : this.handleDidMount(); }

      );
      document.getElementById("searchBox").value = query;
    }
  }

  async componentDidMount() {

    const categories = await loadCategories();
    if (!categories) {
      return false;
    }

    let showDropDown = true;

    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"))

      if ("token" in user) {
        showDropDown = false;
      }
    }
    if (showDropDown) {
      let dropdown = document.querySelector(".dropdown-menu");
      let setDrpdwn = document.querySelector(".settingMenu_dropdown");
      let mySol = document.querySelector(".mySolutions");
      let profileSel = document.querySelector(".profileSelect");
      window.onclick = e => {
        e.target === mySol &&
          (dropdown.style.display === "" || dropdown.style.display === "none")
          ? (dropdown.style.display = "block")
          : (dropdown.style.display = "none");
        e.target === profileSel &&
          (setDrpdwn.style.display === "" || setDrpdwn.style.display === "none")
          ? (setDrpdwn.style.display = "block")
          : (setDrpdwn.style.display = "none");
      };
    }
    const categoriesList = await categories.map(category => {
      return category["category"];
    });
    this.setState(
      { categories: categoriesList, modelNavigationLink: this.state.selectedCategory },
      this.categoryWiseContents,
      window.onpopstate = (event) => {
        this.backButtonURL();
      }
    );

  }

  async ShowEditContents() {
    document.getElementById("feedContainer").style.border = "none";
    this.setState({ showHeader: false, showFooter: false, showEditContentPage: true })
  }


  async navigateEdit(UUID, version, exVersion) {
    if (document.getElementById("feedContainer") != null) { document.getElementById("feedContainer").style.border = "none"; }
    this.setState({ editLoader: true, showNewsModal: false, showHeader: false, showPinnedPage: false, showPreviewPage: false })

    const versionContent = await versionContents({
      type: "drafts",
      uuid: UUID,
      version: true,
      versionId: version

    });

    const getQuest = await exerciseContentPublish({
      uuid: UUID,
      category: "vocabulary",
      versionId: exVersion

    });
    this.setState({
      contents: this.state.contents,
      navigateContent: versionContent,
      exerQues: getQuest,
      showContent: true,
      showPinnedContents: false, displayNewsFeed: false,
      displayWelcomeContent: false,
      displayPreviewModal: true,
      displaySearchPage: false,
      showPreviewPage: false,
      showPinnedPage: false,
      showFooter: false,
      selectedUUID: UUID


    }, () => {
      document.getElementById("feedContainer").style.border = "none";
      this.setState({ editLoader:false, enableLoader: false, showEditContentPage: true })
      window.scrollTo(0, 0);

    })
  }

  async closeEditPage(e) {
    this.setState({
      showEditContentPage: false,
      showContent: true,
      showPinnedContents: false,
      displayNewsFeed: false,
      displayWelcomeContent: false,
      displayPreviewModal: true,
      displaySearchPage: false,
      showHeader: false,
      showPreviewPage: false,
      showPinnedPage: false,
      showNewsModal: true,
      showFooter: true
    });
  }

  closeTab() {
    if (document.getElementById("span_3") != null && document.getElementById("reorder") != null && document.getElementById("span_9") != null) {
      document.getElementById("span_3").style.width = "20%";
      document.getElementById("reorder").style.display = "none";
      document.getElementById("span_9").style.width = "80%";
    }
    // if (document.getElementById("imageContainer") != null) {
    //   document.getElementById("imageContainer").classList.remove("col-sm-9");
    //   document.getElementById("imageContainer").className += " col-sm-12";
    // }
    if (document.getElementsByClassName("thumbnail") != null) {
      var x = document.getElementsByClassName("thumbnail").length;
      for (var i = 0; i < x; i++) {
        document.getElementsByClassName("thumbnail")[i].className +=
          " col-sm-4";
        document
          .getElementsByClassName("thumbnail")
        [i].classList.remove("col-sm-3");
      }
    }
  }


  render() {

    var levClass = document.getElementsByClassName("contentLevels");
    let level = this.state.level;
    level = level.replace("_*", "")
    for (let i = 0; i < levClass.length; i++) {
      if (levClass[i].id === level) {
        levClass[i].style.color = "#E0291A";
        levClass[i].style.borderBottom = "3px solid #E0291A";

      }
      else {
        levClass[i].style.color = "#333333";
        levClass[i].style.borderBottom = "transaprent";
      }
    }

    function closeBtn() {
      document.getElementById("welcomeContent").style.display = "none";
    }

    function expandTab() {

      document.getElementById("span_3").style.width = "0%";
      document.getElementById("span_3").style.overflow = "hidden";
      document.getElementById("reorder").style.display = "block";
      document.getElementById("span_9").style.width = "100%";
      // if (document.getElementById("imageContainer") != null) {
      //   document.getElementById("imageContainer").classList.remove("col-sm-12");
      //   document.getElementById("imageContainer").className += " col-sm-9";
      // }
      var x = document.getElementsByClassName("thumbnail").length;
      for (var i = 0; i < x; i++) {
        document.getElementsByClassName("thumbnail")[i].className +=
          " col-sm-3";
        document
          .getElementsByClassName("thumbnail")
        [i].classList.remove("col-sm-4");
      }
    }




    function welcomeContent() {
      return (
        <div
          className="container welcomeContent"
          id="welcomeContent"
          style={{ backgroundImage: "url(../../bgImg.svg)" }}
        >
          <div style={{ paddingBottom: "10px" }}>
            <b style={{ fontSize: "22px", fontWeight: "600" }}>
              Welcome to News Feed{" "}
            </b>
            { /*<sup>beta</sup>*/
            }
            <img className="closeBtn" onClick={closeBtn} src="../../ic_close_48px.svg" alt="" />
            {/* <p className="closeBtn" onClick={closeBtn}>
              x
            </p> */}
          </div>
          <p>
            Welcome to a news reading and English learning experience
            where content and questions are generated live, which gives you
            access to world news and helps you to enhance your English.
          </p>
          <p>
            We made every effort to make this learning experience tailormade for
            every level of learners who have the motivation to improve their
            English.
          </p>
        </div>
      );
    }

    return (
        <div>
          {/* header starts here */}
          <ConfigContext.Provider value={this.state.config}>
            <Header onMainPage={this.mainPage} navigate={this.navigate} pinnedContents={this.pinnedContents} editorView={this.editorView} editViewState={this.state.editorView} />
          </ConfigContext.Provider>
          {/* header ends here */}

          <div className="clearfix newsfeedParent">
            {/* Welcome Content starts here */}
            {/* <div>{this.state.displayWelcomeContent && welcomeContent()}</div> */}
            {/* Welcome Content ends here */}
            {this.state.enableLoader && <div className="mainLoader" ></div>}
            {this.state.showPinnedContents && <PinnedContents
              previewContents={this.previewContents}
              pinnedContentsDrafts={this.state.pinnedContentsDrafts}
              pinnedContentQuery={this.pinnedContentQuery}
              loadTable={this.state.loadTable}
              showPinnedPage={this.state.showPinnedPage}
              showPreviewPage={this.state.showPreviewPage}
              previewPage={this.previewPage}
              versionContent1={this.state.versionContent}
              listPage={this.listPage}
              navigateEdit={this.navigateEdit}
              previewType={this.state.previewType}
              navigatePreview={this.navigatePreview}
              exVersion={this.state.previewVersion}
              enableLoader={this.enableLoader}
              exerVersion={this.state.exerVersion}
              previewLoader={this.previewLoader}
              previewLoader1={this.state.previewLoader}

            />}
            {this.state.showHeader && <div className="newsBeta" id="newsBeta">
              <div className="container subHeader" >
                {/* <LevelsHeader levelWise={this.levelWise} searchContents={this.searchContents} searchContentsThroughButton={this.searchContentsThroughButton} /> */}

                <Navbar expand="lg" style={{ backgroundColor: "white" }}> <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    {this.state.editorView && <Nav className="mr-auto">
                      <button className="contentLevels" id="levelone" onClick={this.levelWise.bind(null, "levelone")}>Lower Intermediate</button>
                      <button className="contentLevels" id="leveltwo" onClick={this.levelWise.bind(null, "leveltwo")}>Higher Intermediate</button>
                      <button className="contentLevels" id="levelthree" onClick={this.levelWise.bind(null, "levelthree")}>Advanced</button>
                    </Nav>
                    }
                    <div style={{ padding: "12px" }}>
                    <button
                        className="searchBtn"
                        type="submit"
                        onClick={() => this.searchContentsThroughButton("")}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <input
                        className="searchBox"
                        id="searchBox"
                        type="text"
                        placeholder="Search for News"
                        name="search"
                        autoComplete="off"
                        onKeyPress={this.searchContents}
                      />
                     
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </div>

            </div>}
              <div className="row-fluid container">
            {/* News Feed Main content starts here */}
            {this.state.showContent &&
              <div className="feedContainer" id="feedContainer">

                {this.state.loadingCategories ? (
                  <Loader />
                ) : (
                    <div className="applicationContentView">
                      {this.state.displayNewsFeed && (
                        <div id="newsFeed">
                          {/* Left tab*/}
                          <div className="span_3" id="span_3">
                            <p
                              style={{
                                fontSize: "12px",
                                padding: "20px 10px",
                                margin: "0px",
                                fontWeight:"600"
                              }}
                            >
                              CATEGORIES
                          <span
                                style={{ float: "right", cursor: "pointer" }}
                                onClick={expandTab}
                              >
                                <img src="../../ic_menu_18px.svg" alt="" />
                              </span>{" "}
                            </p>
                            <ul className="category">
                              {this.state.categories[0] && (
                                <div>
                                  {this.state.learnerView && <li>
                                    <Link className={
                                      this.state.activeCategory.toLowerCase() === "topstories"
                                        ? "category-active"
                                        : ""
                                    }
                                      to={"/learner/topstories"} onClick={this.categoryWiseContents} key="top" category-name="topstories">Top Stories</Link>
                                  </li>}
                                  {/* <li key="videos" className={this.state.activeCategory === "Videos" ? "category-active" : ""} category-name="Videos" onClick={this.categoryWiseContents}>Videos</li> */}
                                </div>
                              )}
                              {this.state.categories.map((category, index) => {
                                return (
                                  <li key={index}>
                                    <Link className={
                                      this.state.activeCategory.toLowerCase() === category.toLowerCase()
                                        ? "category-active"
                                        : ""
                                    }
                                      to={this.state.loadUrl === "learner" ? "/learner/" + category : "/editor/" + category} onClick={this.categoryWiseContents} key={category} category-name={category}>{category.charAt(0).toUpperCase() + category.slice(1)}</Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          {/*Right tab */}

                          {
                             this.state.loadingPage ? (
                              <div className="span_9" id="span_9">
                                <div className="loaderContainer">
                                  {" "}
                                  <div className="mainLoader"> </div>
                                </div>
                              </div>
                            ) : (
                                <div className="span_9" id="span_9">
                                  {this.state.contents[0] &&
                                    this.state.displayNewsFeed &&
                                    <NewsFeed
                                      contents={this.state.contents}
                                      limit={this.state.limit}
                                      closeTab={this.closeTab}
                                      openModal={this.openModal}
                                      loadMore={this.loadMore}
                                      loadingProperty={this.state.loadingProperty}
                                      selectedCategory={this.state.activeCategory}
                                      loadingMoreRecords={this.state.loadingMoreRecords}
                                      editViewState={this.state.editorView}
                                      learnerViewState={this.state.learnerView}
                                      loadUrl={this.state.loadUrl}
                                    />
                                  }
                                </div>
                              )}

                          {this.state.noContents && this.state.displayNewsFeed && (
                            <div className="span_9" id="span_9">
                              <p style={{ textAlign: "center", padding: "20%" }}>
                                Sorry, presently there are no contents to display
                          </p>
                            </div>
                          )}
                        </div>
                      )}

                      {this.state.displayPreviewModal && (
                        <UserContext.Provider value={this.state.user}>
                          <Modal
                            searchPage={this.searchPage}
                            openModal={this.openModal}
                            title="Content View"
                            level={this.state.level}
                            modelNavigationLink={this.state.modelNavigationLink}
                            mainPage={this.mainPage}
                            contents={this.state.contents}
                            selectedIndex={this.state.selectedIndexToView}
                            selectedUUID={this.state.selectedUUID}
                            searchContentsThroughButton={this.searchContentsThroughButton}
                            ShowEditContents={this.ShowEditContents}
                            editViewState={this.state.editorView}
                            selectedCategory={this.state.activeCategory}
                            learnerViewState={this.state.learnerView}
                            loadUrl={this.state.loadUrl}
                            navigatePreview={this.navigatePreview}
                            showEditContentPage={this.state.showEditContentPage}
                            navigateEdit={this.navigateEdit}
                            navigateContent={this.state.navigateContent}
                            showNewsModal={this.state.showNewsModal}
                            closeEditPage={this.closeEditPage}
                            exerQues={this.state.exerQues}
                            editLoader={this.state.editLoader}
                            enableLoader={this.enableLoader}




                          />
                        </UserContext.Provider>
                      )}
                      {this.state.displaySearchPage && (
                        <SearchPage
                          title="Content view"
                          searchQuery={this.state.searchQuery}
                          contents={this.state.contents}
                          limit={this.state.limit}
                          searchLimit={this.state.searchLimit}
                          openModal={this.openModal}
                          searchPage={this.searchPage}
                          loadMore={this.loadMore}
                          loadingProperty={this.state.loadingProperty}
                          loadingMoreRecords={this.state.loadingMoreRecords}
                        />
                      )}

                      {/* News Feed Main content ends here */}
                    </div>
                  )}
              </div>
            }
            </div>
          </div>

          {/* footer starts here */}
          {/* {
            this.state.showPinnedContents?"":<Footer />
          } */}
          {this.state.showFooter && <Footer />}
          {/* footer ends here */}
        </div>
    );
  }
}

export default App;
